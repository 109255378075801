<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="55" height="80" viewBox="0 0 55 80">
    <g fill="#AEAEB3" fill-rule="evenodd">
        <g>
            <path d="M18 57.37h4.53v-6.85H18v6.85zM16.75 50V18.84c.038-.824-.278-1.625-.87-2.2-.845-.687-1.912-1.042-3-1v.52c.952-.054 1.891.233 2.65.81.491.493.74 1.177.68 1.87v1.83c-9 5.26-14.59-2.62-15.21-3.54v-.32h.06c.24-.005.43-.2.43-.44V7.63c-.005-.235-.195-.425-.43-.43H1v-.36C5.68 1.66 10.82 2 10.87 2c5.08 0 8.79 2.12 11 6.3 1.031 1.964 1.67 4.111 1.88 6.32V50h-7zm6.31.52h1.26V14.58c0-.2-.8-13.16-13.39-13.16-.23 0-5.53-.28-10.4 5.13l-.07.07v10.69c0 .06 3.72 5.63 9.77 5.63 2.067-.038 4.089-.61 5.87-1.66h.09v29.24h1.26v7.37h.81v10.27h.52V57.89h2.82v10.27h.52V57.89H23l.06-7.37z" transform="translate(0 11)"/>
            <path fill-rule="nonzero" d="M22.62 68.66H21.1V58.39h-1.82v10.27h-1.52V58.39H17V51h-1.31V22.08c-1.693.853-3.555 1.318-5.45 1.36C4 23.44 0 17.83 0 17.31V6.41l.15-.14C4.84 1.05 9.95.89 10.95.92c13.09 0 13.91 13.52 13.91 13.66V51h-1.3v7.37h-.9l-.04 10.29zm-4.13-11.79H22V51h-3.51v5.87zm-1.24-7.37h6V14.59c-.203-2.13-.821-4.198-1.82-6.09-2.12-4-5.67-6-10.56-6-.1 0-4.78-.3-9.27 4.41.232.178.374.448.39.74v8.75c-.007.297-.147.576-.38.76 1.32 1.83 6.33 7.61 14.1 3.25v-1.57c.056-.562-.142-1.12-.54-1.52-.653-.478-1.453-.712-2.26-.66h-.53v-1.5h.48c1.211-.047 2.398.347 3.34 1.11.71.678 1.093 1.63 1.05 2.61V49.5zM1 16.31V7.63v8.68z" transform="translate(0 11)"/>
        </g>
        <g>
            <path d="M2.22 1.24c-.79 0-1.43.64-1.43 1.43 0 .79.64 1.43 1.43 1.43.79 0 1.43-.64 1.43-1.43 0-.79-.64-1.43-1.43-1.43" transform="translate(5 21)"/>
            <path fill-rule="nonzero" d="M2.22 4.6C1.154 4.6.29 3.736.29 2.67c0-1.066.864-1.93 1.93-1.93 1.066 0 1.93.864 1.93 1.93-.005 1.064-.866 1.925-1.93 1.93zm0-2.86c-.514 0-.93.416-.93.93s.416.93.93.93.93-.416.93-.93-.416-.93-.93-.93z" transform="translate(5 21)"/>
        </g>
        <g>
            <path d="M2.22 1.3c-.757 0-1.37.613-1.37 1.37 0 .757.613 1.37 1.37 1.37.757 0 1.37-.613 1.37-1.37 0-.363-.144-.712-.401-.969S2.583 1.3 2.22 1.3m0 2.86C1.403 4.138.756 3.462.77 2.644c.015-.817.685-1.47 1.503-1.463.818.007 1.477.671 1.477 1.489 0 .402-.162.787-.45 1.068-.289.28-.678.433-1.08.422" transform="translate(5 21)"/>
            <path fill-rule="nonzero" d="M2.26 4.66v-.12h-.05v.12c-1.081-.032-1.94-.918-1.94-2 0-1.105.895-2 2-2s2 .895 2 2-.895 2-2 2h-.01zm0-2.86c-.48 0-.87.39-.87.87s.39.87.87.87.87-.39.87-.87c0-.238-.097-.465-.269-.63-.172-.164-.403-.25-.641-.24h.04z" transform="translate(5 21)"/>
        </g>
        <g>
            <path d="M5 2.64L2.16 1.33c-.458-.252-1.033-.108-1.319.33C.555 2.098.655 2.682 1.07 3c.084.052.175.092.27.12l2.84 1.3c.129.059.269.09.41.09.387-.003.737-.229.9-.58.097-.24.097-.51 0-.75-.095-.244-.281-.442-.52-.55" transform="translate(12 14)"/>
            <path fill-rule="nonzero" d="M4.59 5c-.214-.001-.426-.049-.62-.14l-2.84-1.3C1.012 3.53.9 3.48.8 3.41c-.336-.216-.57-.559-.65-.95C.025 1.868.269 1.259.767.916 1.266.573 1.921.562 2.43.89l2.78 1.28c.365.158.65.458.79.83.18.452.123.964-.152 1.365s-.732.64-1.218.635h-.04zm-3-3.35h-.1c-.13.026-.242.106-.31.22-.078.107-.103.242-.07.37.021.132.098.248.21.32.044.026.09.046.14.06L4.39 4c.066.01.134.01.2 0 .193-.004.367-.116.45-.29.045-.112.045-.238 0-.35-.05-.127-.147-.23-.27-.29L2 1.78c-.115-.078-.252-.117-.39-.11l-.02-.02z" transform="translate(12 14)"/>
        </g>
        <g>
            <path d="M1.75 1.3c-.362 0-.69.21-.84.54-.105.22-.118.473-.035.702.082.23.254.416.475.518l2.85 1.3c.118.06.248.09.38.09.364 0 .695-.21.85-.54.1-.226.1-.484 0-.71-.086-.23-.258-.416-.48-.52L2.1 1.38c-.118-.06-.248-.09-.38-.09m2.84 3.27c-.148 0-.294-.03-.43-.09l-2.85-1.3c-.34-.155-.57-.48-.605-.852-.035-.371.13-.733.435-.95.304-.216.7-.253 1.04-.098L5 2.58c.249.12.442.331.54.59.108.252.108.538 0 .79-.16.38-.538.622-.95.61" transform="translate(12 14)"/>
            <path fill-rule="nonzero" d="M4.63 5.07V5v.11c-.217.002-.432-.042-.63-.13L1.14 3.67c-.37-.169-.658-.478-.8-.86-.16-.377-.16-.803 0-1.18.17-.376.483-.667.87-.81.384-.144.81-.126 1.18.05l2.85 1.3c.345.176.616.47.76.83.155.375.155.795 0 1.17-.236.547-.775.9-1.37.9zM1.74 1.8c-.164.004-.311.101-.38.25-.045.102-.045.218 0 .32.033.106.109.194.21.24l2.86 1.3c.1.061.222.074.332.035.11-.039.198-.125.238-.235.035-.1.035-.21 0-.31-.042-.112-.124-.204-.23-.26l-2.85-1.3c-.055-.03-.117-.044-.18-.04z" transform="translate(12 14)"/>
        </g>
        <g>
            <path d="M2.49 56.84H7V50H2.49v6.84zm-1.26-7.37V14c.189-2.223.821-4.386 1.86-6.36 2.23-4.17 5.94-6.29 11-6.29.06 0 5.1-.31 9.8 4.75l.07.07h-.1c-.192.052-.33.221-.34.42v8.73c0 .243.197.44.44.44H24v.75c-.61.92-6.25 8.8-15.21 3.54v-1.83c-.03-.677.228-1.335.71-1.81.759-.577 1.698-.864 2.65-.81v-.52c-1.088-.038-2.153.317-3 1-.59.572-.91 1.368-.88 2.19v31.16l-7.04.04zm6.3.52h1.22V20.66l.09.05c1.795 1.05 3.83 1.622 5.91 1.66 6.06 0 9.73-5.57 9.77-5.63V6.05L24.45 6C19.61.55 14.32.84 14.1.84 1.47.84.75 13.84.75 14v36H2v7.37h.9v21.79h.52V57.32h2.81v21.84h.5V57.32h.81l-.01-7.33z" transform="translate(29)"/>
            <path fill-rule="nonzero" d="M7.25 79.66h-1.5V57.82H3.94v21.84H2.42V57.82h-.9v-7.37H.25V14C.25 13.86 1.06.33 14.1.33c1 0 6.11.13 10.72 5.31l.13.14.07.26v10.79l-.08.19c-.16.24-4 5.85-10.19 5.85-1.914-.03-3.796-.498-5.5-1.37v29H8v7.33h-.81l.06 21.83zM3 56.34h3.52v-5.89H3v5.89zM14.28 1.87h-.16c-4.9 0-8.45 2-10.59 6-1.01 1.91-1.622 4.006-1.8 6.16V49h6.06V18.27c-.043-.953.32-1.88 1-2.55.946-.78 2.145-1.186 3.37-1.14h.48v1.53h-.53c-.833-.05-1.657.203-2.32.71-.355.373-.54.876-.51 1.39v1.58c8.14 4.59 13.32-2.12 14.21-3.4v-.22c-.31-.156-.503-.474-.5-.82V6.61c.006-.181.061-.358.16-.51-2.283-2.525-5.471-4.046-8.87-4.23z" transform="translate(29)"/>
        </g>
        <g>
            <path d="M2.75.67c-.79 0-1.43.64-1.43 1.43 0 .79.64 1.43 1.43 1.43.79 0 1.43-.64 1.43-1.43 0-.79-.64-1.43-1.43-1.43" transform="translate(44 10)"/>
            <path fill-rule="nonzero" d="M2.75 4C1.684 4 .82 3.136.82 2.07c0-1.066.864-1.93 1.93-1.93 1.066 0 1.93.864 1.93 1.93C4.675 3.134 3.814 3.995 2.75 4zm0-2.86c-.514 0-.93.416-.93.93s.416.93.93.93.93-.416.93-.93c-.016-.502-.428-.9-.93-.9v-.03z" transform="translate(44 10)"/>
        </g>
        <g>
            <path d="M2.75.73c-.755.006-1.364.621-1.36 1.377.004.755.618 1.365 1.373 1.363.756-.002 1.367-.615 1.367-1.37 0-.365-.146-.715-.405-.972-.259-.258-.61-.4-.975-.398m0 2.86c-.823 0-1.49-.667-1.49-1.49S1.927.61 2.75.61s1.49.667 1.49 1.49-.667 1.49-1.49 1.49" transform="translate(44 10)"/>
            <path fill-rule="nonzero" d="M2.75 4.09c-1.105 0-2-.895-2-2s.895-2 2-2 2 .895 2 2-.895 2-2 2zm0-3v.12c-.23-.002-.452.092-.61.26-.163.165-.253.388-.25.62-.002.23.092.452.26.61.166.16.389.25.62.25.48 0 .87-.39.87-.87s-.39-.87-.87-.87v-.12h-.02z" transform="translate(44 10)"/>
        </g>
        <g>
            <path d="M4.85.76L2 2.06c-.5.223-.727.807-.51 1.31.165.347.515.569.9.57.149 0 .296-.035.43-.1l2.85-1.31c.398-.276.541-.798.34-1.238C5.807.852 5.32.619 4.85.74" transform="translate(35 3)"/>
            <path fill-rule="nonzero" d="M2.39 4.44c-.589.01-1.13-.321-1.39-.85-.324-.757.001-1.636.74-2L4.64.3l.37.82-.36-.84c.751-.342 1.638-.011 1.98.74.342.751.011 1.638-.74 1.98L3 4.29c-.189.097-.398.148-.61.15zM5.05 1.2L2.2 2.5c-.117.052-.207.15-.25.27-.055.124-.055.266 0 .39.13.226.412.317.65.21l2.86-1.31c.238-.12.339-.407.23-.65-.124-.229-.405-.321-.64-.21z" transform="translate(35 3)"/>
        </g>
        <g>
            <path d="M4.87.84L2 2.14c-.224.101-.397.289-.48.52-.087.229-.087.481 0 .71.15.33.478.54.84.54.144.012.288-.012.42-.07l2.84-1.3c.304-.138.51-.427.544-.758.032-.332-.114-.656-.385-.85C5.508.737 5.154.702 4.85.84M2.41 4c-.538.14-1.09-.182-1.23-.72-.14-.538.182-1.09.72-1.23L2 2 4.84.7c.525-.24 1.145-.01 1.385.515S6.235 2.36 5.71 2.6L2.86 3.9c-.135.062-.282.092-.43.09" transform="translate(35 3)"/>
            <path fill-rule="nonzero" d="M2.38 4.5c-.602.003-1.146-.356-1.38-.91-.362-.738-.063-1.63.67-2l.13-.06L4.61.25c.377-.16.803-.16 1.18 0 .588.214.989.762 1.013 1.388.024.626-.333 1.204-.903 1.462L3.05 4.4c-.198.091-.412.139-.63.14l-.04-.04zm2.7-3.2L2.23 2.59c-.1.051-.178.136-.22.24-.045.112-.045.238 0 .35.078.143.228.231.39.23.072.017.148.017.22 0l2.82-1.29c.108-.048.19-.139.23-.25.045-.105.045-.225 0-.33-.1-.215-.355-.31-.57-.21l-.02-.03z" transform="translate(35 3)"/>
        </g>
        <path fill-rule="nonzero" d="M50 78.7L49.5 78.7 49.5 62.02 46.8 62.02 46.8 78.7 46.3 78.7 46.3 61.52 50 61.52z"/>
        <path fill-rule="nonzero" d="M50.5 79.2L49 79.2 49 62.52 47.3 62.52 47.3 79.2 45.8 79.2 45.8 61.02 50.5 61.02zM50 43.27L49.5 43.27 49.5 35.51 46.8 35.51 46.8 43.27 46.3 43.27 46.3 35.01 50 35.01z"/>
        <path fill-rule="nonzero" d="M50.5 43.77L49 43.77 49 36.01 47.3 36.01 47.3 43.77 45.8 43.77 45.8 34.51 50.5 34.51zM50.9 62h-5.5v-8.75h5.5V62zm-5-.5h4.5v-7.75h-4.5v7.75z"/>
        <path fill-rule="nonzero" d="M51.4 62.51h-6.5v-9.76h6.5v9.76zm-5-1.5h3.5v-6.76h-3.5v6.76z"/>
        <path fill-rule="nonzero" d="M51.5 53.76h-6.69V43h6.69v10.76zm-6.19-.5H51v-9.74h-5.69v9.74z"/>
        <path fill-rule="nonzero" d="M52 54.26h-7.69V42.52H52v11.74zm-6.19-1.5h4.69V44h-4.69v8.76zM49.39 35.51h-2.5v-2.89c0-.177.072-.347.2-.47l.61-.62c.25-.224.63-.224.88 0l.62.61c.122.126.19.295.19.47v2.9zm-2-.5h1.5v-2.39c.01-.036.01-.074 0-.11l-.62-.61c-.054-.05-.136-.05-.19 0l-.6.61c-.013.036-.013.074 0 .11l-.09 2.39z"/>
        <path fill-rule="nonzero" d="M49.89 36h-3.5v-3.38c-.002-.313.124-.613.35-.83l.61-.61c.442-.413 1.128-.413 1.57 0l.63.63c.22.22.343.519.34.83V36zm-2-1.5h.5v-1.74l-.25-.25-.25.25v1.74z"/>
    </g>
</svg>

</template>

<script>
export default {

}
</script>

<style>

</style>