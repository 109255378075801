import routes from './routes';

export default {
    computed: {
        lang() {
            return window.appLocale;
        }
    }
};

export const goToRouteWithName = {
    methods: {
        async goToRouteWithName(name, param = '') {
            try {
                let parentRoute, parentPath, childRoute, childPath = '';
                for (let parent of routes) {
                    childRoute = parent.children.find(route => route.name === name);
                    if (childRoute) {
                        parentRoute = parent;
                        break;
                    }
                }
                if (!childRoute) {
                    throw new Error('Route not found');
                }
                parentPath = window.appLocale === 'en' ? parentRoute.path : parentRoute.alias;
                childPath = window.appLocale === 'en' ? childRoute.path : childRoute.alias;
                if (param) {
                    childPath = childPath.split('/').slice(0, -1).join() + '/' + param;
                }
                await this.$router.push(parentPath + '/' + childPath);
            } catch (e) {}
        }
    }
};
