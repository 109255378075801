<template>
  <div class="wizard flex flex-col overflow-hidden">
      <wizard-steps></wizard-steps>
      <router-view></router-view>
  </div>
</template>

<script>
import WizardSteps from './wizard/WizardSteps'
import {MapMutations, mapMutations } from 'vuex';

export default {
    props: ['brands', 'trans'],
    components: {
        WizardSteps
    },
    methods: {
        ...mapMutations(['setState'])
    },
    created(){
        this.setState({ prop: 'brands', data: this.brands });
        this.setState({ prop: 'trans', data: this.trans });
        this.setState({ prop: 'locale', data: window.appLocale });
    },
    mounted() {
        window.onbeforeunload = () => this.trans.leaving;
    }
}
</script>

<style>

</style>
