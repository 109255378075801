<template>
    <div
        class="card p-8 flex flex-col md:flex-row"
    >
        <div class="icon-container flex justify-center items-center md:mr-8 mb-5 md:mb-0 md:h-full">
            <img :src="icon" />
        </div>
        <div class="content flex-grow flex flex-col md:h-full w-full">
            <h2 class="title leading-snug mb-2">{{ title }}</h2>
            <p class="description">{{ description }}</p>
<!--             <p v-if="time" class="time mb-2 mt-1">{{ timeTrans }}: {{ time }}</p> -->
            <span v-if="address" class="mt-3">
                <p class="flex items-center" style="font-size: 14px;"><img src="/img/icons/location_on.svg" class="mr-2" alt=""> Carrer de la Marina, 145<br> 08013 Barcelona</p>
            </span>
        </div>
        <div class="location-tag-container" v-if="locationTag">
            <span class="location-tag">
                {{ locationTag }}
            </span>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: {
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            required: true,
        },
        price: {
            type: Number,
            required: false,
        },
        locationTag: {
            type: String,
            required: false,
        },
        address: {
            type: Boolean,
            required: false,
        }
    },

    computed: {
        ...mapGetters({
            trans: "getTrans",
            locale: "getLocale",
        }),
        freeTrans() {
            return window.appLocale === 'en' ? 'Free' : 'Gratis';
        },
        timeTrans() {
            return window.appLocale === 'en' ? 'Time' : 'Tiempo';
        },
        isroute(){

        }
    },
}
</script>

<style lang="scss" scoped>
@import '../../sass/_variables';

.card {
    width: 100%;
    min-height: 190px;
    margin: 0 !important;
    max-width: calc(100vw - 70px);

    @media (min-width: 500px) {
        &:not(.x-services-cards-card) {
            max-width: 475px;
        }
    }

    .icon-container {
        width: 80px;
        flex-shrink: 0;

        img {
            width: 80px;
        }
    }

    .content {
        height: auto !important;
        align-self: center !important;
    }

    .title {
        font-size: 20px;
        font-weight: 700;
        line-height: 1.75;
        text-align: left;
    }

    .description {
        font-size: 17px;
        font-weight: bold;
        line-height: 1.52;
        color: $primary;
        text-align: left;
    }

    .border-bottom {
        border-bottom: 1px solid #ececec;
    }

    .time {
        font-size: 15px;
        font-weight: bold;
        line-height: 2;
    }

    .price-chip {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: black;
        color: white;
        font-weight: bold;
        border-radius: 18px;
        font-size: 16px;
        text-align: center;
        align-self: start;
        height: 33px;
        padding: 1rem;
    }

    &:nth-child(3) {
        .icon-container {
            img {
                width: 71px;
            }
        }
    }
    &:last-child {
        .icon-container {
            img {
                width: 65px;
            }
        }
    }

    @media (min-width: $lg) {
        .icon-container {
            width: 110px;
        }

        &:first-child {
            .icon-container {
                img {
                    width: 100px;
                }
            }
        }
        &:nth-child(2) {
            .icon-container {
                img {
                    width: 110px;
                }
            }
        }
        &:nth-child(3) {
            .icon-container {
                img {
                    width: 97px;
                }
            }
        }
        &:last-child {
            .icon-container {
                img {
                    width: 89px;
                }
            }
        }
    }

    &.x-services-cards-card {
        .title {
            font-size: 22px;
            line-height: 1.25;
        }
        .description {
            font-size: 16px;
            @media (min-width: $lg) {
                font-size: 18px;
            }
        }
    }
}
</style>
