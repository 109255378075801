<template>
    <div class="scrollable"
        @mousedown="hover && mousedown($event)"
        @mousemove="hover && isDown && mousemove($event)"
        @mouseleave="isDown = false"
        @mouseup="mouseup"
    >
        <ul class="hs no-scrollbar space-x-8 overflow-hidden" ref="ul" @wheel="wheel">
            <slot></slot>
        </ul>
    </div>
</template>

<script>
import _ from 'lodash';

export default {
    props: {
        itemsCount: {
            type: Number,
            required: true,
        },
        itemWidth: {
            type: Number,
            required: true,
        }
    },

    data() {
        return {
            isDown: false,
            mouseWasUp: true,
            startX: null,
            scrollLeft: 0,
            slider: null,
            hover: null,
            scrollableWidth: null,
            maxScroll: null,
            distanceToWalk: null,
            directionToWalk: null,
            containerMaxWidth: 1200,
            spaceX: 32, // pixels
        }
    },

    computed: {
        scrolledToEnd() {
            return this.scrollLeft >= this.maxScroll;
        },
        scrolledToStart() {
            return this.scrollLeft === 0;
        },
        percentage() {
            let percentage;
            if (this.scrollLeft === 0) {
                percentage = 0.5;
            } else {
                percentage = this.scrollLeft / this.maxScroll * 100;
            }
            if (percentage > 100) {
                percentage = 100;
            }
            return percentage;
        }
    },

    methods: {
        mousedown(e) {
            this.isDown = true;
            this.startX = e.pageX - this.slider.offsetLeft;
        },
        mouseup() {
            this.walk(this.directionToWalk, this.distanceToWalk);
        },
        mousemove(e) {
            e.preventDefault();
            const x = e.pageX;
            const distance = x - this.startX;
            this.directionToWalk = distance < 0 ? 'right' : 'left';
            this.distanceToWalk = Math.abs(distance * 3);
        },
        wheel(e) {
            console.log(e);
            if (e.shiftKey) {
                e.preventDefault();
            }
            // this.walkOnWheel(e);
        },
        walkOnWheel: _.throttle(function(e) {
            if (e.shiftKey) {
                e.preventDefault();
                if (e.deltaY > 0) {
                    this.walk('right');
                } else {
                    this.walk('left');
                }
            }
        }, 1000, { leading: true, trailing: false }),
        walk(direction, distance = null) {
            distance = distance || this.scrollableWidth * 0.8;
            let scrollLeft;
            switch (direction) {
                case 'right':
                    if (this.scrolledToEnd) return;
                    scrollLeft = this.scrollLeft + distance;
                    if (scrollLeft > this.maxScroll) {
                        scrollLeft = this.maxScroll;
                    }
                    this.scrollLeft = scrollLeft;
                    break;
                case 'left':
                    if (this.scrolledToStart) return;
                    scrollLeft = this.scrollLeft - distance;
                    if (scrollLeft < 0) {
                        scrollLeft = 0;
                    }
                    this.scrollLeft = scrollLeft;
            }
        },
        setMaxScroll() {
            // this.maxScroll = Items width + space between items - slider space without horizontal paddings
            this.maxScroll = (this.itemWidth * this.itemsCount) + (this.spaceX * (this.itemsCount - 1)) -
                this.slider.getBoundingClientRect().width +
                Number((getComputedStyle(this.slider).getPropertyValue('padding-left').slice(0, -2))) * 2;
        },
        setScrollableWidth() {
            this.scrollableWidth = this.$el.getBoundingClientRect().width;
        }
    },

    watch: {
        scrollLeft(scrollLeft) {
            for (let el of [...this.$el.querySelectorAll('.scrollable-item')]) {
                el.style.transform = `translateX(-${scrollLeft}px)`;
            }
            this.$emit('update:scroll-left', scrollLeft);
        },
        percentage: {
            immediate: true,
            handler(percentage) {
                this.$emit('update:percentage', percentage);
            }
        },
    },

    mounted() {
        this.slider = this.$refs.ul;
        this.hover = window.matchMedia('(hover: hover)').matches;
        this.setMaxScroll();
        this.setScrollableWidth();
        window.onresize = () => {
            this.setMaxScroll();
            this.setScrollableWidth();
        }
    }
};
</script>

<style scoped lang="scss">
    .scrollable {
        max-width: 100%;
        overflow: hidden;
    }

    .hs {
        display: flex;
        cursor: grab;
        overflow-x: scroll;
        padding-bottom: calc(.75 * 5vw);
        margin-bottom: calc(-.25 * 5vw);
        padding-left: 5vw;
        padding-right: 5vw;
        scroll-snap-type: none;
        scroll-behavior: smooth;

        @media (min-width: 1500px) {
            padding-left: calc((100vw - 1200px - 17px) / 2);
            padding-right: calc((100vw - 1200px - 17px) / 2);
        }

        @media (hover: none) {
            scroll-snap-type: x proximity;
        }
    }

    ul {
        list-style: none;
        padding: 0;

        > div, a {
            transition: transform .5s ease-out;
        }
    }

    .no-scrollbar {
        scrollbar-width: none;
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    img {
        user-select: none;
    }
</style>
