<template>
  <div class="relative">
    <div class="flex flex-col">
      <input
        v-model="discountCode"
        class="outline-none rounded-lg appearance-none block w-full py-3 px-4 my-3"
        type="text"
        :placeholder="trans.steps[6].coupon"
        :disabled="validDiscountCode"
      />
      <span v-if="validDiscountCode && !isApplicable" class="discount-error">Importe insuficiente</span>
    </div>
    <div class="loader-container">
      <clip-loader
        v-if="loading"
        class="flex items-center"
        size="25px"
        color="#3c3cfa"
      ></clip-loader>
      <svg
        v-if="validDiscountCode && !loading"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        class="transform transition-transform duration-500 ease-in-out"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M5 13l4 4L19 7"
        ></path>
      </svg>
    </div>
  </div>
</template>

<script>
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    ClipLoader,
  },
  computed: {
    discountCode: {
      get() {
        return this.$store.getters.getDiscountCode;
      },
      set(value) {
        this.$store.commit("setDiscountCode", value);
        this.$store.dispatch("retrieveDiscountCode", value);
      },
    },
    ...mapGetters({
      loading: "getDiscountLoader",
      validDiscountCode: "getValidDiscountCode",
      isApplicable: "getDiscountCodeIsApplicable",
      trans: "getTrans",
    }),
  },
};
</script>

<style lang="scss">
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 18px;
  bottom: 0px;
  height: 100%;
}

.discount-error{
    width: 100%;
    position: absolute;
    bottom: -20px;
}
</style>
