import {
    STORE_ORDER_ACTION,
    SET_ORDER_LOADING_TO_FALSE_MUTATION,
    SET_ORDER_LOADING_TO_TRUE_MUTATION,
    SET_ORDER_DATA_MUTATION,
    RETREIVE_ORDER_ACTION,
    CREATE_PAYMENT_INSTANCE,
} from "./types";
import axios from "axios";
import { goToRouteWithName } from "../../mixin";
import {trans} from '../../components/wizardShop/trans';

const wizardShopActions = {
    [STORE_ORDER_ACTION]({ commit, getters }, payload) {
        commit(SET_ORDER_LOADING_TO_TRUE_MUTATION);
        axios
            .post("/api/order/save", {
                payload: JSON.stringify(payload)
            })
            .then(res => {
                commit(SET_ORDER_LOADING_TO_FALSE_MUTATION);
                commit(SET_ORDER_DATA_MUTATION, res.data);
            })
            .catch(err => {
                commit(SET_ORDER_LOADING_TO_FALSE_MUTATION);
                app.$toast.open({
                    message: err,
                    type: "error"
                });
                console.log(err);
            });
    },
};

export default wizardShopActions;
