const throttle = require('lodash.throttle');
const doc = document.documentElement;
const w = window;
let prev;
let curScroll;
let direction = 0;
let prevDirection = 0;
let prevScroll = w.scrollY || doc.scrollTop;

const onScrollHandler = () => {
    const st = window.scrollY;
    const header = document.querySelector('header');

    if (st > 0 && prev !== 0) {
        header.classList.add('scrolling');
    } else {
        header.classList.remove('scrolling');
    }

    prev = st;

    var checkScroll = function () {
        /*
            ** Find the direction of scroll
            ** 0 - initial, 1 - up, 2 - down
            */
        curScroll = w.scrollY || doc.scrollTop;
        if (curScroll > prevScroll) {
            //scrolled up
            direction = 2;
        } else if (curScroll < prevScroll) {
            //scrolled down
            direction = 1;
        }

        if (direction !== prevDirection) {
            toggleHeader(direction, curScroll);
        }

        prevScroll = curScroll;
    };

    var toggleHeader = function (direction, curScroll) {
        var headerHeight = header.offsetHeight;
        if (direction === 2 && curScroll > headerHeight) {
            header.classList.add('hide');
            prevDirection = direction;
        } else if (direction === 1) {
            header.classList.remove('hide');
            prevDirection = direction;
        }
    };

    checkScroll();
}

onScrollHandler();
window.onscroll = throttle(onScrollHandler, 100);

