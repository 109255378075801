<template>
    <skeleton class="product-card-skeleton">
        <div class="product-card-skeleton-container">
            <div class="image-container">
                <Skeleton :duration="0.75" :loading="true" class="skeleton" width="230px" height="220px"></Skeleton>
            </div>
            <div class="text-container">
                <div>
                    <h6 class="mb-6"><Skeleton :duration="0.75" :loading="true" class="skeleton" width="50px" height="20px"></Skeleton></h6>
                    <p><Skeleton :duration="0.75" :loading="true" class="skeleton" width="50px" height="20px"></Skeleton></p>
                </div>
                <hr class="hr-style">
                <div class="flex justify-between text-footer">
                    <p><span class="price"><Skeleton :duration="0.75" :loading="true" class="skeleton" width="50px" height="20px"></Skeleton></span></p>
                </div>
            </div>
        </div>
    </skeleton>
</template>

<script>
import { Skeleton } from "vue-loading-skeleton";
export default {
components: {
        Skeleton
    },
}
</script>

<style lang="scss">
.product-card-skeleton{
    padding: 2rem 1rem;
    border-radius: 30px;
    box-shadow: 0 3px 20px 0 rgba(17, 17, 95, 0.1);
    background-color: #ffffff;
    max-width: 262px;
    width: 100%;
    // margin: 1rem;
    &-container{
        display: flex;
        flex-direction: column;
        .image-container{
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 2rem;
            img{
                max-width: 230px;
                max-height: 220px;
                object-fit: cover;
            }
        }
        .text-container{
            .text-footer{
                color: #616161;
                font-size: 14px;
            }
            .price{
                color: #3c3cfa;
                font-size: 18px;
                font-weight: bold;
            }
            .hr-style{
                margin: 1rem 0;
                color: #ccc;
            }
        }
    }
}
</style>
