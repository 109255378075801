import {
    INCREASE_ITEM_CART_ACTION,
    DECREASE_ITEM_CART_ACTION,
    UPDATE_ITEM_CART_ACTION,
    REMOVE_ITEM_CART_ACTION,
    RETRIEVE_ITEMS_ACTION,
    ADD_ITEM_CART_ACTION
} from "@ricadesign/vuex-cart";

import {
    SET_CART_LOADING_TO_FALSE,
    SET_CART_LOADING_TO_TRUE
} from '../store/cart/types';

export const notifyKO = (message) => {
    app.$toast.open({
        message,
        type: "error"
    });
};
const notifyOK = (message, url = null) => {
    app.$toast.open({
        message,
        type: "success",
        onClick: () => {
            if (url) {
                window.location.href = "/" + window.appLocale + "/" + url;
            }
        }
    });
};

export const retrieveCartItems = () => {
    store
        .dispatch(RETRIEVE_ITEMS_ACTION)
        .then((res) => {
        })
        .catch((res) => {
            notifyKO(res);
        });
};

export const addCartItem = (item, message) => {
    store
        .dispatch(ADD_ITEM_CART_ACTION, item)
        .then(() => {
            notifyOK(message, "checkout");
        })
        .catch(() => {
            notifyKO("Ha ido mal");
        });
};

export const increaseCartItem = id => {
    store
        .dispatch(INCREASE_ITEM_CART_ACTION, id)
        .then(() => {})
        .catch(() => {
            notifyKO("Ha ido mal");
        });
};

export const decreaseCartItem = id => {
    store
        .dispatch(DECREASE_ITEM_CART_ACTION, id)
        .then(() => {})
        .catch(() => {
            notifyKO("Ha ido mal");
        });
};

export const updateCartItem = (id, quantity) => {
    store
        .dispatch(UPDATE_ITEM_CART_ACTION, { id, quantity })
        .then(() => {})
        .catch(() => {
            notifyKO("Ha ido mal");
        });
};

export const deleteCartItem = (id, message) => {
    store
        .dispatch(REMOVE_ITEM_CART_ACTION, id)
        .then(() => {
            notifyOK(message);
        })
        .catch((res) => {
            notifyKO(res);
        });
};
