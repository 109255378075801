<template>
  <div class="wizard-content">
    <div class="wizard-content__column">
      <address-form />
    </div>
    <div class="wizard-content__column">
      <component-data :title="trans.contactTitle[lang]">
        <form>
          <floating-input
            v-for="(item, index) in personalData"
            :key="index"
            :name="index"
            :placeholder="trans[index][lang]"
            :initialValue="item.value"
            :initialErrors="item.errors"
            @handle-change="handlePersonalChange"
            :type="item.type"
            class="mt-2"
          ></floating-input>
        </form>
      </component-data>
      <component-data :title="trans.shipmentTitle[lang]" class="mt-10">
        <div class="shipment-container flex-col items-start mt-4">
          <input
            type="radio"
            id="standard"
            value="standard"
            v-model="shipingdata"
          />
          <p class="free-shipping-title font-bold">{{ trans.free[lang] }}</p>
          <p class="free-shipping-description mt-3">
            {{ trans.shippingDescription1[lang] }}<br />
            {{ trans.shippingDescription2[lang] }}
          </p>
        </div>
      </component-data>
      <btn-primary
        :disabled="!formIsValid"
        :handle-click="handleNext"
        :text="trans.btnText[lang]"
        class="mt-12 w-full"
      ></btn-primary>
    </div>
  </div>
</template>

<script>
import {
  GET_ADDRESS_DATA,
  SET_ADDRESS_DATA_MUTATION,
  GET_PERSONAL_DATA,
  SET_PERSONAL_DATA_MUTATION,
  GET_SHIPMENT_DATA,
  SET_SHIPMENT_DATA_MUTATION,
  GET_PERSONAL_VALID,
  GET_ADDRESS_VALID,
} from "../../../store/wizardShop/types";
import { GET_SUBTOTAL } from "../../../store/cart/types";
import mixin from "../../../mixin";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { trans } from "../trans";

export default {
  mixins: [mixin],
  computed: {
    ...mapGetters({
      addressForm: GET_ADDRESS_DATA,
      personalData: GET_PERSONAL_DATA,
      personalValid: GET_PERSONAL_VALID,
      addressValid: GET_ADDRESS_VALID,
      subtotal: GET_SUBTOTAL,
    }),
    shipingdata: {
      get() {
        return this.$store.getters[GET_SHIPMENT_DATA];
      },
      set(value) {
        this.setShipmentData(value);
      },
    },
    trans() {
      return trans;
    },
    formIsValid() {
      return this.personalValid && this.addressValid;
    },
  },
  data: () => {
    return {};
  },
  watch: {
    formIsValid(valid) {
      if (!valid) {
        this.$store.commit("steps/setStepsMaxIndex", "personal-data");
      }
    },
  },
  methods: {
    ...mapMutations({
      setAddressData: SET_ADDRESS_DATA_MUTATION,
      setPersonalData: SET_PERSONAL_DATA_MUTATION,
      setShipmentData: SET_SHIPMENT_DATA_MUTATION,
    }),
    handleAddressChange(payload) {
      this.setAddressData({
        ...this.addressForm,
        personal: {
          ...this.addressForm["personal"],
          [payload.name]: {
            ...this.addressForm["personal"][payload.name],
            value: payload.value,
          },
        },
      });
    },
    handlePersonalChange(payload) {
      this.setPersonalData({
        ...this.personalData,
        [payload.name]: {
          ...this.personalData[payload.name],
          value: payload.value,
        },
      });
    },
    handlePostalChange(payload) {
      this.setAddressData({
        ...this.addressForm,
        postal_code: {
          ...this.addressForm["postal"],
          value: payload.value,
        },
      });
    },
    handleCityChange(payload) {
      this.setAddressData({
        ...this.addressForm,
        city: {
          ...this.addressForm["city"],
          value: payload,
        },
      });
    },
    handleNext() {
      if (this.personalValid && this.addressValid)
        this.$router.push({
          name: "resume-payment",
          params: { lang: this.lang },
        });
    },
  },
  mounted() {
    window.onbeforeunload = () => {
      return this.trans.leaving[this.lang];
    };
  },
  beforeRouteEnter(to, from, next) {
    if (from.name == "order-placed") {
      window.location.href = "/";
    } else {
      next();
    }
  },
};
</script>
<style lang="scss">
@import "../../../../sass/_variables";

.shipment-container {
  margin-top: 2rem;
  display: flex;
  align-items: center;

  &.no-input {
    margin-top: 0;
    .shipment-container__label {
      margin-left: 0;
    }
  }

  .free-shipping-title {
    font-size: 18px;
  }

  .free-shipping-description {
    line-height: 1.25;
    font-size: 15px;
  }

  &__label {
    display: flex;
    flex-direction: column;
    margin-left: 1.2rem;
    font-size: 18px;
    .in-row {
      font-weight: bold;
      display: flex;
      justify-content: space-between;
    }
  }
  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #000;
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    content: "";
    width: 10px;
    height: 10px;
    background: $primary;
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.v-select {
  height: 48px;
  margin-top: 1.5rem;
  .vs__dropdown-toggle {
    border-radius: 0;
    border: 1px solid #bfbfbf;
    padding: 8px;
    width: 100%;
    height: 100%;
    background-color: transparent;
    color: #030218;
    cursor: text;
  }
}
</style>
