<template>
    <div class="select-container">
        <div class="mdc-tab-bar" role="tablist">
            <div class="mdc-tab-scroller">
                <div class="mdc-tab-scroller__scroll-area">
                    <div class="mdc-tab-scroller__scroll-content">
                        <button class="mdc-tab" role="tab" aria-selected="false" tabindex="0" @click="selectcategory('phones')" >
                            <span class="mdc-tab__content">
                                <span class="mdc-tab__text-label">{{trans.select1}}</span>
                            </span>
                            <span class="mdc-tab-indicator">
                                <span class="mdc-tab-indicator__content"></span>
                            </span>
                            <span class="mdc-tab__ripple"></span>
                        </button>

                        <button class="mdc-tab " role="tab" aria-selected="false" tabindex="0" @click="selectcategory('accessories')" >
                            <span class="mdc-tab__content">
                                <span class="mdc-tab__text-label">{{trans.select2}}</span>
                            </span>
                            <span class="mdc-tab-indicator">
                                <span class="mdc-tab-indicator__content"></span>
                            </span>
                            <span class="mdc-tab__ripple"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { MDCTabBar } from '@material/tab-bar';
import { mapGetters } from 'vuex';

export default {
    props:['category'],
    mounted() {
        const tabBar = new MDCTabBar(this.$el.querySelector('.mdc-tab-bar'));
        tabBar.activateTab(this.category === 'phones' ? 0 : 1);
    },
    computed: {
        ...mapGetters({
            locale: "getLocale",
            trans: "getTrans"
        })
    },
    methods: {
        selectcategory(val){
            this.$router.push(val);
        }
    },
}
</script>

<style lang="scss" scoped>
.select-container {
    max-width: 600px;
    margin: 3rem auto;
    padding: 0 1rem;
    @media (min-width: 1024px) {
        margin: 3rem auto;
    }

    .mdc-tab-scroller {
        box-shadow: 0 3px 20px 0 rgba(17, 17, 95, 0.1) !important;
        border-radius: 35px;

        .mdc-tab-scroller__scroll-content {
            max-width: 100%;
        }
    }

    .mdc-tab {
        transition: background-color 0.4s ease;
        height: 54px;
        width: 50%;
        border-radius: 35px;
        @media (min-width: 1024px) {
            height: 70px;
        }

        .mdc-tab__content {
            border-radius: 35px;
            transition: background-color 0.4s ease;
            padding: 0;
        }

        .mdc-tab__text-label {
            color: black;
            text-transform: none;
            font-weight: bold;
            font-size: 16px;
            transition: all 0.4s ease;
            white-space: normal;
        }
    }

    .mdc-tab__ripple.mdc-ripple-upgraded {
        opacity: 0;
    }

    .mdc-tab--active {
        // background-color: #3c3cfa;
        border-radius: 35px;

        .mdc-tab__text-label {
            color: white;
            font-weight: bold;
            font-size: 16px;
        }
    }

    .mdc-tab-indicator {
        background-color: white;
        transition: all 0.5s ease;
        border-radius: 35px;

        &--active {
            background-color: #3c3cfa !important;
            border-radius: 35px;
        }
    }
}
</style>
