<template>
    <div class="devices-component flex flex-col">
        <h1>{{ trans.steps[1].title }}</h1>
        <div class="cards">
            <button :class="{ selected : isSelected(device) }" @click="select(device)" v-for="device in devices" :key="device.id" class="card flex flex-col items-center justify-center font-bold">
                <component class="icon mb-4" :is="device.slug"></component>
                {{ trans.steps[1].devices[device.slug] }}
            </button>
            <button class="card" v-if="devices.length === 0" v-for="index in 3">
                <Skeleton :duration="0.75" :loading="true" class="skeleton" width="173px" height="207px"></Skeleton>
            </button>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { Skeleton } from "vue-loading-skeleton";
import icons from './icons/devices';

export default {
    components: {
        Skeleton,
        ...icons
    },
    computed: {
        ...mapGetters({
            devices: 'getDevices',
            trans: 'getTrans',
            selected: 'getSelected',
            brands: 'getBrands'
        }),
        selectedDevice() {
            return this.selected.device;
        }
    },
    watch: {
        selectedDevice(newVal, oldVal) {
            newVal = newVal?.id;
            oldVal = oldVal?.id;

            if (newVal && oldVal && newVal !== oldVal) {
                this.resetSelected(['model', 'damages', 'screen']);
                this.resetData(['models', 'damages']);
                this.setScreensShown(false);
                this.setMaxIndex(2);
            }
        }
    },
    methods: {
        ...mapMutations(['setSelected', 'resetSelected', 'resetData', 'setMaxIndex', 'setState', 'setScreensShown']),
        select(device) {
            this.setSelected({ prop: 'device', data: device });
            this.$store.dispatch('retrieveModels', device.id);
            this.$nextTick(() => {
                this.$router.push(this.$store.getters.getNextRoute(2)).catch(e => e);
            });
        },
        isSelected(device) {
            return this.selected.device?.id === device.id;
        }
    },
    created() {
        if (! this.$store.state.devices.length && ! this.$store.state.loading) {
            const brandSlug = this.$router.currentRoute.params.brand;
            this.$store.dispatch('retrieveBrand', brandSlug);
            this.setState({ prop: 'stepSelected', data: 1 });
            this.setState({ prop: 'maxIndex', data: 1 });
            const brand = this.brands.find(b => b.slug === brandSlug);
            this.setSelected({ prop: 'brand', data: brand });
        }
    }
};
</script>

<style scoped lang="scss">
.card {
    width: 150px;
    height: 207px;
    @media (min-width: 640px) {
        width: 173px;
        height: 207px;
    }
}
.icon {
    height: 117px;
}
</style>
