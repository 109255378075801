<template>
  <div class="items-center">
    <button
      v-if="!loading"
      id="submit"
      class="mdc-button mdc-button--unelevated next-button w-full"
      :class="{ disabled: buttonDisabled }"
      :disabled="buttonDisabled"
    >
      <div class="mdc-button__ripple"></div>
      <span class="font-bold">{{ trans.steps[7].confirm }}</span>
    </button>

    <button v-else class="mdc-button mdc-button--unelevated next-button w-full">
      <div class="mdc-button__ripple"></div>
      <clip-loader class="flex items-center" color="#ffffff"></clip-loader>
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
export default {
    props: ['buttonDisabled', 'loading'],
    components: {
      ClipLoader
    },
    computed: {
      ...mapGetters({
         trans: "getTrans",
      })
    }
};
</script>

<style>
</style>