<template>
  <div class="w-full py-3">
    <div id="card-element">
      <!-- Elements will create input elements here -->
    </div>

    <!-- We'll put the error messages in this element -->
    <div id="card-errors" class="pt-3 text-red" role="alert"></div>
    <div v-if="errorMessage">{{ errorMessage }}</div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapMutations } from "vuex";
import Vue from "vue";
import {
  optionsStripe,
  styleStripe,
} from "../../utils";

import { stripePaymentMethodHandlerCheckout } from './checkout';
import {
  GET_ADDRESS_DATA,
  GET_PERSONAL_DATA,
  GET_SHIPMENT_DATA,
  SET_ORDER_LOADING_TO_TRUE_MUTATION
} from "../../store/wizardShop/types";

export default {
  data: () => ({
    stripeAPIToken: "pk_test_TcJTx6HP71myOEIlm0O1uAvF00V8LQzLD9",
    stripe: "",
    elements: "",
    card: "",
  }),
  methods: {
    ...mapMutations(["setCardMessage", "setCardError", "setLoading"]),
    includeStripe(URL, callback) {
      let documentTag = document,
        tag = "script",
        object = documentTag.createElement(tag),
        scriptTag = documentTag.getElementsByTagName(tag)[0];
      object.src = "//" + URL;
      if (callback) {
        object.addEventListener(
          "load",
          function (e) {
            callback(null, e);
          },
          false
        );
      }
      scriptTag.parentNode.insertBefore(object, scriptTag);
    },
  },
  computed: {
    ...mapGetters({
      addressForm: GET_ADDRESS_DATA,
      personalData: GET_PERSONAL_DATA,
      errorMessage: "getcardMessage",
      locale: "getLocale",
      trans: "getTrans",
    }),
  },
  mounted() {
    this.includeStripe(
      "js.stripe.com/v3/",
      function () {
        const stripe = Stripe("pk_live_51H9Y8DGkqFJsTgCpBzj9JQjyGG8JDuNjV6r3sRSBMNn7VkAtqbslZwZ7OWBa4K9Ak7jtvJ5S1xYMdpc4wl9qqaG400lueGkZSl");
        var options = optionsStripe(this.locale);
        const elements = stripe.elements(options);
        var style = { ...styleStripe };

        var card = elements.create("card", {
          style: style,
          hidePostalCode: true,
          hideIcon: true,
        });
        card.mount("#card-element");
        card.on("change", ({ error, complete }) => {
          const displayError = document.getElementById("card-errors");
          displayError.textContent = error ? error.message : "";
          this.$emit("stripe-invalid", !complete);
        });

        var submitButton = document.getElementById("submit");

        let that = this;

        submitButton.addEventListener("click", async (event) => {
          this.$store.commit(SET_ORDER_LOADING_TO_TRUE_MUTATION);
          const result = await stripe.createPaymentMethod({
            type: "card",
            card: card,
            billing_details: {
              name: that.addressForm.name.value,
            },
          });

          console.log("button clicked");
          const wizardCheckout = {
            address: this.addressForm,
            personal: this.personalData,
            info: this.$store.state.shopExtraInfo,
            shipping: "standard"
          }
          stripePaymentMethodHandlerCheckout(stripe, result, wizardCheckout);
        });
      }.bind(this)
    );
  },
};
</script>

<style lang="scss">
#card-element {
  object-fit: contain;
  border-radius: 1.5rem;
  box-shadow: 0 3px 20px 0 rgba(17, 17, 95, 0.1);
  background-color: #ffffff;
  padding: 1rem;
}
.text-red {
  color: #db003e;
}
.notices {
  .toast-error {
    background: #000;
  }
  .toast {
    opacity: 1;
  }
}
</style>
