<template>
    <div class="damages-brands-component">
        <div class="repairs-content">
            <div v-for="damage in damages.slice(0, 8)" :key="damage.id" class="repair">
                <component class="icon my-2" :is="damage.icon"></component>
                <span class="text">{{damage.name[locale]}}</span>
            </div>
        </div>
        <div class="repairs-content collapsible" :style="{ maxHeight }">
            <div v-for="damage in damages.slice(8)" :key="damage.id" class="repair">
                <component class="icon my-2" :is="damage.icon"></component>
                <span class="text">{{damage.name[locale]}}</span>
            </div>
        </div>
        <button class="see-more mdc-button mdc-button--unelevated mdc-ripple-upgraded mx-auto mt-12 p-3" @click="toggleExpanded">
            <div class="mdc-button__ripple"></div>
            <span class="mdc-button__label">
                {{ expanded ? trans['collapse'] : trans['see-more'] }}
            </span>
            <span class="caret" :class="{ expanded }"></span>
        </button>
    </div>
</template>

<script>
import icons from './wizard/icons/damages';

export default {
    props: ['damages', 'locale', 'trans'],
    components: {
        ...icons,
    },
    data() {
        return {
            expanded: false,
        };
    },
    computed: {
        maxHeight() {
            return this.expanded ?
                this.$el.querySelector('.repairs-content.collapsible').scrollHeight + 'px' :
                0;
        }
    },
    methods: {
        toggleExpanded() {
            if (! this.expanded) {
                this.expanded = true;
                this.$nextTick(() => {
                    const top = this.$el.querySelector('.repairs-content.collapsible').offsetTop;
                    window.scrollTo(0, top);
                });
            } else {
                const top = document.querySelector('.what-repair').offsetTop - 72;
                window.scrollTo(0, top);
                setTimeout(() => this.expanded = false, 200);
            }
        }
    },
}
</script>
