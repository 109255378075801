<template>
    <div class="discount-component">
        <p class="mt-3" v-if="! descuentos">¿Tienes un código de descuento? ¡Ha llegado la hora de utilizarlo!</p>
        <div class="flex mt-3" v-if="! descuentos">
            <input type="text" v-model="discount" name="discount" class="input-descuento pl-3 mr-3" placeholder="código de descuento">
            <!-- <button class="btn btn-border p-0" @click="submit">
               <span v-if="!loading" class="mr-2">Aplicar código</span>
                <span class="flex items-center" v-else>loading</span>
            </button> -->
                  <btn-secondary
                    :handle-click="submit"
                    :loading="loading"
                    text="Aplicar Código"
                ></btn-secondary>
        </div>
        <div>
            <p v-if="codeerror" class="py-3 m-0" style="color:red">{{codeerror}}</p>
            <p v-if="CouponMessage" class="" style="color:red">{{CouponMessage}}</p>
            <p v-if="codemessage" class="py-3" style="color:green">{{codemessage}}</p>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import { mapMutations, mapGetters } from 'vuex';
import BtnSecondary from "../../common/buttons/BtnSecondary";

export default {
    components: {
        BtnSecondary
    },
    data() {
        return {
            discount: null,
            results: '',
            codeerror: '',
            loading: false,
        }
    },
    watch: {
        descuentos(){
            this.loading = true;
            setTimeout(() => {
                this.loading = false;
            }, 1000);
        }
    },
    methods: {
        ...mapMutations(['setCoupon']),
        submit(){
            this.loading = true;
            axios.get(`/coupon`, {params: {discount: this.discount}})
                .then(response => {
                        this.results = response.data
                        this.setCoupon(this.results);
                })
                .catch(error => {
                    this.codeerror= 'Este código no es válido';
                })
                .finally(() => this.loading = false);
        }
    },
    computed: {
        ...mapGetters({
            descuentos: 'getCouponApplicable',
            CouponMessage: 'getCouponMessage'
        }),
        codemessage() {
            if(this.descuentos){
                this.codeerror = '';
                return '¡Código añadido con éxito!';
            }else if(this.results && !this.descuentos){
                return false;
            }
        }
    },
}
</script>
<style lang="scss" scoped>
.input-descuento{
    background-color: #f5f5f5;
}
.discount-component {
    margin-bottom: 80px;
    @media (min-width: 1050px) {
        margin-bottom: 50px;
    }
}
</style>
