<template>
  <div class="scrollable-section-component">
    <div class="container">
      <slot name="top"></slot>
      <div class="grid-mobile grid lg:hidden">
        <slot name="grid-mobile-items"></slot>
      </div>
    </div>

    <scrollable
      class="hidden lg:block"
      ref="scrollable"
      v-bind:percentage.sync="percentage"
      v-bind:scroll-left.sync="scrollLeft"
      :items-count="itemsCount"
      :item-width="itemWidth"
    >
      <slot name="items"></slot>
    </scrollable>

    <div class="container">
      <div class="hidden lg:flex items-center mt-10">
        <button
          class="mr-2"
          @click="walk('left')"
          :class="{ disabled: scrollLeft === 0 }"
        >
          <img
            :src="`/img/icons/left-arrow${
              scrollLeft === 0 ? '-disabled' : ''
            }.svg`"
          />
        </button>
        <button
          class="mr-5"
          @click="walk('right')"
          :class="{ disabled: percentage === 100 }"
        >
          <img
            class="arrow-right"
            :src="`/img/icons/left-arrow${
              percentage === 100 ? '-disabled' : ''
            }.svg`"
          />
        </button>
        <div class="scrollbar-container flex-grow">
          <progress-bar
            bar-color="#030218"
            bg-color="#e9e9e9"
            :val="percentage"
            :bar-border-radius="1"
          >
          </progress-bar>
        </div>
      </div>
      <slot name="bottom"></slot>
    </div>
  </div>
</template>
<script>
import Scrollable from "./Scrollable";
import ProgressBar from "vue-simple-progress";
import {mapMutations} from 'vuex';

export default {
  props: {
    itemsCount: {
      type: Number,
      required: true,
    },
    itemWidth: {
      type: Number,
      required: true,
    },
  },
  components: {
    Scrollable,
    ProgressBar,
  },
  data() {
    return {
      percentage: 0,
      scrollLeft: 0,
    };
  },
  methods: {
    ...mapMutations(["setState"]),
    walk(direction) {
      this.$refs.scrollable.walk(direction);
    },
  },
  mounted() {
    this.setState({ prop: "trans", data: window.translations });
    this.setState({ prop: "locale", data: window.appLocale });
  },
};
</script>
<style lang="scss" scoped>
@import "../../../../sass/_variables";

.scrollable-section-component {
  margin-left: auto;
  padding: 70px 0;
  background-color: #f5f6f8;

  .title {
    color: $secondary;
    margin-bottom: 40px;
    @media (min-width: 1024px) {
      margin-bottom: 50px;
    }
  }
  &-grid {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 1rem;
  }

  button {
    outline: none;

    &.disabled {
      pointer-events: none;
    }

    .arrow-right {
      transform: rotate(180deg);
    }
  }
}
</style>
