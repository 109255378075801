import Vuex from "vuex";
import Vue from "vue";
import { wizardMutations } from "./mutations";
import { wizardActions } from "./actions";
import { checkEmptyObject } from "../utils";
import { wizardShopState, wizardShopGetters, wizardShopMutations, wizardShopActions } from "./wizardShop";
import { cartState, cartGetters, cartMutations, cartActions } from "./cart";
import { shopState, shopGetters, shopMutations, shopActions } from "./shop";

import routes from "../routes";
Vue.use(Vuex);
export const store = new Vuex.Store({
    state: {
        ...shopState,
        brands: [],
        devices: [],
        models: [],
        damages: [],
        screens: [],
        extras: [],
        services: [],
        scheduleClosedDates: null,
        schedule: {},
        selected: {
            brand: null,
            device: null,
            model: null,
            damages: null,
            screen: null,
            postalCode: null,
            service: null,
            date: null,
            time: null,
            extras: null,
            contact: {
                name: null,
                email: null,
                phone: null,
                comments: null
            },
            address: {
                address: null,
                postalCode: null,
                info: null,
                city: null
            },
            paymentMethod: '',
            cardName: "",
            discountCode: ""
        },
        routes: routes[0].children,
        trans: null,
        locale: 'es',
        loading: false,
        screensShown: false,
        maxIndex: 0,
        stepSelected: 0,
        cardError: false,
        cardMessage: "",
        disableSubmitButton: false,
        discountObject: {
            loading: false,
            data: {}
        },
        appointment_id: null,
        ...wizardShopState,
        ...cartState
    },
    getters: {
        ...shopGetters,
        getcardMessage(state) {
            return state.cardMessage;
        },
        getCardError(state) {
            return state.cardError;
        },
        getTrans(state) {
            return state.trans;
        },
        getLocale(state) {
            return state.locale;
        },
        getBrands(state) {
            return state.brands;
        },
        getModels(state) {
            return state.models;
        },
        getDevices(state) {
            return state.devices;
        },
        getDamages(state) {
            var damages_ordered = state.damages.sort((a,b) => a.order_position - b.order_position);
            return damages_ordered;
        },
        getScreens(state) {
            return state.screens;
        },
        getServices(state) {
            return state.services;
        },
        getScheduleClosedDays(state) {
            return state.scheduleClosedDays;
        },
        getSchedule(state) {
            return state.schedule;
        },
        getExtras(state) {
            return state.extras;
        },
        getSelected(state) {
            return state.selected;
        },
        getRoutes(state) {
            return state.routes;
        },
        getLoading(state) {
            return state.loading;
        },
        getSelected(state) {
            return state.selected;
        },
        getScreensMustBeShown(state) {
            return state.selected.damages?.filter(d => d.screen).length;
        },
        getMaxIndex(state) {
            return state.maxIndex;
        },
        getStepSelected(state) {
            return state.stepSelected;
        },
        getNextRoute: state => index => {
            const prop = state.locale === "es" ? "alias" : "path";
            let route = state.routes[index][prop];
            route = route.replace(":brand", state.selected.brand?.slug);
            route = route.replace(":model", state.selected.model?.slug);
            return `/${state.locale}/wizard/${route}`;
        },
        getDiscountCode(state) {
            return state.selected.discountCode;
        },
        getDiscountObjectData(state) {
            return state.discountObject.data;
        },
        getDiscountLoader(state) {
            return state.discountObject.loading;
        },
        getValidDiscountCode(state, getters) {
            return !checkEmptyObject(getters.getDiscountObjectData);
        },
        getPriceWithoutDiscount(state) {
            let totalPrice = 0;
            state.selected.damages.forEach((damage, index) => {
                totalPrice =
                    parseFloat(totalPrice) + parseFloat(damage.pivot.price);
            });
            const servicePrice = state.selected.service?.service_price_selected.price;
            if (servicePrice) {
                totalPrice += parseFloat(servicePrice);
            }
            state.selected.extras?.forEach((extra, index) => {
                totalPrice = parseFloat(totalPrice) + parseFloat(extra.price);
            });

            if(state.selected.paymentMethod == 'cash') totalPrice += 5;
            return totalPrice;
        },
        getPriceToDiscount(state, getters) {
            if (
                !state.selected.discountCode ||
                !getters.getDiscountCodeIsApplicable
            )
                return 0;

            const { type, value_discount } = getters.getDiscountObjectData;

            switch (type) {
                case "percent":
                    return Number(
                        (
                            (getters.getPriceWithoutDiscount / 100) *
                            value_discount
                        ).toFixed(2)
                    );
                case "fixprice":
                    return value_discount;
            }
        },
        getTotalPrice(state, getters) {
            const priceWithoutDiscount = getters.getPriceWithoutDiscount;
            const priceToDiscount = getters.getPriceToDiscount;
            return Number((priceWithoutDiscount - priceToDiscount).toFixed(2));
        },
        getDiscountCodeIsApplicable(state, getters) {
            if (!getters.getValidDiscountCode) return false;

            const discountData = getters.getDiscountObjectData;

            switch (discountData.requirements) {
                case "monto":
                    return (
                        getters.getPriceWithoutDiscount >= discountData.req_qty
                    );
                case "none":
                    return true;
            }
        },
        getAppointmentId(state){
            return state.appointment_id;
        },
        ...wizardShopGetters,
        ...cartGetters
    },
    mutations: {
        ...wizardMutations,
        ...wizardShopMutations,
        ...cartMutations,
        ...shopMutations,
    },
    actions: {
        ...wizardActions,
        ...wizardShopActions,
        ...cartActions,
        ...shopActions,
    }
});
