import Vue from "vue";
import { mapMutations } from "vuex";
import axios from "axios";

export const optionsStripe = locale => {
    return {
        fonts: [
            {
                family: "pragmatica",
                src:
                    'url("https://use.typekit.net/af/c9f384/0000000000000000000100ca/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/c9f384/0000000000000000000100ca/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/c9f384/0000000000000000000100ca/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype")',
                weight: "400"
            }
        ],
        locale: locale
    };
};

export const styleStripe = {
    base: {
        iconColor: "#808087",
        color: "#808087",
        fontWeight: 400,
        fontFamily: "pragmatica, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": {
            color: "#fce883"
        },
        "::placeholder": {
            color: "#808087"
        }
    },
    invalid: {
        iconColor: "#DB003E",
        color: "#DB003E"
    }
};

export const prepareWizard = wizard => {
    return {
        ...wizard,
        damagesFormat: wizard.damages.map(d => d.pivot.id),
        extrasFormat: wizard.extras ? wizard.extras.map(e => e.id) : ""
    };
};

export const saveAppointment = async (wizard) => {
    return axios
        .post("/api/stripe/appointment", { wizard })
        .then(res => {
            return res.data;
        })
        .catch(err => {
            store.commit("setLoading", false);
            store.commit("setCardMessage", err);
            Vue.$toast.error(err, { position: "bottom" });
        });
};

export const stripePaymentMethodHandler = async (
    stripe,
    result,
    wizard,
    appointment_id
) => {
    if (result.error) {
        // Show error in payment form
        store.commit("setLoading", false);
        Vue.$toast.error(result.error.message, { position: "bottom" });
    } else {
        // Otherwise send paymentMethod.id to your server
        const res = await fetch("/api/pay", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                payment_method_id: result.paymentMethod.id,
                wizard: wizard,
                appointment_id: appointment_id
            })
        });
        const paymentResponse = await res.json();

        handleServerResponse(stripe, paymentResponse, wizard, appointment_id);
    }
};

export const handleServerResponse = async (
    stripe,
    response,
    wizard,
    appointment_id
) => {
    if (response.err) {
        store.commit("setLoading", false);
        store.commit("setCardMessage", response.err);
        Vue.$toast.error(response.err, { position: "bottom" });
    } else if (response.requires_action) {
        const {
            error: errorAction,
            paymentIntent
        } = await stripe.handleCardAction(
            response.payment_intent_client_secret
        );
        if (errorAction) {
            Vue.$toast.error(errorAction, { position: "bottom" });
        } else {
            // The card action has been handled
            // The PaymentIntent can be confirmed again on the server
            const serverResponse = await fetch("/api/pay", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    payment_intent_id: paymentIntent.id,
                    wizard: wizard,
                    appointment_id: appointment_id
                })
            });
            handleServerResponse(
                stripe,
                await serverResponse.json(),
                wizard,
                appointment_id
            );
        }
    } else {
        store.commit("setCardError", true);
        router.replace(store.getters.getNextRoute(8));
        store.commit("setLoading", false);
    }
};

export const checkEmptyObject = obj => {
    return Object.entries(obj).length === 0;
};
