import axios from "axios";

export const wizardActions = {
    retrieveBrand(context, slug) {
        context.commit('setState', { prop: 'loading', data: true })
        return axios.get('/api/brand/'+slug)
            .then(({ data }) => {
                context.commit('setState', { prop: 'devices', data });
                context.commit('setState', { prop: 'loading', data: false })
            }).catch(err => {
                console.log(err);
            });
    },
    retrieveModels(context, deviceId) {
        context.commit('setState', { prop: 'loading', data: true })
        return axios.get('/api/devices/'+deviceId+'/models')
            .then(({ data }) => {
                context.commit('setState', { prop: 'models', data });
                context.commit('setState', { prop: 'loading', data: false })
            }).catch(err => {
                console.log(err);
            });
    },
    retrieveDamages(context, modelId) {
        context.commit('setState', { prop: 'loading', data: true })
        axios.get('/api/models/'+modelId+'/damages')
            .then(({ data }) => {
                context.commit('setState', { prop: 'damages', data });
                context.commit('setState', { prop: 'loading', data: false })
            }).catch(err => {
                console.log(err);
            });
    },
    retrieveScreens(context) {
        context.commit('setState', { prop: 'loading', data: true })
        axios.get('/api/screens')
            .then(({ data }) => {
                context.commit('setState', { prop: 'screens', data });
                context.commit('setState', { prop: 'loading', data: false })
            }).catch(err => {
                console.log(err);
            });
    },
    retrieveServices(context) {
        axios.get('/api/services')
            .then(({ data }) => {
                context.commit('setState', { prop: 'services', data });
            }).catch(err => {
                console.log(err);
            });
    },
    retrieveScheduleClosedDates({ commit, state }, date) {
        return axios.get(`/api/schedule/${state.selected.service.id}/closed`)
            .then(({ data }) => {
                commit('setScheduleClosedDates', data);
            }).catch(err => {
                console.log(err);
            });
    },
    retrieveScheduleForDate({ commit, state }, date) {
        if (!date) return;
        return axios.get(`/api/schedule/${state.selected.service.id}/${date}`)
            .then(({ data: times }) => {
                commit('setScheduleForDate', { date, times });
            }).catch(err => {
                console.log(err);
            });
    },
    retrieveFirstAvailableDateForService({ state }) {
        return axios.get(`/api/schedule/${state.selected.service.id}/first-available`)
            .catch(err => {
                console.log(err);
            });
    },
    retrieveExtras(context) {
        context.commit('setState', { prop: 'loading', data: true })
        axios.get('/api/extras')
            .then(({ data }) => {
                context.commit('setState', { prop: 'extras', data });
                context.commit('setState', { prop: 'loading', data: false })
            }).catch(err => {
                console.log(err);
            });
    },
    retrieveDiscountCode(context, payload){
        context.commit('setDiscountLoadingToTrue');
        axios.get('/coupon?discount='+payload)
            .then(({data}) => {
                context.commit('setDiscountObjectData', data);
            }).catch(err => {
                context.commit('setDiscountObjectData', {});
            }).finally(() => context.commit('setDiscountLoadingToFalse'));
    }
}
