<template>
    <div class="shop-base-container">
        <drawer-component :open="open"></drawer-component>
        <select-category :category="category"></select-category>
        <div class="grid-shop">
            <div class="shop-filter-desktop">
                <filter-component></filter-component>
            </div>
            <div class="w-full">
                <div class="products-skeleton" v-if="loading">
                    <product-skeleton v-for="n in 12" :key="n" />
                </div>
                <transition name="slide-fade">
                    <div v-if="!loading" class="grid-products" :class="{ block: !products.length }">
                        <p v-if="!products.length" class="no-products-warning">{{ trans['no-products-found'] }}</p>
                        <product-card v-else v-for="product in products" :key="product.id" :product="product"></product-card>
                    </div>
                </transition>
            </div>
        </div>
        <div class="button-filter-mobile" @click="openDrawer()">
            <button><img src="/img/icons/filter.svg" alt=""></button>
        </div>
    </div>
</template>
<script>
import ProductCard from './ProductCard.vue';
import SelectCategory from './SelectCategory';
import FilterComponent from './FilterComponent';
import DrawerComponent from './DrawerComponent';
import { mapMutations , mapGetters } from 'vuex';
import ProductSkeleton from './ProductSkeleton';

export default {
    computed:{
        ...mapGetters({
            loading: 'getShopLoading',
            products: 'getShopProductsFiltered',
        }),
    },
    components:{
        SelectCategory,ProductCard,ProductSkeleton,FilterComponent,DrawerComponent
    },
    props:['trans'],
    data() {
        return {
            category: '',
            open: false
        }
    },
    watch:{
        $route(to, from){
            this.category = to.path.substring(to.path.lastIndexOf('/') + 1);
            this.$store.commit('setCategory', this.category)
            this.getProducts();
        },
    },
    methods: {
        ...mapMutations(['setState']),
        getProducts(){
            this.$store.dispatch('resetFilter');
            this.setState({ prop: 'shopLoading', data: true });
            this.$store.dispatch("loadproducts", this.category);
        },
        showFilter(){
            this.opendrawer = true
        },
        openDrawer(){
            document.getElementById("filter-drawer").style.width = "100%";
            this.open = true;
        },
        closeDrawer(){
             document.getElementById("filter-drawer").style.width = "0";
             this.open = false;
        }
    },
    created(){
        this.setState({ prop: 'trans', data: this.trans });
        this.setState({ prop: 'locale', data: window.appLocale });
    },
    beforeMount () {
        this.category = this.$route.path.substring(this.$route.path.lastIndexOf('/') + 1);
        this.$store.commit('setCategory', this.category)
        this.getProducts();
    }
}
</script>
<style lang="scss" scoped>
@import '../../../sass/variables';

.grid-shop{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    @media (min-width: $lg) {
        width: 92%;
        margin-left: 4%;
    }

    .grid-products,.products-skeleton{
        display: grid;
        grid-template-columns: repeat(auto-fill,262px);
        gap: 20px;
        width: 100%;
        justify-content: center;
        @media (max-width:640px) {
            grid-template-columns: 1fr 1fr;
            padding: 0 1rem;
        }
    }
    .grid-products .no-products-warning {
        text-align: center;
        @media (min-width: $lg) {
            margin-left: -22%;
        }
    }
    .shop-filter-desktop{
        display: none;
        @media (min-width:1024px) {
            display: flex;
            justify-content: left;
            width: 25%;
            max-width: 250px;
            margin-right: 2rem;
            height: 100%;
        }
    }
    .form-filter{
        padding: 0 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .products-skeleton {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
// * Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
    transition: all 0.8s ease;
}
.slide-fade-leave-active {
    transition: all 0.6s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
}
.button-filter-mobile{
    position: fixed;
    bottom: 5.75rem;
    right: 5%;
    background-color: #3c3cfa;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 35px;
    box-shadow: 0 7px 20px 0 rgba(17, 17, 95, 0.52);
    width: 62px;
    height: 62px;
    @media (min-width: $sm) {
        bottom: 6.5rem;
    }
    @media (min-width: $lg) {
        right: 2%;
        bottom: 125px;
        display: none;
    }
}
</style>
