<template>
    <svg width="74px" height="74px" viewBox="0 0 74 74" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>Artboard Copy</title>
        <g id="Artboard-Copy" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" class="no-fill">
            <g id="Group-6" transform="translate(3.000000, 2.000000)" fill="#AEAEB3">
                <g id="Group-3" transform="translate(0.000000, 0.684000)">
                    <path d="M97.9876,0.3161 L15.4776,0.3161 C6.9316,0.3231 0.0076,7.2491 -0.0004,15.7951 L-0.0004,202.5831 C0.0396,211.1041 6.9556,217.9931 15.4776,218.0001 L97.9876,218.0001 C106.5086,217.9931 113.4256,211.1041 113.4666,202.5831 L113.4666,15.7951 C113.4606,7.2491 106.5336,0.3231 97.9876,0.3161 M97.9876,214.9161 L15.4776,214.9161 C8.6586,214.9101 3.1236,209.4001 3.0826,202.5831 L3.0826,15.7951 C3.1236,8.9761 8.6586,3.4681 15.4776,3.4611 L97.9876,3.4611 C104.8056,3.5021 110.3146,9.0381 110.3206,15.8561 L110.3206,202.5831 C110.2806,209.3781 104.7826,214.8761 97.9876,214.9161" id="Fill-1"></path>
                </g>
                <path d="M18.1956,35.2638 L23.1366,31.9038 L29.1656,31.0788 L27.4386,37.6638 C26.7186,37.8168 25.9736,37.9008 25.2096,37.9008 C22.5256,37.9008 20.0756,36.9008 18.1956,35.2638 M17.2586,20.0528 L16.6516,27.8218 C16.6216,28.2008 16.8096,28.5638 17.1366,28.7588 L20.9066,31.0018 L16.7976,33.7948 C15.3656,31.9748 14.5046,29.6858 14.5046,27.1958 C14.5046,24.4498 15.5526,21.9498 17.2586,20.0528 M27.6336,16.7798 L29.2436,22.4128 L23.6356,23.3618 C23.2116,23.4328 22.8816,23.7678 22.8146,24.1928 L22.0086,29.3308 L18.6936,27.3578 L19.4096,18.2118 C21.0826,17.1278 23.0716,16.4918 25.2096,16.4918 C26.0446,16.4918 26.8536,16.5978 27.6336,16.7798 M35.9146,27.1958 C35.9146,31.4908 33.3666,35.1928 29.7076,36.8968 L31.4796,30.1388 C31.5646,29.8158 31.4826,29.4718 31.2606,29.2218 C31.0396,28.9718 30.7086,28.8458 30.3766,28.8948 L23.9646,29.7728 L24.6786,25.2138 L30.6786,24.1988 C30.9596,24.1508 31.2066,23.9868 31.3586,23.7458 C31.5096,23.5048 31.5516,23.2108 31.4736,22.9378 L29.9526,17.6148 C33.4796,19.3678 35.9146,22.9978 35.9146,27.1958 M37.9146,27.1958 C37.9146,21.5018 34.1476,16.6708 28.9746,15.0618 C28.7896,14.9338 28.5696,14.8788 28.3366,14.8968 C27.3346,14.6418 26.2906,14.4918 25.2096,14.4918 C22.4556,14.4918 19.9106,15.3818 17.8266,16.8768 C17.8146,16.8868 17.8016,16.8938 17.7896,16.9048 C14.5946,19.2148 12.5046,22.9618 12.5046,27.1958 C12.5046,34.2018 18.2036,39.9008 25.2096,39.9008 C26.3496,39.9008 27.4486,39.7358 28.5016,39.4528 C28.5356,39.4438 28.5696,39.4388 28.6026,39.4268 C33.9636,37.9368 37.9146,33.0248 37.9146,27.1958" id="Fill-4"></path>
            </g>
        </g>
    </svg>
</template>

<script>
export default {};
</script>
