import * as TYPES from "./types";
import { actions } from "@ricadesign/vuex-cart";
import getters from "./getters";
import mutations from "./mutations";

export const cartState = {
    cartItems: [],
    cartLoading: false,
    shipmentPrice: 4.95,
    // discount: 0,
    // coupon: null,
    // couponmessage: ""
};

export const cartGetters = {
    ...getters
};

export const cartMutations = {
    ...mutations
};

export const cartActions = {
    ...actions
};
