<template>
    <svg width="66px" height="120px" viewBox="0 0 66 120" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <!-- Generator: sketchtool 63.1 (101010) - https://sketch.com -->
        <title>EDE0A74F-62D4-493A-AB69-F88221FCF602</title>
        <desc>Created with sketchtool.</desc>
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(-714.000000, -375.000000)" fill="#AEAEB3" fill-rule="nonzero" stroke="#FFFFFF" stroke-width="1.15873453">
                <path d="M769.907355,376.491747 L724.466785,376.491747 C719.240857,376.497621 715.005869,380.736024 715,385.966167 L715,484.500133 C715.005869,489.730276 719.240857,493.968679 724.466785,493.974553 L769.907355,493.974553 C775.133283,493.968679 779.368271,489.730276 779.37414,484.500133 L779.37414,385.966167 C779.368271,380.736024 775.133283,376.497621 769.907355,376.491747 L769.907355,376.491747 Z M760.024504,380.281515 L758.88849,385.966167 L735.485887,385.966167 L734.349873,380.281515 L760.024504,380.281515 Z M775.587426,484.500133 C775.583905,487.638219 773.042912,490.181261 769.907355,490.184785 L724.466785,490.184785 C721.331228,490.181261 718.790236,487.638219 718.786714,484.500133 L718.786714,385.966167 C718.790236,382.828081 721.331228,380.285039 724.466785,380.281515 L730.487897,380.281515 L732.076897,388.232685 C732.253911,389.118424 733.031017,389.755976 733.933571,389.755935 L760.44057,389.755935 C761.343123,389.755976 762.12023,389.118424 762.297243,388.232685 L763.886243,380.281515 L769.907355,380.281515 C773.042912,380.285039 775.583905,382.828081 775.587426,385.966167 L775.587426,484.500133 Z" id="phones-device-wizard"></path>
            </g>
        </g>
    </svg>
</template>

<script>
export default {};
</script>
