import WizardContent from "../components/wizard/WizardContent";
import Brands from "../components/wizard/Brands";
import Devices from "../components/wizard/Devices";
import Models from "../components/wizard/Models";
import Repair from "../components/wizard/Repair";
import Services from "../components/wizard/Services";
import Calendar from "../components/wizard/Calendar";
import Extras from "../components/wizard/Extras";
import Payment from "../components/wizard/Payment";
import PersonalData from "../components/wizard/PersonalData";
import Confirmation from "../components/wizard/Confirmation";

const routes = [
    {
        path: "/:lang?/wizard",
        component: WizardContent,
        props: true,
        children: [
            {
                path: "brands",
                name: "brand",
                component: Brands,
                alias: "marcas"
            },
            {
                path: "brands/:brand",
                name: "device",
                component: Devices,
                alias: "marcas/:brand"
            },
            {
                path: "brands/:brand/models",
                name: "model",
                component: Models,
                alias: "marcas/:brand/modelos"
            },
            {
                path: "brands/:brand/:model/repair",
                name: "damages",
                component: Repair,
                alias: "marcas/:brand/:model/reparacion"
            },
            {
                path: "brands/:brand/:model/services",
                name: "services",
                component: Services,
                alias: "marcas/:brand/:model/servicios"
            },
            {
                path: "brands/:brand/:model/calendar",
                name: "calendar",
                component: Calendar,
                alias: "marcas/:brand/:model/calendario"
            },
            // {
            //     path: "brands/:brand/:model/extras",
            //     name: "extras",
            //     component: Extras,
            //     alias: "marcas/:brand/:model/extras"
            // },
            {
                path: "brands/:brand/:model/data",
                name: "personal",
                component: PersonalData,
                alias: "marcas/:brand/:model/datos"
            },
            {
                path: "brands/:brand/:model/payment",
                name: "payment",
                component: Payment,
                alias: "marcas/:brand/:model/pago"
            },
            {
                path: "brands/:brand/:model/confirmation",
                name: "confirmation",
                component: Confirmation,
                alias: "marcas/:brand/:model/confirmacion"
            }
        ]
    }
];

export default routes;
