<template>
  <div class="w-full">
    <rica-vue-stepper
      class="rica-vue-stepper"
      :steps="routes"
      :max-index="stepperMaxIndex"
      :step-navigation-is-disabled="stepperIsDisabled"
      primary-color="#3c3cfa"
      pending-text-color="#006bc2"
    >
    </rica-vue-stepper>
    <transition appear name="slide-fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import RicaVueStepper from "@ricadesign/vue-wizard";
import routes from "../../routes/wizardShop";
import { eventBus } from "../../app";
import {
  GET_ADDRESS_DATA,
  GET_PERSONAL_DATA,
  SET_ADDRESS_DATA_MUTATION,
  SET_PERSONAL_DATA_MUTATION,
  SET_CARDS,
} from "../../store/wizardShop/types";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  props: {
    personalData: {
      type: Object,
      required: false,
    },
    address: {
      type: Object,
      required: false,
    },
    cards: {
      type: Array,
      required: false,
    },
  },
  components: {
    RicaVueStepper,
  },
  data() {
    return {
      routes: routes[0].children.slice(0, -2),
    };
  },
  computed: {
    ...mapGetters({
      addressObject: GET_ADDRESS_DATA,
      personalObject: GET_PERSONAL_DATA,
    }),
    stepperMaxIndex() {
      return (
        this.routes.findIndex((route) => route.name === this.$route.name) - 1
      );
    },
    stepperIsDisabled() {
      return this.$route.name === "confirmacion-preinscripcion";
    },
  },
  methods: {
    ...mapMutations({
      setAddressData: SET_ADDRESS_DATA_MUTATION,
      setPersonalData: SET_PERSONAL_DATA_MUTATION,
      setCards: SET_CARDS,
    }),
  },
  created() {
    eventBus.$on("something-went-wrong", (toPath) => {
      this.$toast.error(this.trans.get("customer.something-went-wrong"));
    });
  },
};
</script>

<style lang="scss">
</style>
