const shopMutations = {
    setFilter(state, { prop, value }) {
        state.filter[prop] = value;
    },
    setProducts(state,payload){
        state.products = payload;
    },
    setProductsFiltered(state,payload){
        state.products_filtered = payload;
    },
    setCategory(state,payload){
        state.category = payload;
    }
};

export default shopMutations;
