<template>
    <div class="flex justify-center wizard-form-container">
        <ValidationObserver v-slot="{ invalid }">
            <form @submit.prevent="onSubmit" class="w-full">
                <div class="wizard-form">
                    <div class="wizard-article">
                        <div class="article-form">
                            <h3 class="title-form">{{ trans.steps[7]['personal-info'] }}</h3>
                            <ValidationProvider name="name" rules="required" v-slot="{ errors }">
                                <input v-model="selected.contact.name" class="outline-none rounded-lg appearance-none block w-full py-3 px-4 my-3" type="text" :placeholder="trans.steps[7].name + '*'"/>
                                <span class="text-red-500">{{errors[0]}}</span>
                            </ValidationProvider>
                            <ValidationProvider name="email" rules="required|email" v-slot="{ errors }" >
                                <input v-model="selected.contact.email" class="outline-none rounded-lg appearance-none block w-full py-3 px-4 my-3" type="email" :placeholder="trans.steps[7].email + '*'"/>
                                <span class="text-red-500">{{errors[0]}}</span>
                            </ValidationProvider>
                            <ValidationProvider name="phone" rules="required|numeric" v-slot="{ errors }">
                                <input v-model="selected.contact.phone" class="outline-none rounded-lg appearance-none block w-full py-3 px-4 my-3" type="tel" :placeholder="trans.steps[7].phone + '*'"/>
                                <span class="text-red-500">{{errors[0]}}</span>
                            </ValidationProvider>
                            <textarea v-model="selected.contact.comments" class="outline-none rounded-lg appearance-none block w-full py-3 px-4 my-3 " name="" id="" cols="30" rows="5" :placeholder="trans.steps[7]['additional-comments']"></textarea>
                        </div>
                        <div class="article-form" v-if="selected.service.ask_for_address">
                            <h3 class="title-form mt-10">{{ trans.steps[7].address }}</h3>
                            <ValidationProvider name="address" rules="required" v-slot="{ errors }">
                                <input v-model="selected.address.address" class="outline-none rounded-lg appearance-none block w-full py-3 px-4 my-3" type="text" :placeholder="trans.steps[7].address + '*'"/>
                                <span class="text-red-500">{{ errors[0] }}</span>
                            </ValidationProvider>
                            <ValidationProvider name="addressPostal" rules="required|numeric" v-slot="{ errors }">
                                <input v-model="selected.address.postalCode" class="outline-none rounded-lg appearance-none block w-full py-3 px-4 my-3" type="number" :placeholder="trans.steps[7]['postal-code'] + '*'"/>
                                <span class="text-red-500">{{ errors[0] }}</span>
                            </ValidationProvider>
                            <ValidationProvider name="addressInfo" v-slot="{ errors }">
                                <input v-model="selected.address.info" class="outline-none rounded-lg appearance-none block w-full py-3 px-4 my-3" type="text" :placeholder="trans.steps[7]['more-info']"/>
                                <span class="text-red-500">{{ errors[0] }}</span>
                            </ValidationProvider>
                            <ValidationProvider name="addressCity" rules="required" v-slot="{ errors }">
                                <input v-model="selected.address.city" class="outline-none rounded-lg appearance-none block w-full py-3 px-4 my-3" type="text" :placeholder="trans.steps[7].city + '*'"/>
                                <span class="text-red-500">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <save-appointment-button :button-disabled="buttonIsDisabled(invalid)" :loading="loading"></save-appointment-button>
                    </div>
    
                    <div class="wizard-article">
                        <resume-component :selected="selected" :damages="damages" :trans="trans" :locale="locale" :select-date="selectDate"></resume-component>
                    </div>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import {
    ValidationProvider,
    ValidationObserver,
    extend,
    localize
} from "vee-validate";
import es from "vee-validate/dist/locale/es.json";
import * as rules from "vee-validate/dist/rules";
import { mapGetters,mapMutations  } from "vuex";
import axios from "axios";
import Stripe from "./Stripe.vue";
import Coupon from "./Coupon.vue";
import { prepareWizard } from "../../utils";
import SaveAppointmentButton from './paymentBtn/SaveAppointmentButton.vue';
import Resume from "./Resume.vue";

// install rules and localization
Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule]);
});

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        Stripe,
        CouponComponent: Coupon,
        SaveAppointmentButton,
        ResumeComponent: Resume
    },
    data: () => ({
        invalid: false,
        stripeInvalid: true
    }),
    computed: {
        ...mapGetters({
            selected: "getSelected",
            trans: "getTrans",
            locale: "getLocale",
            carderror: "getCardError",
            priceToDiscount: "getPriceToDiscount",
            validDiscountCode: "getValidDiscountCode",
            isApplicable: "getDiscountCodeIsApplicable",
        }),
        damages(){
            return this.selected.damages.map(damage => ({
                ...damage,
            }));
        },
        price(){
            return this.$store.getters.getTotalPrice
        },
        selectDate()
        {
            const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            const lang = this.locale;
            const fecha = this.selected.date.toLocaleDateString(lang, options);
            return fecha;
        },
        loading() {
            return this.$store.state.loading;
        },
        paymentMethod: {
            get() {
                return this.selected.paymentMethod;
            },
            set(data) {
                this.setSelected({ prop: 'paymentMethod', data });
            }
        },
        showPaymentMethodSelector() {
            return this.selected.service.show_full_calendar;
        },
        isMobile(){
            return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        },
    },
    methods: {
        ...mapMutations(['setSelected', 'setLoading']),
        async onSubmit(event) {
            event.preventDefault();
            event.stopPropagation();
            this.setLoading(true);
            if (this.selected.paymentMethod === 'delivery') {
                try {
                    await axios.post('/api/appointments', {
                        wizard: {
                            ...prepareWizard(this.selected),
                            locale: this.locale,
                        }
                    });
                    this.$router.replace(store.getters.getNextRoute(8));
                } catch (e) {
                    Vue.$toast.error(this.trans.steps[7].error, { position: "bottom" });
                } finally {
                    this.setLoading(false);
                }
            }
        },
        buttonIsDisabled(invalid) {
            return invalid;
        }
    },

    created() {
        const names = {
            name: this.trans.steps[7].name,
            email: this.trans.steps[7].email,
            phone: this.trans.steps[7].phone,
            addressName: this.trans.steps[7].name,
            address: this.trans.steps[7].address,
            addressPostal: this.trans.steps[7]['postal-code'],
            addressCity: this.trans.steps[7].city,
            cardName: this.trans.steps[7]['card-name']
        };
        localize({
            en: { names },
            es: { names }
        });
        if (this.locale === 'es') {
            localize("es", es);
        }
    }
};
</script>

<style scoped lang="scss">
.disabled {
    background-color: #d5d5da!important;
}
.accepted-cards {
    display: flex;
}
.wizard-form-container {
    @media(max-width: 1024px) {
        width: 80%;
        margin: 0 auto;
        flex-direction: column;
        .wizard-form {
            .wizard-article:nth-child(2) {
                display: none;
            }
        }
    }
    @media(max-width: 640px) {
        width: 100%;
    }
}
</style>
