<template>
  <transition name="slide-fade" appear>
    <div class="add-item-container">
      <div class="qty-price-container">
        <div class="qty-container">
          <input
            class="plus-minus"
            type="button"
            value="-"
            id="moins"
            @click="minus"
          />
          <input
            class="input-qty"
            type="text"
            size="2"
            v-model="quantity"
            id="count"
            @keydown="checkIfNumeric($event)"
          />
          <input
            class="plus-minus"
            type="button"
            value="+"
            id="plus"
            @click="plus"
          />
        </div>
        <btn-primary
          :disabled="item.stock < 1"
          :handle-click="addItem"
          class="add-item-btn"
          :text="text[lang]"
        ></btn-primary>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from "vuex";
import BtnPrimary from "../../components/common/buttons/BtnPrimary";
import mixin from "../../mixin";
import { addCartItem } from "../../app/cart";
import { notifyKO } from '../../app/cart';
import { GET_PRODUCT_HAS_ENOUGH_STOCK } from '../../store/cart/types';

export default {
  mixins: [mixin],
  components: {
    BtnPrimary,
  },
  props: ["item"],
  data: () => {
    return {
      pack_quantity: null,
      quantity: 1,
      text: {
        es: "Añadir a la cesta",
        en: "Add to bag",
      },
      toastMessage: {
        es: "Producto añadido al carrito correctamente",
        en: "Product added to cart successfully",
      },
      toastNotEnoughStockMessage: {
        es: "Lo sentimos, no quedan suficientes unidades disponibles de este producto.",
        en: "We're sorry, there is not enough stock of this product.",
      },
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
        getProductHasEnoughStock: GET_PRODUCT_HAS_ENOUGH_STOCK,
    }),
  },
  methods: {
    minus() {
      if (this.quantity > 1) this.quantity--;
    },
    plus() {
      this.quantity++;
    },
    checkIfNumeric(e) {
      const k = e.keyCode;
      if (
        !(
          [8, 9, 35, 36, 37, 39, 46].includes(k) ||
          (k >= 48 && k <= 57) ||
          (k >= 96 && k <= 105)
        )
      ) {
        e.preventDefault();
      }
    },
    addItem() {
      if (! this.getProductHasEnoughStock(this.item.id, this.item.stock, this.quantity)) {
        return notifyKO(this.toastNotEnoughStockMessage[this.lang]);
      }
      addCartItem(
        {
          id: this.item.id,
          quantity: Number(this.quantity),
          price: this.item.price,
          name: this.item.name,
          attributes: {
            image: this.item.images[0],
          },
        },
        this.toastMessage[this.lang]
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../sass/_variables.scss";

.add-item-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: $breakpoint-landing-phone) {
    flex-direction: column;
  }

  .pack-container {
    padding: 16px 0 24px 0;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #90abaa;

    &__row {
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;
      @media (max-width: 500px) {
        flex-wrap: wrap;
      }
    }

    &__item {
      max-width: 33%;
      height: 79px;
      min-width: 120px;
      width: 100%;
      margin-right: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 2px solid #90abaa;
      border-radius: 6px;
      color: black;
      background-color: white;
      cursor: pointer;

      &:hover {
        border-color: black;
      }
      &:last-child {
        margin-right: 0;
      }

      @media (max-width: 500px) {
        margin: 0.75rem 0;
        max-width: 100%;
      }

      &.selected {
        background-color: black;
        color: white;
        border-color: black;
      }
    }
  }

  .qty-price-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;

    .qty-container {
      display: flex;
      align-items: center;

      input.plus-minus {
        font-size: 30px;
        font-weight: bold;
        background-color: transparent;
        cursor: pointer;
      }
      input.input-qty {
        width: 70px;
        height: 46px;
        margin: 0 20px;
        text-align: center;
        border: 1px solid black;
        background: transparent;
        font-size: 1.25rem;
        font-weight: bold;
      }
    }
  }

  .add-item-btn {
    width: 100%;
    max-width: 165px;

  }
}
</style>
