<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="43" height="80" viewBox="0 0 43 80">
    <g fill="#AEAEB3">
        <path d="M36.88.12h-5.46V2c0 1.04-.84 1.884-1.88 1.89H14c-.505.005-.99-.191-1.35-.546-.358-.355-.56-.84-.56-1.344V.12H6.25C3.143.126.626 2.643.62 5.75v68.32c.011 3.103 2.527 5.615 5.63 5.62h30.63c1.49 0 2.92-.592 3.974-1.646C41.908 76.99 42.5 75.56 42.5 74.07V5.75c0-3.105-2.515-5.624-5.62-5.63zm-30.63 2h3.85v.11c.152 2.038 1.856 3.61 3.9 3.6h15.54c2.036-.005 3.723-1.58 3.87-3.61v-.11h3.46c2.01 0 3.64 1.63 3.64 3.64v68.32c.005.967-.376 1.896-1.06 2.58-.684.684-1.613 1.065-2.58 1.06H6.24c-.96.002-1.882-.38-2.56-1.06-.683-.685-1.067-1.613-1.07-2.58V5.75c0-2.01 1.63-3.64 3.64-3.64v.01z"/>
        <path d="M35.51 15.64c.151.143.352.221.56.22.213.002.418-.08.57-.23.147-.15.233-.35.24-.56 0-.212-.083-.416-.23-.57L31.8 9.57c-.153-.15-.356-.235-.57-.24-.326-.008-.623.183-.753.481-.13.3-.065.647.163.879l4.87 4.95zM35.53 20.38c.313.3.807.3 1.12 0 .305-.311.305-.809 0-1.12l-9.37-9.42c-.16-.157-.376-.243-.6-.24-.194.03-.375.117-.52.25-.297.306-.297.794 0 1.1l9.37 9.43z"/>
    </g>
</svg>

</template>

<script>
export default {

}
</script>

<style>

</style>