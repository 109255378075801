<template>
    <div class="extras-component ">
        <h1>{{ trans.steps[6].title }}</h1>
        <div class="cards">
            <button :class="{ selected : isSelected(extra) }" @click="toggleExtra(extra)" v-for="extra in extras" :key="extra.id" class="card card-with-price flex flex-col items-center justify-between p-4 pb-0 relative">
                <div class="flex flex-col items-center justify-end pb-2 flex-grow">
                    <div class="icon-container flex items-center">
                        <component class="icon my-2" :is="extra.icon"></component>
                    </div>
                    <span class="name flex-grow flex items-center">{{ extra.name[locale] }}</span>
                </div>
                <span class="price mt-2 font-bold">{{ extra.price | currency }}</span>
                <span class="absolute flex justify-center items-center" :class="{ selected : isSelected(extra) }">
                    <img v-show="isSelected(extra)" src="/img/icons/check-white.svg">
                </span>
            </button>
            <template v-if="! extras.length">
                <button class="card p-0" v-for="index in 3" :key="index">
                    <Skeleton :duration="0.75" :loading="true" class="skeleton" width="175" height="209px"></Skeleton>
                </button>
            </template>
        </div>
        <div class="flex justify-center mt-8">
            <button @click="nextStep" class="next-button mdc-button mdc-button--unelevated">
                <div class="mdc-button__ripple"></div>
                <span class="mdc-button__label">{{ trans.next }}</span>
            </button>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { Skeleton } from "vue-loading-skeleton";
import icons from './icons/extras';

export default {
    components: {
        Skeleton,
        ...icons
    },
    computed: {
        ...mapGetters({
            trans: 'getTrans',
            selected: 'getSelected',
            locale: 'getLocale',
            extras: 'getExtras'
        })
    },
    methods: {
        ...mapMutations(['toggleSelected']),
        toggleExtra(extra) {
            this.toggleSelected({ prop: 'extras', data: extra });
        },
        isSelected(extra) {
            return this.selected.extras?.find(e => e.id === extra.id);
        },
        nextStep() {
            this.$router.push(this.$store.getters.getNextRoute(7)).catch(e => e);
        }
    }
};
</script>

<style scoped>
.card {
    width: 175px;
    height: 209px;
}
</style>
