<template>
  <div class="cart-resume">
    <div class="list-price">
      <span>Subtotal</span>
      <span>{{subtotal | currency}}</span>
    </div>
    <div class="list-price">
      <span>{{trans.shipping[lang]}}</span>
      <!-- <span v-if="remaining > 0">{{shipmentPrice | currency}}</span> -->
      <span>{{trans.free[lang]}}</span>
    </div>
    <div class="list-price total">
      <span>Total ({{trans.vatIncluded[lang]}})</span>
      <span>{{total | currency}}</span>
    </div>
    <btn-primary
    v-if="withButton"
      :disabled="itemsCount == 0"
      :handle-click="headToCheckout"
      class="head-to-checkout"
      :text="text[lang]"
    ></btn-primary>
    <slot></slot>
  </div>
</template>

<script>
import BtnPrimary from "../../components/common/buttons/BtnPrimary";
import mixin from "../../mixin";
import { GET_SUBTOTAL, GET_TOTAL, GET_SHIPMENT_PRICE, GET_CART_COUNT, GET_REMAINING_FREE } from "../../store/cart/types";
import { mapGetters } from "vuex";
import {trans} from '../wizardShop/trans';

export default {
  mixins: [mixin],
  props:{
    withButton: {
      type: Boolean,
      default: false
    }
  },
  components: {
    BtnPrimary,
  },
  computed: {
    ...mapGetters({
      subtotal: GET_SUBTOTAL,
      total: GET_TOTAL,
      shipmentPrice: GET_SHIPMENT_PRICE,
      itemsCount: GET_CART_COUNT,
      remaining: GET_REMAINING_FREE
    }),
  },
  data: () => {
    return {
      trans,
      text: {
        es: "Comenzar pedido",
        en: "Checkout",
      },
    };
  },
  methods: {
    headToCheckout() {
      if(this.itemsCount > 0) window.location.href= '/'+this.lang+'/checkout/personal-data'
    },
  },
};
</script>

<style lang="scss" scoped>
.cart-resume {
  padding: 1rem;
  background-color: #fff;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  font-size: 1.1rem;
  border-radius: 10px;

  .list-price {
    display: flex;
    justify-content: space-between;
    margin-top: 0.4rem;
    &:first-child {
      margin-top: 0;
    }
    &.total {
      font-weight: bold;
    }
  }

  .head-to-checkout {
    margin-top: 1rem;
    text-align: center;
    width: 100%;
  }
}
</style>
