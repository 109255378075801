<template>
    <div
        class="card flex p-6 lg:p-4 justify-around"
    >
        <div class="flex flex-col justify-around flex-separator">
            <div class="icon-container flex items-center">
                <img :src="icon" /> 
            </div>
            <div class="content flex flex-col md:h-full w-full">
                <h2 class="title leading-snug mb-2">{{ title }}</h2>
                <p class="description">{{ description }}</p>
                <span v-if="address" class="mt-3">
                    <p class="flex items-center" style="font-size: 14px;">
                        <img src="/img/icons/location_on.svg" class="mr-2" alt=""> 
                        {{addressString}}
                    </p>
                </span>
            </div>
            <div class="location-tag-container">
                <span class="location-tag">
                     {{ locationTag }}
                </span>
            </div>
        </div>
        <div class="separator"></div>
        <div class="flex flex-col justify-center price-container flex-separator">
            <div v-for="price in prices" :key="price.id" class="flex justify-between items-center service">
                <div class="flex flex-col service__item">
                    <span class="font-bold mb-2">
                        {{trans.steps[4]["time"]}}: 
                        <span v-if="locale == 'es'">{{price.time.es}}</span>
                        <span v-else>{{price.time.en}}</span>
                    </span>
                    <span class="font-bold service__item--price mt-2 text-center">
                        {{formatPrice(price.price)}}
                    </span>
                </div>
               <button @click="selectServicePrice(price)" class="next-button mdc-button mdc-button--unelevated">
                    <div class="mdc-button__ripple"></div>
                    <span class="mdc-button__label">{{ trans.select }}</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: {
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            required: true,
        },
        locationTag: {
            type: String,
            required: false,
        },
        address: {
            type: Boolean,
            required: false,
        },
        prices: {
            type: Array,
            required: false
        },
        service: {
            type: Object,
            required: true
        },
        addressString: {
            type: String,
            require: true
        }
    },

    computed: {
        ...mapGetters({
            trans: "getTrans",
            locale: "getLocale",
        }),
        freeTrans() {
            return window.appLocale === 'en' ? 'Free' : 'Gratis';
        },

    },
    methods: {
        selectServicePrice(price){
            let service_clone = {
                ...this.service,
                service_price_selected : {...price}
            }
            this.$emit('select-service-price-event', service_clone);
        },
        formatPrice(price){
            if(price == 0) return this.freeTrans;
            else return price + "€";
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../sass/_variables';

.card {
    width: 85%;
    min-height: 256px;

    /* @media (min-width: 500px) {
        &:not(.x-services-cards-card) {
            max-width: 475px;
        }
    } */

    .price-container {

        & .service {
            padding: 1rem 0rem;
            &:not(:first-child) {
                border-top: 1px solid #808087;
            }

            &__item {
            &--price {
                padding: .1rem .7rem;
                border: 1px black solid;
                border-radius: 20px;
                width: 75px;
                 @media (max-width: 380px) {
                     width: 60px;
                 }
            }

                span {
                    align-self: flex-start;
                    @media (max-width: 380px) {
                        font-size: 11px !important;
                    }
                }
            }

            .next-button {
                width: 110px !important;
                @media(max-width: 380px) {
                    width: 80px !important;
                    height: 35px;

                    span {
                       font-size: 11px !important;
                    }
                }
            }
        }

        .mdc-button {
            margin-bottom: 0 !important;
        }

    }

    .separator {
        height: 100%;
        width: 2px;
        background: #808087;
    }

    .icon-container {
        width: 80px;
        flex-shrink: 0;

        img {
            width: 80px;
        }

        @media (max-width: $lg) {
            margin-bottom: 1rem;
        }
    }

    .content {
        height: auto !important;
        align-self: center !important;
    }

    .title {
        font-size: 20px;
        font-weight: 700;
        line-height: 1.75;
        text-align: left;
    }

    .description {
        font-size: 17px;
        font-weight: bold;
        line-height: 1.52;
        color: $primary;
        text-align: left;
    }

    .border-bottom {
        border-bottom: 1px solid #ececec;
    }

    .time {
        font-size: 15px;
        font-weight: bold;
        line-height: 2;
    }

    .price-chip {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: black;
        color: white;
        font-weight: bold;
        border-radius: 18px;
        font-size: 16px;
        text-align: center;
        align-self: start;
        height: 33px;
        padding: 1rem;
    }

    &:nth-child(3) {
        .icon-container {
            img {
                width: 71px;
            }
        }
    }
    &:last-child {
        .icon-container {
            img {
                width: 65px;
            }
        }
    }

    @media(max-width: $lg) {
        & {
            flex-direction: column;
            width: 70%;

            .separator {
                display: none;
            }

            .service {
                padding-bottom: 1rem !important;
            }
        }
    }

    @media(max-width: $md) {
        & {
            width: 90%;
        }
    }

    @media(max-width: 365px) {
        .service {
            button {
                width: 110px;
            }
        }
    }

    @media (min-width: $lg) {
        .icon-container {
            width: 60px;
        }

        &:first-child {
            .icon-container {
                img {
                    width: 100px;
                }
            }
        }
        &:nth-child(2) {
            .icon-container {
                img {
                    width: 110px;
                }
            }
        }
        &:nth-child(3) {
            .icon-container {
                img {
                    width: 97px;
                }
            }
        }
        &:last-child {
            .icon-container {
                img {
                    width: 89px;
                }
            }
        }
    }

    &.x-services-cards-card {
        .title {
            font-size: 22px;
            line-height: 1.25;
        }
        .description {
            font-size: 16px;
            @media (min-width: $lg) {
                font-size: 18px;
            }
        }
    }

    .flex-separator {
        flex: .40;
    }
}
</style>
