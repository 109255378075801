<template>
<svg xmlns="http://www.w3.org/2000/svg" width="56" height="80" viewBox="0 0 56 80">
    <g fill="#AEAEB3">
        <path d="M11.89 11.92c-1 0-6.11.13-10.81 5.35l-.14.14v10.9c0 .52 4.08 6.13 10.28 6.13 1.895-.042 3.757-.507 5.45-1.36V62h1.26v7.37h.81v10.29h1.58V69.39h1.81v10.27h1.52V69.39h.91v-7.37h1.24V25.58c0-.14-.8-13.66-13.91-13.66zM23 62v5.85h-3.5V62H23zM2.6 17.93c4.24-4.52 8.74-4.45 9.27-4.43 4.89 0 8.44 2 10.56 6 1.037 1.88 1.696 3.946 1.94 6.08V60.5h-6.14V29.86c.046-.983-.341-1.937-1.06-2.61-.942-.763-2.129-1.157-3.34-1.11h-.48v1.52h.52c.819-.043 1.626.205 2.28.7.405.393.61.948.56 1.51v1.54c-7.93 4.44-13-1.76-14.13-3.28.218-.18.343-.448.34-.73v-8.78c-.012-.263-.127-.511-.32-.69z"/>
        <path d="M8.22 25.66c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM14.07 17.64l2.86 1.3c.198.088.413.132.63.13V19v.05c.618.028 1.19-.325 1.44-.89.152-.372.152-.788 0-1.16-.152-.381-.44-.691-.81-.87l-2.85-1.29c-.37-.176-.796-.194-1.18-.05-.387.143-.7.434-.87.81-.339.779.008 1.686.78 2.04zM55 5.74l-.16-.11C50.18.46 45.09.32 44.1.34 31.18.34 30.24 13.41 30.2 14v36.5h1.3v7.37h.86v21.79h1.53V57.82h1.81v21.84h1.52V57.82H38v-7.37h1.29V21.51c1.696.854 3.562 1.318 5.46 1.36C51 22.87 54.8 17.26 55 17l.09-.13V6.05L55 5.74zM36.5 50.5v5.84H33V50.5h3.5zm3.3-33.69c.662-.509 1.486-.761 2.32-.71h.53v-1.5h-.48c-1.225-.046-2.424.36-3.37 1.14-.678.67-1.041 1.597-1 2.55v30.64h-6.08V14c.189-2.152.8-4.245 1.8-6.16 2.15-4 5.7-6 10.59-6 .5 0 4.81-.06 9.06 4.24-.097.15-.152.322-.16.5v8.73c.002.342.19.656.49.82v.21c-.9 1.28-6.13 8-14.21 3.41V18.2c-.025-.513.159-1.015.51-1.39z"/>
        <path d="M47.82 14.09c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM42.9 4.2c-.202-.804-1.014-1.295-1.82-1.1H41c-.117.02-.231.053-.34.1l-2.84 1.33c-.789.343-1.154 1.257-.82 2.05.229.56.775.924 1.38.92h.12c.216-.004.43-.049.63-.13L42 6.05c.223-.098.416-.253.56-.45.346-.377.475-.906.34-1.4zM53.57 38.67v-5.49c-.13-1.033-1.03-1.794-2.07-1.75H47c-1.034-.005-1.902.78-2 1.81v5.43c-.81.342-1.348 1.122-1.38 2V55c.022 1.22 1.01 2.2 2.23 2.21h.58v4.16c.005.459.214.891.57 1.18v17.11h1.5V63h1.69v16.66h1.5V62.59c.328-.336.541-.766.61-1.23v-4.14h.49c1.215-.022 2.194-1.005 2.21-2.22V40.73c-.014-.913-.58-1.727-1.43-2.06zM53.5 55c-.03.396-.353.706-.75.72h-6.9c-.403 0-.73-.327-.73-.73v-14.3c0-.403.327-.73.73-.73h6.92c.194 0 .38.077.516.214.137.137.214.322.214.516V55zm-7-21.69c.054-.234.26-.403.5-.41h4.58c.218-.009.416.125.49.33v5.27H46.5v-5.19zm4.23 28v.19H48c-.024-.038-.044-.078-.06-.12v-4.16h2.83l-.04 4.09z"/>
        <rect width="5" height="1.54" x="46.8" y="33.63" rx=".74"/>
    </g>
</svg>
</template>

<script>
export default {

}
</script>

<style>

</style>