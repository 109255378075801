import { MDCRipple } from "@material/ripple";
import { MDCChipSet } from "@material/chips";

//Ripple
const selector = ".mdc-button";
const ripples = [].map.call(document.querySelectorAll(selector), function(el) {
    return new MDCRipple(el);
});

//Chips
const chipsSelector = ".mdc-chip-set";
const chips = [].map.call(document.querySelectorAll(chipsSelector), function(el) {
    return new MDCChipSet(el);
});
