import './header';
import './material';
require('./mdc.js');

import Vue from "vue";
import VueRouter from "vue-router";
import VCalendar from 'v-calendar';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import routes from "./routes";
import { store } from "./store";
import VueSkeletonLoader from 'skeleton-loader-vue';
window.Vue = Vue;
Vue.use(VueRouter);
const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior(to, from, savedPosition) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve({ x: 0, y: 0 })
            }, 500)
        })
    },
    routes
});
export const eventBus = new Vue();
router.afterEach((to, from) => {
    eventBus.$emit("routechanged", to);
});

Vue.component("base-wizard", require("./components/BaseWizard.vue").default);
Vue.component('damages-brands', require('./components/damagesBrands.vue').default);
Vue.component('models-brands', require('./components/modelsBrands.vue').default);
Vue.component('wizard-base-shop', require('./components/wizardShop/WizardBaseShop.vue').default);
Vue.component('stepper-wrapper', require('./components/wizardShop/steps/StepperWrapper.vue').default);
Vue.component('btn-primary', require('./components/common/buttons/BtnPrimary.vue').default);
Vue.component('address-form', require('./components/wizardShop/common/AddressForm.vue').default);
Vue.component('component-data', require('./components/wizardShop/common/ComponentData.vue').default);
Vue.component('floating-input', require('./components/common/forms/FloatingInput.vue').default);
Vue.component('floating-textarea', require('./components/common/forms/FloatingTextarea.vue').default);
Vue.component('cart-component', require('./components/cart/Cart.vue').default);
Vue.component('cart-resume', require('./components/cart/CartResume.vue').default);
Vue.component('add-item', require('./components/product/AddItem.vue').default);
Vue.component('shop-base', require('./components/shop/ShopBase.vue').default);
Vue.component('vue-skeleton-loader', VueSkeletonLoader);
Vue.component('service-card', require('./components/ServiceCard.vue').default);
Vue.component('service-wizard-card', require('./components/ServiceWizardCard.vue').default);
Vue.component('scrollable-section', require('./components/common/scrollable/ScrollableSection.vue').default);
Vue.component('product-card', require('./components/shop/ProductCard.vue').default);
Vue.component('cart-count', require('./components/cart/CartCount.vue').default);
Vue.component('faqs-toggler', require('./components/FaqsToggler.vue').default);
Vue.component('contact-map', require('./components/ContactMap.vue').default);


Vue.use(VCalendar);

Vue.filter('currency', function (value) {
    value = new Intl.NumberFormat("es-ES", { minimumFractionDigits: 2 }).format(value);
    return `${value}€`;
});

Vue.use(VueToast);
window.store = store;
window.router = router;
window.app = new Vue({ el: "#app", store, router });
