import * as cart from "./types";

const cartMutations = {
    [cart.ADD_ITEM_MUTATION](state, item) {
        //Search if id exists
        const index = state.cartItems.findIndex(
            itemList => itemList.id === item.id
        );

        if (index == -1) state.cartItems.push(item);
        else state.cartItems[index].quantity += item.quantity;
    },
    [cart.REMOVE_ITEM_MUTATION](state, id) {
        const index = state.cartItems.findIndex(item => item.id === id);
        state.cartItems.splice(index, 1);
    },
    [cart.UPDATE_ITEM_MUTATION](state, payload) {
        const index = state.cartItems.findIndex(item => item.id === payload.id);
        state.cartItems[index].quantity = payload.quantity;
    },
    [cart.INCREASE_ITEM_MUTATION](state, id) {
        const index = state.cartItems.findIndex(item => item.id === id);
        state.cartItems[index].quantity++;
    },
    [cart.DECREASE_ITEM_MUTATION](state, id) {
        const index = state.cartItems.findIndex(item => item.id === id);
        state.cartItems[index].quantity--;
    },
    [cart.RETREIVE_ITEMS_MUTATION](state, items) {
        Vue.set(state, "cartItems", items);
    },
    [cart.SET_CART_LOADING_TO_TRUE](state) {
        state.cartLoading = true;
    },
    [cart.SET_CART_LOADING_TO_FALSE](state) {
        state.cartLoading = false;
    },
    setCoupon(state, payload) {
        state.coupon = payload;
    }
};

export default cartMutations;
