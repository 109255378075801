import PersonalData from "../components/wizardShop/steps/PersonalData.vue";
import ResumePayment from "../components/wizardShop/steps/ResumePayment";
import OrderPlaced from "../components/wizardShop/steps/OrderPlaced";
import WizardBaseShop from "../components/wizardShop/WizardBaseShop";

const routes = [
    {
        path: "/:lang/checkout",
        component: WizardBaseShop,
        children: [
            {
                path: "personal-data",
                name: "personal-data",
                component: PersonalData
            },
            {
                path: "resume-and-payment",
                name: "resume-payment",
                component: ResumePayment
            },
            {
                path: "order-placed/",
                name: "order-placed",
                component: OrderPlaced
            },
            {
                path: ""
            },
            {
                path: "*"
            }
        ]
    }
];
export default routes;
