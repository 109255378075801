export const GET_ITEM_SELECTOR = 'ITEM_SELECTOR';
export const GET_CART_COUNT = 'CART_COUNT';
export const GET_CART_LOADING = 'GET_CART_LOADING';
export const ADD_ITEM_MUTATION = 'addItemToCart';
export const REMOVE_ITEM_MUTATION = 'removeItemToCart';
export const UPDATE_ITEM_MUTATION = 'updateItemCart';
export const INCREASE_ITEM_MUTATION = 'increaseItemCart';
export const DECREASE_ITEM_MUTATION = 'decreaseItemCart';
export const RETREIVE_ITEMS_MUTATION = 'retrieveItems';
export const SET_CART_LOADING_TO_TRUE = 'SET_CART_LOADING_TO_TRUE';
export const SET_CART_LOADING_TO_FALSE = 'SET_CART_LOADING_TO_FALSE';
export const GET_SUBTOTAL = 'GET_SUBTOTAL';
export const GET_TOTAL = 'GET_TOTAL';
export const GET_SHIPMENT_PRICE = 'GET_SHIPMENT_PRICE';
export const GET_SHIPMENT_TYPE = 'GET_SHIPMENT_TYPE';
export const GET_REMAINING_FREE = 'GET_REMAINING_FREE';
export const GET_PRODUCT_HAS_ENOUGH_STOCK = 'GET_PRODUCT_HAS_ENOUGH_STOCK';
