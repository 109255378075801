<template>
<div>
    <!-- filter accessories -->
    <form  v-if="category == 'accessories'" class="form-filter">
        <h6 class="mb-6 text-grey">{{trans.type_acc}}</h6>
        <div class="input-checkbox-filter mb-6" @click="selectType('all')">
            <input class="check-input" type="checkbox" id="all_items" :checked="acc_selected == 'all'" :disabled="acc_selected == 'all'">
            <label class="check-label" for="all_items">
                <span></span>{{trans.all}}
            </label>
        </div>
        <div class="input-checkbox-filter mb-6" v-for="type in typesaccessories" :key="type.id" @click="selectType(type)">
            <input class="check-input" type="checkbox" :id="type" :checked="type == acc_selected" :disabled="type == acc_selected">
            <label class="check-label" :for="type">
                <span></span>{{typeName(type)}}
            </label>
        </div>
    </form>
    <!-- filter phones  -->
    <form class="form-filter" :class="{ 'mt-6' : category == 'accessories'}">
        <h6 class="mb-6 text-grey">{{trans.brands}}</h6>
        <div class="input-checkbox-filter mb-6" @click="resetfilter">
            <input class="check-input" type="checkbox" id="all_items" :checked="allitems" :disabled="allitems">
            <label class="check-label" for="all_items">
                <span></span>{{trans.all}}
            </label>
        </div>
        <div class="input-checkbox-filter mb-6" v-for="brand in brands" :key="brand">
            <input class="check-input" type="checkbox" :value="brand" :id="brand" v-model="brandfilter">
            <label class="check-label" :for="brand">
                <span></span>{{brand}}
            </label>
        </div>
    </form>
</div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    computed:{
        ...mapGetters({
            category : 'getCategory',
            brands : 'getPhoneBrands',
            typesaccessories : 'getAccessoriesTypes',
            typefilter: 'getTypeFilter',
            trans: 'getTrans',
            allitems: 'getFilterAll',
        }),
        brandfilter: {
            get() {
                return this.$store.state.filter.brandfilter;
            },
            set(value) {
                this.$store.dispatch('selectBrandFilter', value);
            }
        }
    },
    watch:{
        $route(to, from){
            this.type_brand_selected = 'all';
            this.acc_selected = 'all';
        },
    },
    data() {
        return {

            acc_selected: 'all'
        }
    },
    methods: {
        // typebrandselect(val){
        //     this.type_brand_selected = val;
        //     this.$store.dispatch("typeBrandSelect", this.type_brand_selected);
        // },
        typeName(type) {
            return this.$store.state.accessoryTypes.find(({ slug }) => slug === type).name[window.appLocale];
        },
        selectType(val){
                this.acc_selected = val;
                this.$store.dispatch("selectTypeAcc", val);

        },
        resetfilter(){
            this.$store.dispatch("resetFilter");
        },
    },
    mounted() {
        this.$store.dispatch("resetFilter");

    },
}
</script>
<style lang="scss" scoped>
.text-grey{
  color: #9a9c96;

}
</style>
