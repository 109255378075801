<template>
<div>
    <div v-if="nofiles === 0">
        <h1>{{trans.steps[3]['nodamages']}}</h1>
    </div>
    <div class="repair-component" v-else>
        <h1>{{ trans.steps[3]['title-1'] }}</h1>
        <div class="cards">
            <button :class="{ selected : isSelectedDamage(damage) }" @click="toggleDamage(damage)" v-for="damage in damages" :key="damage.id" class="card card-with-price flex flex-col items-center justify-between px-4 pb-0 relative">
                <div class="flex flex-col items-center justify-end pb-2 flex-grow">
                    <div class="icon-container flex items-center">
                        <component class="icon my-2" :is="damage.icon"></component>
                    </div>
                    <span class="name flex-grow flex items-center">{{ damage.name[locale] }}</span>
                </div>
                <span class="price mt-2 font-bold" style="height:20px">{{ damage.pivot.price | currency }}</span>
                <span class="iva-included mb-2">{{ trans.steps[3]['iva'] }}</span>
                <span class="absolute flex justify-center items-center" :class="{ selected : isSelectedDamage(damage) }">
                    <img v-show="isSelectedDamage(damage)" src="/img/icons/check-white.svg">
                </span>
            </button>
            <template v-if="! damages.length">
                <button class="card p-0" v-for="index in 10" :key="index">
                    <Skeleton :duration="0.75" :loading="true" class="skeleton" width="175" height="209px"></Skeleton>
                </button>
            </template>
        </div>

        <div class="flex justify-center mt-10" v-if="! screensShown">
            <button @click="showScreens" :class="{ disabled: showScreensButtonDisabled }" class="next-button mdc-button mdc-button--unelevated">
                <div v-show="! showScreensButtonDisabled" class="mdc-button__ripple"></div>
                <span class="mdc-button__label">{{ trans.next }}</span>
            </button>
        </div>

        <transition name="fade">
            <div v-show="screensShown" ref="screens" class="pt-10">
                <h1>{{ trans.steps[3]['title-2'] }}</h1>
                <div class="cards">
                    <button :class="{ selected: isSelectedScreen(screen), disabled: showScreensButtonDisabled }" @click="selectScreen(screen)" v-for="screen in screens" :key="screen.color" class="screen card flex flex-col items-center justify-between pt-5 pb-3 relative">
                        <span class="color" :class="[screen.slug]"></span>
                        <span>{{ screen.name[locale] }}</span>
                    </button>
                    <button class="screen card" v-if="! screens.length" v-for="index in 3">
                        <Skeleton :duration="0.75" :loading="true" class="skeleton" width="175" height="147px"></Skeleton>
                    </button>
                </div>
            </div>
        </transition>

        <div class="flex justify-center mt-10" v-if="screensShown">
            <button @click="nextStep" :disabled="nextButtonDisabled" :class="{ disabled: nextButtonDisabled }" class="next-button mdc-button mdc-button--unelevated">
                <div class="mdc-button__ripple"></div>
                <span class="mdc-button__label">{{ trans.next }}</span>
            </button>
        </div>
    </div>
</div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { Skeleton } from "vue-loading-skeleton";
import icons from './icons/damages';

export default {
    components: {
        Skeleton,
        ...icons
    },
    data() {
        return {
            nofiles: null
        }
    },
    computed: {
        ...mapGetters({
            brands: 'getBrands',
            devices: 'getDevices',
            models: 'getModels',
            damages: 'getDamages',
            screens: 'getScreens',
            trans: 'getTrans',
            selected: 'getSelected',
            locale: 'getLocale',
            screensMustBeShown: 'getScreensMustBeShown'
        }),
        selectedDamages() {
            return this.selected.damages;
        },
        damagesWereSelected() {
            return this.selectedDamages?.length;
        },
        screensShown() {
            return this.$store.state.screensShown;
        },
        showScreensButtonDisabled() {
            return ! this.damagesWereSelected;
        },
        nextButtonDisabled() {
            return ! (this.damagesWereSelected && this.selected.screen);
        },
    },
    methods: {
        ...mapMutations(['setSelected', 'toggleSelected', 'setState']),
        toggleDamage(damage) {
            this.toggleSelected({ prop: 'damages', data: damage });
        },
        selectScreen(screen) {
            this.setSelected({ prop: 'screen', data: screen });
        },
        isSelectedDamage(damage) {
            return this.selected.damages?.find(d => d.id === damage.id);
        },
        isSelectedScreen(screen) {
            return this.selected.screen?.id === screen.id;
        },
        showScreens() {
            if (! this.damagesWereSelected) return;

            if (this.screensMustBeShown) {
                this.$store.commit('setScreensShown', true);
                this.$nextTick(() => {
                    this.$refs.screens.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start'
                    });
                });
            } else {
                this.nextStep();
            }
        },
        nextStep() {
            this.$router.push(this.$store.getters.getNextRoute(4)).catch(e => e);
        },
        checkDamagesEmpty() {
            if(this.damages && this.damages.length && this.damages.length > 0){
                this.nofiles = 1;
            } else {
                this.nofiles = 0;
            }
        }
    },
    watch: {
        selectedDamages() {
            if (! this.screensMustBeShown) {
                this.$store.commit('setScreensShown', false);
                this.$store.commit('setSelected', { prop: 'screen', data: null });
            }
        }
    },
    async created() {
        if (! this.$store.state.damages.length && ! this.$store.state.loading) {
            const { query, params } = this.$router.currentRoute;
            const { d: deviceId, m: modelId } = query;
            const { brand: brandSlug, model: modelSlug } = params;
            this.setState({ prop: 'stepSelected', data: 3 });
            this.setState({ prop: 'maxIndex', data: 3 });
            this.$store.dispatch('retrieveDamages', modelId),
            this.$store.dispatch('retrieveScreens');
            await Promise.all([
                this.$store.dispatch('retrieveModels', deviceId),
                this.$store.dispatch('retrieveBrand', brandSlug)
            ]);
            const brand = this.brands.find(b => b.slug === brandSlug);
            this.setSelected({ prop: 'brand', data: brand });
            const device = this.devices.find(d => d.id == deviceId);
            this.setSelected({ prop: 'device', data: device });
            const model = this.models.find(m => m.slug === modelSlug);
            this.setSelected({ prop: 'model', data: model });
            this.checkDamagesEmpty();
        }
    }
};
</script>

<style scoped lang="scss">
.card {
     width: 150px;
    height: 210px;
    @media (min-width: 640px) {
        width: 173px;
        height: 209px;
    }

    &.screen {
        height: 147px;

        .color {
            width: 71px;
            height: 71px;
            border-radius: 50%;

            &.black {
                background: black;
            }
            &.white {
                background: white;
                border: 1px solid #979797;
            }
            &.other {
                background: #b9b9b9;
            }
        }
    }
    .iva-included{
        color: grey;
        font-size: 12px;
    }
}
.front-camera-icon {
    .cls-2 {
        clip-path: url(#clip-path);
    }
}
.wifi-icon {
    .cls-1 {
        fill-rule: evenodd;
    }
}
</style>
