<template>
  <div class="resume font-bold">
    <h3 class="title-resume">{{ trans.steps[7].resume }}</h3>
    <table class="table-fixed">
      <thead>
        <tr>
          <th class="w-1/3 md:w-1/4"></th>
          <th class="w-2/3 md:w-3/4"></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="title px-2 py-2">{{ trans.steps[0].step }}:</td>
          <td class="px-2 py-2 font-normal text-sm">{{ selected.brand.name }}</td>
        </tr>
        <tr>
          <td class="title px-2 py-2">{{ trans.steps[7].device }}:</td>
          <td class="px-2 py-2 font-normal text-sm">
            {{ trans.steps[1].devices[selected.device.slug] }}
          </td>
        </tr>
        <tr class="bg-gray-100">
          <td class="title px-2 py-2">{{ trans.steps[7].model }}:</td>
          <td class="px-2 py-2 font-normal text-sm">{{ selected.model.name }}</td>
        </tr>
        <tr>
          <td class="title px-2 py-2">{{ trans.steps[7].repair }}:</td>
          <td class="px-2 py-2 font-normal text-sm">
            <p
              class="flex flex-col md:flex-row md:justify-between"
              v-for="damage in damages"
              :key="damage.id"
            >
              <span class="pr-0 md:pr-2 text-sm">{{ damage.name[locale] }}</span>
              <span class="font-bold">{{ damage.pivot.price | currency }}</span>
            </p>
          </td>
        </tr>
        <tr>
          <td class="title px-2 py-2">{{ trans.steps[7].service }}:</td>
          <td class="px-2 py-2 font-normal text-sm">
            <p class="flex flex-col md:flex-row md:justify-between">
              <span class="pr-0 md:pr-2 text-sm">{{
                selected.service.name[locale]
              }}</span>
              <span class="font-bold" v-if="selected.service.service_price_selected.price == 0">{{
                trans.steps[4].free
              }}</span>
              <span class="font-bold" v-else>{{
                selected.service.service_price_selected.price | currency
              }}</span>
            </p>
          </td>
        </tr>
        <!-- <tr>
          <td class="title px-2 py-2">Extras:</td>
          <td class="px-2 py-2 font-normal text-sm">
            <p
              class="flex flex-col md:flex-row md:justify-between"
              v-for="extra in selected.extras"
              :key="extra.id"
            >
              <span class="pr-0 md:pr-2">{{ extra.name[locale] }}</span>
              <span>{{ extra.price | currency }}</span>
            </p>
          </td>
        </tr> -->
        <tr>
          <td class="title px-2 py-2">{{ trans.steps[7].calendar }}:</td>
          <td class="px-2 py-2 font-normal text-sm">{{ selectDate }} - {{ selected.time }}</td>
          <td class="price px-2 py-2"></td>
        </tr>
        <tr v-if="validDiscountCode && isApplicable">
          <td class="title px-2 py-2">{{ trans.steps[7].voucher }}:</td>
          <td class="price px-2 py-2">-{{ priceToDiscount | currency }}</td>
        </tr>
        <slot></slot>
        <tr>
          <td class="title px-2 py-2">TOTAL:</td>
          <td class="price px-2 py-2">
            {{ price | currency }}<br />{{ trans.steps[3].iva }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ['selected', 'damages', "trans", "locale","selectDate"],
  computed: {
    ...mapGetters({
      priceToDiscount: "getPriceToDiscount",
      validDiscountCode: "getValidDiscountCode",
      isApplicable: "getDiscountCodeIsApplicable",
      price: "getTotalPrice"
    }),
  },
  methods: {
    getDiscount(){
      var payment = this.selected.paymentMethod;
      if(payment == 'cash') {
          return 'Contrareembolso (+5€)';
      } else if (payment == 'delivery') {
        return 'Con entrega';
      } else {
        return 'Online';
      }
    }
  }
};
</script>

<style>

.font-bold {
  font-weight: bold;
}



tbody tr:last-child {
    border-top: 1px solid #3c3cfa !important;
}

</style>