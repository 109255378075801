<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="43" height="80" viewBox="0 0 43 80">
    <g fill="#AEAEB3">
        <path d="M36.27.38h-30c-3.314 0-6 2.686-6 6v67.29c0 3.314 2.686 6 6 6h30c3.29-.033 5.94-2.71 5.94-6V6.33C42.183 3.06 39.54.413 36.27.38zm4 73.29c0 2.21-1.79 4-4 4h-30c-2.21 0-4-1.79-4-4V6.33c0-2.21 1.79-4 4-4h30c2.21 0 4 1.79 4 4v67.34z"/>
        <path d="M14 5.48H8.47c-.92-.003-1.802.36-2.453 1.01C5.366 7.139 5 8.02 5 8.94v5.47c0 1.909 1.541 3.459 3.45 3.47H14c1.91-.011 3.455-1.56 3.46-3.47V8.94c-.005-1.909-1.551-3.455-3.46-3.46zm1.52 8.93c-.005.839-.681 1.52-1.52 1.53H8.47C7.649 15.908 7 15.232 7 14.41V8.94c-.003-.402.155-.789.439-1.074.283-.286.669-.446 1.071-.446H14c.84 0 1.52.68 1.52 1.52v5.47z"/>
    </g>
</svg>

</template>

<script>
export default {

}
</script>

<style>

</style>