<template>
    <button @click="handleClick" class="btn btn-primary has-spinner" :class="{disabled : disabled}">
        <span  v-show="loading" class="spinner"></span>
        <span v-show="!loading">{{text}}</span>
    </button>
</template>
<script>
export default {
  props: {
    handleClick: {
      type: Function,
    },
    text: {
      type: String,
      default: "button",
    },
    disabled: {
        type: Boolean,
        default: false
    },
    loading: {
        type: Boolean,
        default: false
    }
  },
};
</script>
<style lang="scss">
</style>
