<template>
    <div class="field-wrapper mt-6" @click="$refs.textarea.focus()" :class="{ hasValue: value, hasErrors: errors.length }">
        <textarea :name="name"
            ref="textarea"
            v-model="value"
            @focus="errors = []"
            @input="$emit('input', $event.target.value)">
        </textarea>
        <div class="field-placeholder"><span>{{ placeholder }}</span></div>
    </div>
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
            required: true,
        },
        initialValue: {
            required: false,
            default: '',
        },
        initialErrors: {
            type: Array,
            required: false,
            default: () => [],
        },
    },

    data() {
        return {
            value: this.initialValue,
            errors: this.initialErrors,
        };
    },
}
</script>

<style lang="scss" scoped>
@import '../../../../sass/_variables';

.field-wrapper {
    position: relative;
    height: 15rem;
    cursor: text;
    &:hover {
        border: 1px solid $primary;
        &.hasErrors {
            border: 1px solid #cc0000;
        }
    }
}

.field-wrapper textarea {
    border: 1px solid #bfbfbf;
    padding: 15px;
    // border-radius: 4px;
    width: 100%;
    height: 100%;
    background-color: transparent;
    color: #030218;
    outline: none;
}

.field-wrapper textarea:focus {
    border:1px solid $primary;
}

.field-wrapper.hasErrors textarea {
    border: 1px solid #cc0000;
}

.field-wrapper .field-placeholder {
    font-size: 16px;
    position: absolute;
    top: 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #80868b;
    left: 8px;
    padding: 0 8px;
    -webkit-transition: transform 150ms cubic-bezier(0.4,0,0.2,1),opacity 150ms cubic-bezier(0.4,0,0.2,1);
    transition: transform 150ms cubic-bezier(0.4,0,0.2,1),opacity 150ms cubic-bezier(0.4,0,0.2,1);
    z-index: 1;
    text-align: left;
    width: 100%;
}

.field-wrapper .field-placeholder span {
    background: #f5f6f8;
    padding: 0px 8px;
    color: #999;
    font-weight: normal;
}

.field-wrapper textarea:not([disabled]):focus~.field-placeholder {
    color:#030218;
    span {
        color: #030218;
    }
}

.field-wrapper textarea:not([disabled]):focus~.field-placeholder,
.field-wrapper.hasValue textarea:not([disabled])~.field-placeholder {
    -webkit-transform: scale(.75) translateY(-29px) translateX(-16%);
    transform: scale(.75) translateY(-29px) translateX(-16%);
}
</style>
