export const trans = {
    name: {
        en: "Full Name",
        es: "Nombre y apellidos"
    },
    address: {
        en: "Address",
        es: "Dirección"
    },
    floor: {
        en: "Floor",
        es: "Piso y puerta"
    },
    info: {
        en: "More info",
        es: "Más información"
    },
    postal: {
        en: "Postal code",
        es: "Código postal"
    },
    city: {
        en: "City",
        es: "Población"
    },
    country: {
        en: "Country",
        es: "País"
    },
    email: {
        en: "Email",
        es: "Email"
    },
    phone: {
        en: "Phone",
        es: "Teléfono"
    },
    addressTitle: {
        en: "Shipping Address",
        es: "Dirección de envío"
    },
    contactTitle: {
        en: "Contact Data",
        es: "Datos de contacto"
    },
    shipmentTitle: {
        en: "Shipping Information",
        es: "Método de envío"
    },
    delivery: {
        gls_1_3_days: {
            en: "Delivery between 1-3 business days",
            es: "Entrega entre 1-3 dias laborables"
        },
        gls_3_days: {
            en: "Delivery in 3 business days",
            es: "Entrega en 3 dias laborables"
        },
        gls_2_3_days: {
            en: "Delivery between 2-3 business days",
            es: "Entrega entre 2-3 dias laborables"
        },
        gls_2_4_days: {
            en: "Delivery between 2-4 business days",
            es: "Entrega entre 2-4 dias laborables"
        },
        gls_3_6_days: {
            en: "Delivery between 3-6 business days",
            es: "Entrega entre 3-6 dias laborables"
        },
        gls_4_6_days: {
            en: "Delivery between 4-6 business days",
            es: "Entrega entre 4-6 dias laborables"
        },
        certified: {
            en: 'Certified Postal Serivce',
            es: 'Servicio postal certificado'
        }
    },
    btnText: {
        en: "Next",
        es: "Siguiente"
    },
    resumeTitle: {
        en: "Order Summary",
        es: "Resumen del pedido"
    },
    promotiontitle: {
        en: "Promotional code",
        es: "Código de promoción"
    },
    paymentMethod: {
        en: "Payment Method",
        es: "Forma de pago"
    },
    paymentMethods: {
        en: "Payment methods",
        es: "Formas de pago"
    },
    end: {
        en: "Proceed to checkout",
        es: "Finalizar pedido"
    },
    congrats: {
        en: "Congratulations, your order has been placed!",
        es: "¡Felicidades, tu pedido ha sido realizado!"
    },
    orderNumber: {
        en: "Order Number",
        es: "Número de pedido"
    },
    expectedDeliveryDate: {
        en: "Expected Delivery Date",
        es: "Fecha de entrega estimada"
    },
    standardShippingName: {
        en: "Standard",
        es: "Estándar"
    },
    standardShippingDesc: {
        en: "Delivery in 3-4 days<br /> (festive days not included)",
        es: "Entrega entre 3-4 dias<br /> (días festivos no incluidos)"
    },
    shipping: {
        en: "Shipping",
        es: "Envío"
    },
    contactData: {
        en: "Contact Data",
        es: "Datos de contacto"
    },
    article: {
        en: "Article",
        es: "Artículo"
    },
    units: {
        en: "Units",
        es: "Unidades"
    },
    price: {
        en: "Price",
        es: "Precio"
    },
    priceFrom: {
        en: "From",
        es: "Desde"
    },
    subtotal: {
        en: "Subtotal",
        es: "Subtotal"
    },
    free: {
        en: "Free",
        es: "Gratis"
    },
    shippingDescription1: {
        en: "24/48h delivery.",
        es: "Entrega en 24/48h."
    },
    shippingDescription2: {
        en: "(Festive days not included).",
        es: "(No están previstos los días festivos).",
    },
    shippingPrice: {
        en: "Shipping Price",
        es: "Precio envío"
    },
    totalIvaIncluded: {
        en: "Total (IVA included)",
        es: "Total (IVA incluido)"
    },
    totalPrice: {
        en: 'Total price',
        es: 'Precio total'
    },
    paymentcard_title: {
        en: "Card Payment",
        es: "Pago con tarjeta"
    },
    paymentcard_description: {
        en:
            "Make your payment with a credit or debit card.  We ensure that your data is protected and well stored.",
        es:
            "Realiza tu pago con tarjeta de crédito o débito. Aseguramos que tus datos están protegidos y bien guardados."
    },
    bitcoin_title: {
        en: "Payment with Bitcoin",
        es: "Pago con Bitcoin"
    },
    bitcoin_description: {
        en:
            "You can now make your payment with Bitcoins. A safe way to carry out your transaction.",
        es:
            "Ya puedes realizar tu pago con Bitcoins. Una forma segura de realizar tu transacción."
    },
    card_number: {
        en: "Card number",
        es: "Número tarjeta"
    },
    expiry: {
        en: "Expiry Date",
        es: "Validez"
    },
    payment_error: {
        en: "Error, payment can not be completed",
        es: "Error, no se ha podido realizar el pago"
    },
    free_shipping: {
        p1: {
            en: "",
            es: "Faltan"
        },
        p2: {
            en: "remaining for free shipping",
            es: "para que te salga gratis"
        }
    },
    not_found: {
        en: "Country not avaliable",
        es: "País no disponible"
    },
    country: {
        en: 'Country',
        es: 'País'
    },
    choosePack: {
        en: 'Choose the pack',
        es: 'Escoge el pack'
    },
    creditCard:{
        en: 'Credit card',
        es: 'Tarjeta de crédito'
    },
    bitcoin: {
        en: 'Bitcoint payment',
        es: 'Pago con bitcoin'
    },
    vatIncluded: {
        en: 'VAT included',
        es: 'IVA incluido'
    },
    acceptedCards: {
        en: 'Accepted cards',
        es: 'Tarjetas aceptadas',
    },
    leaving: {
        en: 'Are you sure you want to leave? Some changes could be lost.',
        es: '¿Seguro que quieres salir? Algunos cambios podrían no guardarse.',
    }
};
