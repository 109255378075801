<template>
   <aside class="filter-drawer" id="filter-drawer">

        <div class="back-button" @click="backDrawer()">
            <div class="back-button-container">
                <img src="/img/icons/arrow-red.svg" alt="">
                <p class="font-bold">{{trans.filter}}</p>
            </div>
        </div>
        <div class="filter-drawer__content pt-12">
            <filter-component v-if="open"></filter-component>
        </div>

        <button @click="closeDrawer()" class="btn btn-primary apply-button z-10">{{ trans['apply-filters'] }}</button>

    </aside>
</template>
<script>
import FilterComponent from './FilterComponent';
import { mapGetters } from 'vuex';

export default {
    props:['open'],
    computed:{
        ...mapGetters({
            trans: 'getTrans',
        }),
    },
    components:{
        FilterComponent
    },
    methods: {
        closeDrawer(){
            document.getElementById("filter-drawer").style.width = "0";
        },
        backDrawer(){
            document.getElementById("filter-drawer").style.width = "0";
        },
    },
}
</script>
<style lang="scss" scoped>
#filter-drawer{
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    background-color: white;
    overflow-x: hidden;
    transition: 0.3s ;

    .back-button{
        height: 100px;
        background-color: #f5f6f8;
        box-shadow: 0 -2px 14px 0 rgba(0, 0, 0, 0.13);
        position: relative;
        transition: 0.3s;
        &-container{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 3.5rem 1rem 0 1rem;
            img{
                position: absolute;
                left: 25px;
            }
        }
    }
    .filter-drawer__content{
        height: 75vh;
        position: relative;
        width: 100%;
        transition: 0.3s ease-out;
        overflow-y: scroll;
    }
        .apply-button{
            position: absolute;
            bottom: 15px;
            left: 50%;
            -webkit-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            transform: translateX(-50%);
        }
}
</style>
