<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
    <path fill="#AEAEB3" d="M62.49 42.12h-.11V13.79c-.196-7.075-5.987-12.709-13.065-12.709-7.078 0-12.87 5.634-13.065 12.709v42.42c0 6.075-4.925 11-11 11s-11-4.925-11-11V38.28h.11c6.366-.47 11.3-5.757 11.33-12.14V12.07c0-.552-.448-1-1-1H23.4V4C23.303 2.24 21.848.862 20.085.862S16.867 2.24 16.77 4v7H9.63V4C9.533 2.24 8.078.862 6.315.862S3.097 2.24 3 4v7H1.73c-.552 0-1 .448-1 1v13.51c.003 6.498 4.874 11.964 11.33 12.71h.11v18c-.132 4.754 2.33 9.204 6.426 11.62 4.096 2.415 9.182 2.415 13.278 0 4.097-2.416 6.558-6.866 6.426-11.62V13.79c0-6.075 4.925-11 11-11s11 4.925 11 11v28.32h-.1c-3.884.566-6.766 3.895-6.77 7.82v9.17c0 .552.448 1 1 1h.13v8.12c0 .552.448 1 1 1h11.53c.552 0 1-.448 1-1v-8.08h.12c.552 0 1-.448 1-1v-9.2c0-3.91-2.855-7.232-6.72-7.82zm-2.41 20.27v-2.28h2.55v2.28h2v-2.28h1.45v7.13h-9.44v-7.13H58v2.28h2.08zm7.13-12.48V58H55.5v-8.09c-.089-2.15 1.007-4.175 2.855-5.276 1.849-1.101 4.151-1.101 6 0 1.848 1.101 2.944 3.126 2.855 5.276zM6.32 2.73c.703 0 1.275.567 1.28 1.27v7H5V4c.003-.345.144-.674.393-.913.248-.24.582-.368.927-.357zm12.86.37c.505-.477 1.295-.477 1.8 0 .239.238.372.563.37.9v7h-2.54V4c-.007-.338.127-.664.37-.9zm-16.43 16v-6h20.9v6H2.75zm20.9 2v5c.005 2.691-1.06 5.274-2.96 7.18-1.9 1.905-4.479 2.977-7.17 2.98-5.933-.016-10.743-4.817-10.77-10.75v-4.39l20.9-.02z"/>
</svg>

</template>

<script>
export default {

}
</script>

<style>

</style>