<template>
  <div class="services-component">
    <h1>{{ trans.steps[4]["need-postal-code"] }}</h1>
    <input
      v-model="postalCode"
      class="
        outline-none
        rounded-lg
        appearance-none
        block
        w-full
        py-3
        px-4
        my-3
        mx-auto
        text-center
      "
      type="text"
      :placeholder="trans.steps[4]['enter-postal-code']"
      @keydown="setPostalCode"
    />
    <transition name="fade">
      <div v-if="postalCodeIsValid">
        <p class="pt-2 text-center" v-if="!postalCodeServices.length">
          {{ trans.steps[4]["check-postal-code"] }}
        </p>
        <div v-else>
          <h1 class="mt-12 md:mt-16">{{ trans.steps[4]["choose-service"] }}</h1>
          <div
            class="cards"
            :class="{
              'single-column': postalCodeServices.length === 1,
              en: locale === 'en',
            }"
          >
            <service-wizard-card
              class="service-card"
              @select-service-price-event="selectService"
              v-for="service in postalCodeServices"
              :key="service.id"
              :title="service.name[locale]"
              :description="service.description[locale]"
              :icon="`/storage/${service.icon}`"
              :address="!service.ask_for_address"
              :prices="service.prices"
              :service="service"
              :address-string="addressString"
            >
            </service-wizard-card>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  data() {
    return {
      invalid: true,
    };
  },

  computed: {
    ...mapGetters({
      trans: "getTrans",
      locale: "getLocale",
      services: "getServices",
      selected: "getSelected",
    }),
    postalCode: {
      get() {
        return this.$store.state.selected.postalCode;
      },
      set(data) {
        this.setSelected({ prop: "postalCode", data });
      },
    },
    addressString(){
      switch (this.postalCode.slice(0,2)) {
        case '07':
          return 'Carrer de Cazador, 6, 07002 Palma, Illes Balears'
        default:
           return 'Carrer de la Marina, 145, 08013, Barcelona'
      }
    },
    postalCodeIsValid() {
      return this.postalCode && this.postalCode.length === 5;
    },
    postalCodeServices() {
      return this.services.filter(({ postalCodes }) =>
        postalCodes.includes(this.postalCode)
      );
    },
    selectedService() {
      return this.selected.service;
    },
  },

  watch: {
    selectedService(selectedService) {
      this.setSelected({ prop: "date", data: null });
      this.setSelected({ prop: "time", data: null });
      // if (!selectedService.show_full_calendar) {
      //   this.setSelected({ prop: "paymentMethod", data: "online" });
      // }
    },
  },

  methods: {
    ...mapMutations({
      setSelected: "setSelected",
    }),
    setPostalCode(e) {
      const k = e.keyCode;
      if (
        !(
          [8, 9, 35, 36, 37, 39, 46].includes(k) ||
          (k >= 48 && k <= 57) ||
          (k >= 96 && k <= 105)
        ) &&
        this.postalCode.length !== 5
      ) {
        e.preventDefault();
      }
    },
    serviceIsSelected(service) {
      return this.selected.service?.id === service.id;
    },
    selectService(service) {
      this.setSelected({ prop: "service", data: service });
      this.$nextTick(() =>
        this.$router.push(this.$store.getters.getNextRoute(5)).catch((e) => e)
      );
    },
  },

  async created() {
    if (!this.services.length) {
      this.$store.dispatch("retrieveServices");
    }
  },
};
</script>

<style scoped lang="scss">
@import "../../../sass/_variables";

input {
  max-width: 230px;
}
.cards {
  column-gap: 30px;
  row-gap: 30px;
  grid-template-columns: 100%;
  max-width: 1000px;
  justify-items: center;

  @media (min-width: $md) {
    &:not(.single-column) {
      grid-template-columns: 50% 50%;
    }
  }
  @media (min-width: $xl) {
    column-gap: 20px;
    row-gap: 40px;
  }

  .service-card {
    &:not(:focus):hover,
    &.selected {
      transform: none !important;
    }
  }
}
</style>
