<template>
    <button @click="handleClick" class="btn btn-outline-primary" :class="{disabled : disabled}">
        <span  v-if="loading">loading</span>
        <span v-else>{{text}}</span>
    </button>
</template>
<script>
export default {
  props: {
    handleClick: {
      type: Function,
    },
    text: {
      type: String,
      default: "button",
    },
    disabled: {
        type: Boolean,
        default: false
    },
    loading: {
        type: Boolean,
        default: false
    }
  },
};
</script>
<style lang="scss">
</style>
