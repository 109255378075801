import { MDCTopAppBar } from "@material/top-app-bar";
import { MDCDrawer } from "@material/drawer";
const bodyScrollLock = require('body-scroll-lock');
window.bodyScrollLock = bodyScrollLock;

const drawer = MDCDrawer.attachTo(document.querySelector('#menu-drawer'));
window.drawer = drawer;
const topAppBarElement = document.querySelector('.mdc-top-app-bar');
const topAppBar = MDCTopAppBar.attachTo(topAppBarElement);
window.topAppBar = topAppBar;

new MDCTopAppBar(topAppBarElement);

topAppBar.setScrollTarget(document.getElementById('main-content'));

document.querySelector('#drawer-close-button').addEventListener('click', () => {
    drawer.open = false;
    bodyScrollLock.enableBodyScroll(document.body);
});
