<template>
    <span>steps</span>
</template>

<script>
export default {
    methods: {
        go(routeName) {
            this.$router.push({ name: routeName });
        }
    },
}
</script>
