<template>
  <div class="order-placed-component wizard-content">
    <h1 class="title-done mx-auto text-center">
      {{ trans.congrats[lang] }}
    </h1>
    <div class="done-section flex flex-col space-y-5 lg:flex-row lg:space-y-0">
      <div class="section-item">
        <p>{{ trans.orderNumber[lang] }}</p>
        <p>#{{ order.id }}</p>
      </div>
      <div class="section-item">
        <p>{{ trans.expectedDeliveryDate[lang] }}</p>
        <p>{{ orderShippingDate }}</p>
      </div>
      <div class="section-item">
        <p>{{ trans.shipping[lang] }}</p>
        <p>{{ order.shipping_type.charAt(0).toUpperCase() + order.shipping_type.slice(1) }}</p>
      </div>
    </div>

    <div class="done-section flex flex-col space-y-5 lg:flex-row lg:space-y-0">
      <div class="section-item">
        <p>{{ trans.contactData[lang] }}:</p>
        <div>
          <p>{{ order.name }}</p>
          <p>{{ order.email }}</p>
          <p>{{ order.phone }}</p>
        </div>
      </div>
      <div class="section-item">
        <p>{{ trans.addressTitle[lang] }}</p>
        <div>
          <p>{{order.name}}</p>
          <p>{{order.address}}, {{order.postal}}, {{order.city}}</p>
        </div>
      </div>
    </div>

    <div class="pay-section border-title">
      <div class="pay-section-item">
        <p>{{trans.creditCard[lang]}}:</p>
      </div>
      <div class="pay-image-done">
        <p>{{order.card.brand}}</p>
        <p>{{trans.card_number[lang]}}: **** **** **** {{order.card.last4}}</p>
        <p>{{trans.expiry[lang]}}: {{order.card.valid}}</p>
      </div>
    </div>

    <div class="pay-section">
      <div class="grid-products">
        <p class="font-bold">{{ trans.article[lang] }}</p>
        <p class="font-bold text-center">{{ trans.units[lang] }}</p>
        <p class="font-bold text-right">{{ trans.price[lang] }}</p>
        <template v-for="product in order.products">
          <div class="image-done section-product" :key="`image-done${product.id}`">
            <img
              class="hidden md:inline mr-5"
              :src="product.images[0] || '/img/no-image-placeholder.png'"
              :alt="product.name[lang]"
            />
            <p>{{ product.name[lang] }}</p>
          </div>
          <div class="section-product text-center" :key="`section-product-1-${product.id}`">
            <p>x{{ product.pivot.quantity }}</p>
          </div>
          <div class="section-product ml-auto" :key="`section-product-2-${product.id}`">
            <p class="text-right">
              {{ product.price | currency }}
            </p>
          </div>
        </template>
      </div>
    </div>

    <div class="resume rounded p-4 md:p-8 mt-8">
      <div class="flex justify-between mb-2">
        <span>{{ trans.subtotal[lang] }}</span>
        <span>{{ order.subtotal | currency }}</span>
      </div>
      <div class="flex justify-between mb-2">
        <span>{{trans.shippingPrice[lang]}}</span>
        <span>{{trans.free[lang]}}</span>
      </div>
      <div class="flex justify-between">
        <span class="font-bold">{{ trans.totalIvaIncluded[lang] }}</span>
        <span class="font-bold">
          {{ order.total | currency }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  GET_ADDRESS_DATA,
  GET_ORDER,
  GET_PERSONAL_DATA,
  RETREIVE_ORDER_ACTION,
} from "../../../store/wizardShop/types";
import { trans } from "../trans";
import mixin from "../../../mixin";

export default {
  mixins: [mixin],

  data() {
    return {
      trans,
    };
  },

  computed: {
    ...mapGetters({
      order: GET_ORDER,
    }),
    orderShippingDate() {
        const date = new Date(this.order.shipping_date);
        return `${date.getDate()}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
    }
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.order-placed-component {
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  width: 100%;
  padding-bottom: 1rem;

  p {
    margin-bottom: 0.75rem;
  }
  .title-done {
    font-size: 25px;
    margin-bottom: 50px;
  }
  .done-section,
  .pay-section,
  .resume {
    align-self: stretch;
  }
  .pay-section {
    display: flex;
    flex-direction: column;
    padding: 30px 0px;
    &-item {
      display: flex;
      flex-direction: row;
      p {
        width: 33%;
        font-weight: bold;
        @media (max-width: 1023px) {
          width: 100%;
        }
      }
    }
    .pay-image-done {
      display: flex;
      flex-direction: row;
      @media (max-width: 1023px) {
        flex-direction: column;
      }
      img {
        width: 50px;
      }
      p {
        margin: 0px;
        &:not(:first-child) {
          padding-left: 20px;
        }
      }
    }
    .image-done {
      display: flex;
      flex-direction: row;
      align-items: center;

      img {
        width: 50px;
        height: 50px;
        object-fit: contain;
      }
      p {
        margin: 0px;
      }
    }
    .section-product-item {
      display: flex;
      flex-direction: row;

      .section-product {
        width: 33%;
        align-self: center;
        @media (max-width: 1023px) {
          img {
            width: 30px;
          }
        }
        p {
          margin: 0px;
        }
      }
    }
  }
  .border-product {
    border-bottom: 0.5px solid rgba(61, 61, 61, 0.5);
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
  }
  .done-section {
    border-bottom: 1px solid black;
    padding: 30px 0px;

    &:first-of-type {
      @media (max-width: 1023px) {
        padding-top: 1rem;
      }
    }
    .section-item {
      width: 33%;
      display: flex;
      flex-direction: column;
      padding-right: 20px;
      @media (max-width: 1023px) {
        width: 100%;
      }
      > p:first-child {
        font-weight: bold;
      }
      > div p {
        margin-bottom: 0.5rem;
      }
    }
  }
  .resume {
    background: #3c3cfa;
    color: white;
  }
  .grid-products {
    display: grid;
    grid-template-columns: 40% 5rem 1fr;
    column-gap: 1rem;
    row-gap: 0.5rem;
    align-items: center;
    @media (min-width: 640px) {
      grid-template-columns: 33% 5rem 1fr;
    }
    > div p {
      margin-bottom: 0;
    }
  }
}
</style>
