import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

export const wizardShopState = {
    addressData: {
        name: {
            name: "name",
            value: "",
            errors: []
        },
        address: {
            name: "address",
            value: "",
            errors: []
        },
        floor: {
            name: "floor",
            value: "",
            errors: [],
        },
        city: {
            name: "city",
            value: "",
            errors: []
        },
        postal: {
            name: "postal",
            value: "",
            errors: []
        },
        country: {
            name: "country",
            value: "",
            errors: []
        },
    },
    shopExtraInfo: "",
    shippingType: {
        cost: 5.5,
        method: "gls_1_3_days",
        free_shipping_from: 60
    },
    personalData: {
        email: {
            name: "email",
            value: "",
            errors: [],
            type: "email"
        },
        phone: {
            name: "phone",
            value: "",
            errors: [],
            type: "tel"
        }
    },
    shippingData: "standard",
    order: {
        id: 0,
        code: "123",
        shipping_type: "standard",
        subtotal: 20,
        total: 25,
        shipping_price: 0,
        products: [
            {
                images: [],
                pivot: {
                    product_name: {
                        en: "Griffin Dietrich",
                        es: "Nat Leuschke"
                    },
                    quantity: 2,
                    price: 23.23
                }
            },
            {
                images: [],
                pivot: {
                    product_name: {
                        en: "Griffin Dietrich",
                        es: "Nat Leuschke"
                    },
                    quantity: 2,
                    price: 23.23
                }
            }
        ]
    },
    orderLoading: false,
    orderSaved: false,
};

export const wizardShopGetters = {
    ...getters
};

export const wizardShopMutations = {
    ...mutations
};
export const wizardShopActions = {
    ...actions
};
