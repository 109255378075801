import {SET_ORDER_LOADING_TO_FALSE_MUTATION} from '../../store/wizardShop/types';
import {retrieveCartItems} from "../../app/cart.js";

function getMeta(metaName) {
  const metas = document.getElementsByTagName('meta');

  for (let i = 0; i < metas.length; i++) {
    if (metas[i].getAttribute('name') === metaName) {
      return metas[i].getAttribute('content');
    }
  }

  return '';
}

const csrfToken = getMeta('csrf-token');

export const stripePaymentMethodHandlerCheckout = async (stripe, result, wizardCheckout) => {
    if (result.error) {
        // Show error in payment form
        Vue.$toast.error(result.error.message, { position: "bottom" });
    } else {
        // Otherwise send paymentMethod.id to your server
        const res = await fetch("/api/checkout", {
            method: "POST",
            headers: { "Content-Type": "application/json", 'X-CSRF-TOKEN': csrfToken },
            body: JSON.stringify({
                payment_method_id: result.paymentMethod.id,
                payload: wizardCheckout,
                locale: window.appLocale,
            })
        });
        const paymentResponse = await res.json();

        handleServerResponseCheckout(stripe, paymentResponse, wizardCheckout);
    }
};

export const handleServerResponseCheckout = async (stripe, response, wizardCheckout) => {
    retrieveCartItems();
    if (response.err) {
        store.commit(SET_ORDER_LOADING_TO_FALSE_MUTATION)
        store.commit("setCardMessage", response.err);
        Vue.$toast.error(response.err, { position: "bottom" });
    } else if (response.requires_action) {
        const {
            error: errorAction,
            paymentIntent
        } = await stripe.handleCardAction(
            response.payment_intent_client_secret
        );
        if (errorAction) {
            Vue.$toast.error(errorAction, { position: "bottom" });
        } else {
            // The card action has been handled
            // The PaymentIntent can be confirmed again on the server
            const serverResponse = await fetch("/api/checkout", {
                method: "POST",
                headers: { "Content-Type": "application/json", 'X-CSRF-TOKEN': csrfToken },
                body: JSON.stringify({
                    payment_intent_id: paymentIntent.id,
                    payload: wizardCheckout
                })
            });
            handleServerResponseCheckout(stripe, await serverResponse.json(), wizardCheckout);
        }
    } else {
        store.commit(SET_ORDER_LOADING_TO_FALSE_MUTATION)
        //go final step wizard checkout
        if(response.order){
            store.commit('SET_ORDER_DATA_MUTATION', response.order)
            router.push({name: 'order-placed'})
        }else{
            console.log('No order data');
        }
    }
};
