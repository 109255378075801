<template>
  <div class="wizard-content">
    <div class="wizard-content__column">
      <!-- <component-data :title="trans.resumeTitle[lang]">
        <cart-component></cart-component>
      </component-data> -->
      <!-- Address From component -->
      <address-form expandable>
        <span>{{ addressForm.name.value }}</span>
        <span
          >{{ addressForm.address.value }} {{ addressForm.floor.value }}, {{ addressForm.postal.value }},
          {{ addressForm.city.value }}, {{ addressForm.country.value }}</span
        >
      </address-form>
      <!--              -->
      <!-- Personal Data -->
      <component-data
        :title="trans.contactTitle[lang]"
        expandable
        class="mt-10"
      >
        <form>
          <floating-input
            v-for="(item, index) in personalData"
            :key="index"
            :name="index"
            :placeholder="trans[index][lang]"
            :initialValue="item.value"
            :initialErrors="item.errors"
            @handle-change="handlePersonalChange"
            :type="item.type"
            class="mt-2"
          ></floating-input>
        </form>
        <template v-slot:minimized-slot>
          <span>{{ personalData.email.value }}</span>
          <span>{{ personalData.phone.value }}</span>
        </template>
      </component-data>
      <!--               -->
      <!-- Shipment component -->
      <component-data :title="trans.shipmentTitle[lang]" disabled class="mt-10">
        <div class="shipment-container no-input flex-col items-start mt-3">
          <p class="free-shipping-title font-bold">{{trans.free[lang]}}</p>
          <p class="free-shipping-description mt-2">
            {{trans.shippingDescription1[lang]}}<br>
            {{trans.shippingDescription2[lang]}}
          </p>
        </div>
      </component-data>
      <!--                  -->
    </div>
    <div class="wizard-content__column">
      <!-- <component-data :title="trans.promotiontitle[lang]">
        <discount-component></discount-component>
      </component-data> -->
      <!-- Payment methods component -->
      <component-data :title="trans.paymentMethod[lang]" class="mt-3 lg:mt-0">
        <div class="accepted-cards flex flex-col items-start mt-2 mb-5">
          <span class="mb-1">{{ trans.acceptedCards[lang] }}</span>
          <div class="mt-3 flex space-x-3">
            <img src="/img/icons/visa.svg" alt="Visa">
            <img src="/img/icons/mastercard.svg" alt="Mastercard">
            <img src="/img/icons/maestro.png" alt="Maestro">
            <img src="/img/icons/american-express.png" alt="American Express">
          </div>
        </div>
        <stripe-checkout
          @stripe-invalid="stripeInvalid = $event"
        ></stripe-checkout>
      </component-data>
      <!-- Saved cards component -->

      <!--                       -->
      <!-- Cart resume component -->
      <component-data :title="trans.resumeTitle[lang]" class="mt-6">
        <cart-resume class="mt-4">
          <btn-primary
          id="submit"
            :disabled="!isValidCheckout || stripeInvalid"
            :handle-click="handleCheckout"
            class="mt-4 w-full"
            :text="trans.end[lang]"
            :loading="loading"
          ></btn-primary>
        </cart-resume>
      </component-data>
      <!--                       -->
    </div>
  </div>
</template>

<script>
import DiscountComponent from "../../wizardShop/coupon/InputCoupon.vue";
import { trans } from "../trans";

import {
  GET_ADDRESS_DATA,
  SET_ADDRESS_DATA_MUTATION,
  GET_PERSONAL_DATA,
  SET_PERSONAL_DATA_MUTATION,
  GET_SHIPMENT_DATA,
  SET_SHIPMENT_DATA_MUTATION,
  GET_PERSONAL_VALID,
  GET_ADDRESS_VALID,
  STORE_ORDER_ACTION,
  GET_ORDER_LOADING,
} from "../../../store/wizardShop/types";
import { GET_CART_COUNT, GET_SUBTOTAL } from "../../../store/cart/types";
import { mapActions, mapGetters, mapMutations } from "vuex";
import mixin from "../../../mixin";
import StripeCheckout from "../StripeCheckout";

export default {
  mixins: [mixin],
  components: {
    DiscountComponent,
    StripeCheckout,
  },
  data: () => {
    return {
      invalid: true,
      stripeInvalid: true,
    };
  },
  computed: {
    ...mapGetters({
      addressForm: GET_ADDRESS_DATA,
      personalData: GET_PERSONAL_DATA,
      personalValid: GET_PERSONAL_VALID,
      addressValid: GET_ADDRESS_VALID,
      cartCount: GET_CART_COUNT,
      loading: GET_ORDER_LOADING,
      subtotal: GET_SUBTOTAL,
    }),
    shipingdata: {
      get() {
        return this.$store.getters[GET_SHIPMENT_DATA];
      },
      set(value) {
        this.setShipmentData(value);
      },
    },
    isValidCheckout() {
      // return this.personalValid && this.addressValid && this.cartCount > 0;
      return true;
    },
    trans() {
      return trans;
    },
  },
  methods: {
    ...mapMutations({
      setAddressData: SET_ADDRESS_DATA_MUTATION,
      setPersonalData: SET_PERSONAL_DATA_MUTATION,
    }),
    ...mapActions({
      storeOrderAction: STORE_ORDER_ACTION,
    }),
    handlePersonalChange(payload) {
      this.setPersonalData({
        ...this.personalData,
        [payload.name]: {
          ...this.personalData[payload.name],
          value: payload.value,
        },
      });
    },
    handleCheckout() {
      // if (!this.isValidCheckout) return;
      // this.storeOrderAction({
      //   address: this.addressForm,
      //   personal: this.personalData,
      //   shipping: "standard",
      // });
    },
  },
  mounted() {
    // window.onbeforeunload = () => {
    //   return this.trans.unsavedChanges[this.lang];
    // };

    if (this.$route.query.error) {
      this.$toast.open({
        message: trans.payment_error[this.lang],
        type: "error",
      });
    }
  },
  beforeRouteEnter(to, from, next) {
    if (from.name == "order-placed") {
      window.location.href = "/";
    } else if(from.name == null){
      next({name: "personal-data"})
    } else{
      next();
    }
  },
};
</script>
<style lang="scss">
.bitcoin-button {
  display: none;
}
.payments-container {
  display: flex;
  flex-direction: column;
  .radio-input {
    margin-top: 2rem;
    label {
      font-size: 18px;
    }

    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
      position: absolute;
      left: -9999px;
    }
    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label {
      position: relative;
      padding-left: 28px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
      color: #000;
    }
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 19px;
      height: 19px;
      border: 1px solid #ddd;
      border-radius: 100%;
      background: #fff;
    }
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
      content: "";
      width: 11px;
      height: 11px;
      background: #618f6c;
      position: absolute;
      top: 4px;
      left: 4px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }
    [type="radio"]:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    [type="radio"]:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
}
</style>
