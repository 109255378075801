var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex justify-center wizard-form-container"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{staticClass:"w-full",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('div',{staticClass:"wizard-form"},[_c('div',{staticClass:"wizard-article"},[_c('div',{staticClass:"article-form"},[_c('h3',{staticClass:"title-form"},[_vm._v(_vm._s(_vm.trans.steps[7]['personal-info']))]),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selected.contact.name),expression:"selected.contact.name"}],staticClass:"outline-none rounded-lg appearance-none block w-full py-3 px-4 my-3",attrs:{"type":"text","placeholder":_vm.trans.steps[7].name + '*'},domProps:{"value":(_vm.selected.contact.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.selected.contact, "name", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"text-red-500"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selected.contact.email),expression:"selected.contact.email"}],staticClass:"outline-none rounded-lg appearance-none block w-full py-3 px-4 my-3",attrs:{"type":"email","placeholder":_vm.trans.steps[7].email + '*'},domProps:{"value":(_vm.selected.contact.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.selected.contact, "email", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"text-red-500"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"phone","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selected.contact.phone),expression:"selected.contact.phone"}],staticClass:"outline-none rounded-lg appearance-none block w-full py-3 px-4 my-3",attrs:{"type":"tel","placeholder":_vm.trans.steps[7].phone + '*'},domProps:{"value":(_vm.selected.contact.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.selected.contact, "phone", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"text-red-500"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._v(" "),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.selected.contact.comments),expression:"selected.contact.comments"}],staticClass:"outline-none rounded-lg appearance-none block w-full py-3 px-4 my-3 ",attrs:{"name":"","id":"","cols":"30","rows":"5","placeholder":_vm.trans.steps[7]['additional-comments']},domProps:{"value":(_vm.selected.contact.comments)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.selected.contact, "comments", $event.target.value)}}})],1),_vm._v(" "),(_vm.selected.service.ask_for_address)?_c('div',{staticClass:"article-form"},[_c('h3',{staticClass:"title-form mt-10"},[_vm._v(_vm._s(_vm.trans.steps[7].address))]),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selected.address.address),expression:"selected.address.address"}],staticClass:"outline-none rounded-lg appearance-none block w-full py-3 px-4 my-3",attrs:{"type":"text","placeholder":_vm.trans.steps[7].address + '*'},domProps:{"value":(_vm.selected.address.address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.selected.address, "address", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"text-red-500"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"addressPostal","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selected.address.postalCode),expression:"selected.address.postalCode"}],staticClass:"outline-none rounded-lg appearance-none block w-full py-3 px-4 my-3",attrs:{"type":"number","placeholder":_vm.trans.steps[7]['postal-code'] + '*'},domProps:{"value":(_vm.selected.address.postalCode)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.selected.address, "postalCode", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"text-red-500"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"addressInfo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selected.address.info),expression:"selected.address.info"}],staticClass:"outline-none rounded-lg appearance-none block w-full py-3 px-4 my-3",attrs:{"type":"text","placeholder":_vm.trans.steps[7]['more-info']},domProps:{"value":(_vm.selected.address.info)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.selected.address, "info", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"text-red-500"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"addressCity","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selected.address.city),expression:"selected.address.city"}],staticClass:"outline-none rounded-lg appearance-none block w-full py-3 px-4 my-3",attrs:{"type":"text","placeholder":_vm.trans.steps[7].city + '*'},domProps:{"value":(_vm.selected.address.city)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.selected.address, "city", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"text-red-500"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_vm._v(" "),_c('save-appointment-button',{attrs:{"button-disabled":_vm.buttonIsDisabled(invalid),"loading":_vm.loading}})],1),_vm._v(" "),_c('div',{staticClass:"wizard-article"},[_c('resume-component',{attrs:{"selected":_vm.selected,"damages":_vm.damages,"trans":_vm.trans,"locale":_vm.locale,"select-date":_vm.selectDate}})],1)])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }