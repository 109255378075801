
export const wizardMutations = {
    setCardError(state,payload){
        state.cardError = payload;
    },
    setCardMessage(state,payload){
        state.cardMessage = payload;
    },
    setState(state, { prop, data }) {
        state[prop] = data;
    },
    setSelected(state, { prop, data }) {
        state.selected[prop] = data;
    },
    resetSelected(state, keysToReset) {
        for (let key of keysToReset) {
            state.selected[key] = null;
        }
    },
    resetData(state, keysToReset) {
        for (let key of keysToReset) {
            state[key] = [];
        }
    },
    toggleSelected(state, { prop, data }) {
        if (state.selected[prop] === null) {
            state.selected[prop] = [data];
            return;
        }
        const index = state.selected[prop].findIndex(i => i.id === data.id);
        index === -1 ?
            state.selected[prop].push(data) :
            state.selected[prop].splice(index, 1);
    },
    setScreensShown(state, payload) {
        state.screensShown = payload;
    },
    setMaxIndex(state, payload) {
        state.maxIndex = payload;
    },
    setLoading(state, payload) {
        state.loading = payload;
    },
    setDiscountCode(state, payload){
        state.selected.discountCode = payload;
    },
    setDiscountObjectData(state, payload){
        state.discountObject.data = {...payload};
    },
    setDiscountLoadingToTrue(state){
        state.discountObject.loading = true
    },
    setDiscountLoadingToFalse(state){
        state.discountObject.loading = false
    },
    setScheduleClosedDates(state, payload) {
        state.scheduleClosedDates = payload;
    },
    setScheduleForDate(state, { date, times }) {
        times = times ? times.split(',') : [];
        times = times.map(time => time.replace('-', ' - '));
        state.schedule = {
            ...state.schedule,
            [date]: times,
        };
    },
    setAppointmentId(state, payload){
        state.appointment_id = payload;
    }
}
