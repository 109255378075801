<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="70" height="43" viewBox="0 0 70 43">
    <g fill="#AEAEB3">
        <path d="M32.61 6.58c-1.598-1.638-3.723-2.662-6-2.89-1.38-.13-2.94-.27-4.26.78l-.64-.5-.44-.32C17.873 1.274 13.68.323 9.59 1 5.07 1.68 2.14 4.15.86 8.32.67 9 .524 9.692.42 10.39c-.06.3-.11.6-.17.89v2.44l.15.67c.08.4.16.81.23 1.22l-.12-.13.22.59c.18 1.234.47 2.449.87 3.63.88 2.36 1.91 4.67 2.91 6.91l.18.4c.26.59.52 1.16.77 1.74 1.68 3.83 3.35 7.58 4.77 10.74.54 1.472 1.923 2.467 3.49 2.51.64-.01 1.269-.154 1.85-.42l.57-.24c.39-.17.83-.36 1.25-.56.978-.411 1.742-1.208 2.112-2.203.369-.994.31-2.097-.162-3.047l-1.71-3.88-3.13-7.14c2.747.047 5.432-.815 7.64-2.45l.19.09.82.41c.243.113.498.197.76.25 3.187.57 6.449-.483 8.7-2.81.574-.537.89-1.294.87-2.08V8.64c.015-.779-.301-1.528-.87-2.06zM8.7 23.44c.763.254 1.541.461 2.33.62l.08-.36v.38c.44.1.89.2 1.33.32.203.047.381.169.5.34 1.4 3.13 3.24 7.27 5.08 11.52.276.567.305 1.224.08 1.813-.226.59-.686 1.059-1.27 1.297-.72.35-1.44.67-2.13.93-.57.256-1.22.271-1.802.041-.582-.23-1.046-.684-1.288-1.261-2.17-4.9-4.37-9.9-6.5-14.72l-2-4.58c1.557 1.634 3.47 2.886 5.59 3.66zm-4.6-4.91c-1.81-2.014-2.66-4.712-2.33-7.4.17-2.646 1.4-5.111 3.41-6.84 1.547-1.127 3.38-1.796 5.29-1.93.54-.05 1.07-.08 1.59-.08 3.59-.08 7.067 1.257 9.68 3.72.212.23.333.528.34.84V19.55c.032.382-.125.756-.42 1-1.456 1.28-3.265 2.088-5.19 2.32-4.595.777-9.267-.862-12.37-4.34zm19.48-3.3v-4.18-5.11c0-.47.08-.64.58-.71 2.768-.482 5.593.466 7.51 2.52.206.255.322.572.33.9v9.28c-.016.369-.157.722-.4 1-1.906 2.002-4.687 2.918-7.41 2.44-.48-.07-.61-.21-.6-.69 0-1.82 0-3.68-.01-5.45zM1 16h.26H1z"/>
        <path d="M15.39 9.15l-7.28-.46H8c-1.23.008-2.246.963-2.33 2.19-.037.615.171 1.22.58 1.68.407.465.983.75 1.6.79l7.27.46h.14c1.235-.03 2.231-1.02 2.271-2.254.04-1.235-.89-2.286-2.121-2.396l-.02-.01zM69.77 13.74v-2.49c-.06-.28-.12-.59-.17-.89-.103-.692-.25-1.377-.44-2.05-1.28-4.17-4.21-6.64-8.73-7.32-4.101-.693-8.31.263-11.71 2.66l-.45.33-.62.49c-1.32-1-2.88-.91-4.26-.78-2.276.225-4.4 1.245-6 2.88-.58.538-.9 1.3-.88 2.09v9.28c-.01.782.314 1.531.89 2.06 2.255 2.32 5.516 3.366 8.7 2.79.437-.104.858-.27 1.25-.49h.09l.43-.21c2.208 1.635 4.893 2.497 7.64 2.45l-3.13 7.12-1.71 3.88c-.472.95-.53 2.053-.162 3.047.37.995 1.134 1.792 2.112 2.203.42.2.86.39 1.3.58l.52.22c.581.265 1.211.404 1.85.41 1.573-.02 2.971-1.006 3.52-2.48 1.3-2.91 3-6.76 4.77-10.74l.94-2.12c1-2.25 2-4.58 2.92-6.93.401-1.187.693-2.41.87-3.65l.21-.58-.12.14c.07-.41.15-.82.23-1.22l.14-.68zm-23.34-2.69v9.64c0 .48-.12.62-.59.69-.48.078-.964.118-1.45.12-2.265-.001-4.432-.926-6-2.56-.249-.275-.39-.63-.4-1C38 15 38 12 38 8.65c.006-.329.122-.646.33-.9 1.569-1.68 3.762-2.635 6.06-2.64.486.001.97.041 1.45.12.5.07.58.24.58.71 0 1.7 0 3.43.01 5.11zM57 24.75c.12-.17.298-.29.5-.34.44-.12.89-.22 1.43-.34.79-.168 1.572-.378 2.34-.63 2.112-.774 4.018-2.022 5.57-3.65l-2.37 5.37c-2 4.58-4.09 9.29-6.14 13.93-.24.579-.704 1.035-1.286 1.265-.583.23-1.234.214-1.804-.045-.7-.273-1.41-.583-2.13-.93-.57-.253-1.012-.73-1.221-1.317-.21-.588-.17-1.236.111-1.793 1.85-4.37 3.77-8.66 5-11.52zm-9.13-9.64v-3.57-4.67c.015-.312.139-.61.35-.84 2.634-2.495 6.153-3.84 9.78-3.74.51 0 1 0 1.57.07 1.91.13 3.745.799 5.29 1.93 2.01 1.729 3.24 4.194 3.41 6.84.33 2.688-.52 5.386-2.33 7.4-3.115 3.477-7.8 5.105-12.4 4.31-1.925-.232-3.734-1.04-5.19-2.32-.3-.241-.458-.617-.42-1-.02-1.45-.02-2.96-.02-4.41h-.04zM69 16h-.2.25H69z"/>
        <path d="M54.8 13.8h.11l7.27-.46c1.287-.05 2.29-1.133 2.24-2.42-.05-1.287-1.133-2.29-2.42-2.24l-7.41.47c-1.234.113-2.163 1.172-2.115 2.41.048 1.238 1.056 2.222 2.295 2.24h.03z"/>
    </g>
</svg>

</template>

<script>
export default {

}
</script>

<style>

</style>