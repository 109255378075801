import backCamera from './back-camera';
import backCameraGlass from './back-camera-glass';
import backCover from './back-cover';
import battery from './battery';
import brokenBackGlass from './broken-back-glass';
import camera from './camera';
import chargingPort from './chargingPort';
import earspeaker from './earspeaker';
import fingerprint from './fingerprint';
import freeDiagnostic from './free-diagnostic';
import frontCamera from './front-camera';
import frontScreen from './front-screen';
import glassProtector from './glass-protector';
import headphonePort from './headphone-port';
import microphone from './microphone';
import powerButton from './power-button';
import speakerSilence from './speaker-silence';
import touch from './touch';
import unlock from './unlock';
import vibration from './vibration';
import volume from './volume';
import waterDamage from './water-damage';
import wifi from './wifi';
import faceId from './face-id';
import simCard from './sim-card';
import software from './software';

export default {
    backCamera,
    backCameraGlass,
    backCover,
    battery,
    brokenBackGlass,
    camera,
    chargingPort,
    earspeaker,
    fingerprint,
    freeDiagnostic,
    frontCamera,
    frontScreen,
    glassProtector,
    headphonePort,
    microphone,
    powerButton,
    speakerSilence,
    touch,
    unlock,
    vibration,
    volume,
    waterDamage,
    wifi,
    faceId,
    simCard,
    software
};
