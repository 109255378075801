<template>
    <a class="product-card" :href="product.link">
        <div class="product-card-container h-full">
            <div class="image-container-outer">
                <div class="image-container-inner">
                    <img :src="product.images[0]" :alt="product.name[locale]">
                </div>
            </div>
            <div class="text-container flex flex-col flex-grow">
                <div class="pb-4">
                    <h6 class="mb-4">{{product.name[locale]}}</h6>
                    <p>{{trans && trans.warranty}}: {{product.warranty}} {{trans && trans.months}}</p>
                </div>
                <hr class="hr-style mt-auto">
                <div class="text-footer">
                    <p><span class="price">{{product.price | currency}}</span></p>
                    <p v-if="product.old_price && product.old_price > product.price" class="line-through lg:pl-3 lg:self-center color-old-price">{{product.old_price | currency}}</p>
                </div>
                    <p v-if="product.old_price && product.old_price > product.price" class="color-discount">{{product.discount_percentage}}% {{trans && trans.discount}}</p>
            </div>
        </div>
    </a>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
    props:['product'],
    computed: {
        ...mapGetters({
            locale: "getLocale",
            trans: "getTrans"
        }),
    },
}
</script>
<style lang="scss" scoped>
.product-card{
    padding: 2rem 1rem;
    border-radius: 30px;
    box-shadow: 0 3px 20px 0 rgba(17, 17, 95, 0.1);
    background-color: #ffffff;
    max-width: 262px;
    width: 100%;
    // margin: 1rem;
    &:hover{
        cursor: pointer;
    }
    &-container{
        display: flex;
        flex-direction: column;
        .image-container-outer{
            margin-bottom: 2rem;
            width: 100%;
            padding-top: 133%;
            position: relative;
            .image-container-inner {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: #e9eef1;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        .text-container{
            .text-footer{
                color: #616161;
                font-size: 14px;
                display: flex;
                flex-direction: column;
                .color-old-price{
                    color: #727272;
                }

                p{
                    font-size: 12px;
                    @media (min-width:1024px) {
                         font-size: 16px;
                    }
                }
                @media (min-width:1024px) {
                    flex-direction: row;


                }
            }
            .color-discount{
                    font-size: 14px;
                    color: #23a936;
                }
            .price{
                color: #3c3cfa;
                font-size: 16px;
                font-weight: bold;
                @media (min-width:1024px) {
                   font-size: 18px;

                }
            }
            .hr-style{
                margin: 1rem 0;
                color: #8a8a8a;
            }
        }
    }
}
</style>
