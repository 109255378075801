import axios from "axios";
import { forEach } from "lodash";
const shopActions = {
    async loadproducts({ commit, dispatch, state }, payload) {
        if (payload === 'accessories') {
            const { data } = await axios.get('/api/accessory-types');
            commit('setState', { prop: 'accessoryTypes', data });
        }
        return axios.get('/api/products/'+payload)
            .then(( res ) =>{

                dispatch('resetFilter');
                commit('setProducts', res.data );
                commit('setProductsFiltered', res.data );
                dispatch('filterProducts' , res.data );
                dispatch('filterBrands');
                if(payload == 'accessories'){
                    dispatch('filterAccessoriesTypes');
                }
                commit('setState' , { prop: 'shopLoading', data: false});
            }
        );
    },
    filterBrands({ commit , state }){
        let brands = [ ...new Set( state.products.map( x=> x.brand ) )]

        commit('setFilter', {prop:'brands' , value: brands})
    },
    filterAccessoriesTypes({ commit , state }){
        let types_count = [ ...new Set( state.products.map( x=> x.type ) )];
        commit('setFilter', {prop: 'type_accessories', value: types_count})
    },
    selectBrandFilter({ dispatch ,state }, payload){
        state.filter.brandfilter = payload;

        dispatch('filterProducts')
    },
    selectTypeAcc({commit , dispatch}, payload){
        commit('setFilter', {prop: 'typefilter', value: payload})
        dispatch('filterProducts')
    },
    filterProducts({ commit , state }){
        commit('setState' , { prop: 'shopLoading', data: true});
        if(state.category == 'accessories' && state.filter.typefilter != 'all'){
            let accessories_filtered = state.products.filter( product => product.type == state.filter.typefilter);
            if(state.filter.brandfilter.length > 0){
                let filterSet = new Set( state.filter.brandfilter );
                let products_filtered = accessories_filtered.filter( product => filterSet.has(product.brand))
                commit('setFilter' , { prop: 'all', value: false});
                commit('setProductsFiltered', products_filtered );
            }else{
                commit('setFilter' , { prop: 'all', value: true});
                commit('setProductsFiltered', accessories_filtered );
            }
        }else{
            if(state.filter.brandfilter.length > 0 ){
                commit('setFilter' , { prop: 'all', value: false});
                let filterSet = new Set( state.filter.brandfilter );
                let products_filtered = state.products.filter( product => filterSet.has(product.brand))
                commit('setProductsFiltered', products_filtered );
            }else{
                commit('setFilter' , { prop: 'all', value: true});
                commit('setProductsFiltered', state.products );
            }
        }
            commit('setState' , { prop: 'shopLoading', data: false});
    },
    resetFilter({ commit, state }){
        commit('setFilter', { prop: 'all', value: true })
        commit('setFilter', { prop: 'brandfilter', value: [] })
        commit('setProductsFiltered', state.products );
    },
    resetAccesory({ commit, state }){
        commit('setFilter', { prop: 'typefilter', value: 'all' })
        commit('setProductsFiltered', state.products );
    }

};

export default shopActions;
