<template>
    <div class="confirmation-component flex flex-col items-center">
        <h1>{{ trans.steps[8].title }}</h1>
        <p class="mb-8">{{ subtitle }}</p>
        <h3 class="title-resume mb-8">ID reserva: {{ appointment_id}}</h3>
        <img src="/img/wizard-confirmation.svg" alt="image of calendar">
        <button @click="backToHome" class="next-button mdc-button mdc-button--unelevated mt-12">
            <div class="mdc-button__ripple"></div>
            <span class="mdc-button__label">{{ trans.steps[8]['back-to-home'] }}</span>
        </button>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { Skeleton } from "vue-loading-skeleton";

export default {
    components: {
        Skeleton
    },
    computed: {
        ...mapGetters({
            trans: 'getTrans',
            selected: 'getSelected',
            locale: 'getLocale',
            appointment_id: "getAppointmentId"
        }),
        subtitle() {
            let subtitle = this.trans.steps[8].subtitle;
            subtitle = subtitle.replace(':device', this.trans.steps[1].devices[this.selected.device?.slug].toLowerCase());
            subtitle = subtitle.replace(':date', this.date);
            return subtitle;
        },
        date() {
            const options = { year: 'numeric', month: 'long', day: 'numeric' };
            const lang = this.locale;
            const fecha = this.selected.date.toLocaleDateString(lang, options);
            return fecha;
        }
    },
    methods: {
        backToHome() {
            window.location = `/${this.locale}`;
        }
    },
    created() {
        this.$store.commit('setMaxIndex', -1);
    }
};
</script>

<style scoped>
button {
    width: 170px !important;
}
.title-resume {
    color:#3c3cfa;
}
</style>
