<template>
    <div
        class="map-container flex flex-col justify-center items-center relative h-full my-8"
    >
        <div id="map" class="contact-map"></div>
        <div class="map-info absolute" v-if="shops">
            <div
                v-for="shop in shops"
                :key="shop.id"
                class="map-info-container flex h-auto w-full rounded-3xl mb-3 bg-white relative"
            >
                <div
                    class="map-info-container__content flex justify-between w-full p-4"
                >
                    <div class="flex flex-col sm:ml-2 w-full">
                        <h4 class="font-bold bgc-dark mb-2 text-base">
                            {{ shop.city }}
                        </h4>
                        <p class="text-xs underline bgc-dark">
                            {{ shop.street }}
                        </p>
                        <div class="flex mb-1 mt-2">
                            <p class="text-xs bgc-dark">
                                Teléfono: {{ shop.phone }}
                            </p>
                        </div>
                        <div class="flex mb-1">
                            <a
                                :href="shop.url"
                                class="text-primary text-base font-bold flex"
                                target="_blank"
                            >
                                Ver en Google maps
                                <img
                                    class="ml-4"
                                    src="/img/icons/arrow-right-fill.svg"
                                    alt="arrow-right"
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const shops = [
    {
        id: 1,
        city: "Barcelona",
        street: "Carrer de la Marina, 145, 08013 Barcelona",
        phone: "+34 612 56 89 72",
        url: "https://goo.gl/maps/iNcWDqtUNbWo6UxF8",
        latitude: 41.397579,
        longitude: 2.182897
    },
    {
        id: 2,
        city: "Palma de Mallorca",
        street: "Carrer de Cazador, 6, 07002 Palma de Mallorca, Illes Balears",
        phone: "+34 612 40 22 06",
        url: "https://goo.gl/maps/6367xECePd1Pk8Z27",
        latitude: 39.573323,
        longitude: 2.654923
    }
];
export default {
    data() {
        return {
            markers: [],
            test: "",
            isOpen: false,
            shops: []
        };
    },
    beforeMount() {
        this.shops = shops;
    },
    mounted() {
        window.addEventListener("load", () => {
            this.initMap();
        });
    },
    methods: {
        initMap() {
            this.map = new google.maps.Map(document.getElementById("map"), {
                disableDefaultUI: true
            });
            this.setupMap(this.mapZoom);
        },
        setupMap(zoom) {
            return new Promise(resolve => {
                this.addMapMarkers();
                this.setMapCenter({
                    latitude: this.centerCoordinates.lat,
                    longitude: this.centerCoordinates.lng
                });
                this.map.setZoom(zoom);
                resolve();
            });
        },
        setMapCenter(center) {
            this.map.setCenter({
                lat: Number(center.latitude),
                lng: Number(center.longitude)
            });
        },
        addMapMarkers() {
            const that = this;
            let marker, location;
            this.shops.forEach((shop, key) => {
                location = {
                    lat: Number(shop.latitude),
                    lng: Number(shop.longitude)
                };
                marker = new google.maps.Marker({
                    position: location,
                    map: this.map
                });

                shop.marker = marker;
                this.markers.push(marker);
            });
        }
    },
    computed: {
        mapZoom() {
            return 7;
        },
        centerCoordinates() {
            return {
                lat: 40.5,
                lng: 3.0
            };
        }
    }
};
</script>

<style lang="scss">
.map-container {
    .map-info {
        top: -0;
        right: 15px;

        @media (max-width: 1024px) {
            top: 0;
            right: 0;
            position: relative !important;
        }
    }
    .map-info-container {
        max-width: 320px;
        margin: 1rem;
        box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.15);
        border-radius: 30px;
        .img-x {
            position: absolute;
            top: 10px;
            right: 15px;
            @media (max-width: 400px) {
                right: 12px;
            }
        }
        @media (max-width: 640px) {
            max-width: 300px;
        }
        @media (max-width: 400px) {
            max-width: 275px;
        }
        @media (max-width: 375px) {
            max-width: 265px;
        }
    }
}
.contact-map {
    height: 30rem;
    width: 100vh;
    border-radius: 30px;

    @media (max-width: 1024px) {
        border-radius: 0;
        max-width: 768px;
    }

    @media (max-width: 425px) {
        border-radius: 0;
        max-width: 425px;
    }

    @media (max-width: 375px) {
        border-radius: 0;
        max-width: 375px;
    }

    @media (max-width: 325px) {
        border-radius: 0;
        max-width: 325px;
    }
}
</style>
