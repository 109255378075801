<template>
  <div class="models-container">
    <h2>{{trans.model}}</h2>
    <div class="devices" v-if="devices.length > 1">
      <button
        v-for="device in devices"
        :key="device.id"
        class="device"
        :class="{active : activeDevice === device.id}"
        @click="selectDevice(device)"
      >{{ deviceNameTranslated(device.slug) }}</button>
    </div>

    <transition-group
      tag="div"
      name="staggered-fade"
      class="models"
    >
      <button
        @click="goToWizard(model)"
        class="model"
        v-for="model in modelsFiltered"
        :key="model.id"
      >{{model.name}}</button>
    </transition-group>
  </div>
</template>

<script>
export default {
  props: ["brand","devices", "models", "trans"],
  data: () => ({
    active: "",
  }),
  computed: {
    activeDevice() {
      return this.active || this.devices[0].id;
    },
    modelsFiltered() {
      return this.models.filter(
        (model) => model.device_id == this.activeDevice
      );
    },
  },
  methods: {
    selectDevice(device) {
      this.active = device.id;
    },
    goToWizard(model) {
      const brandsLocalized = window.appLocale === "es" ? "marcas" : "brands";
      const { id, slug, device_id } = model;
      window.location = `/${window.appLocale}/wizard/${brandsLocalized}/${this.brand.slug}/${model.slug}/${window.appLocale === 'es' ? 'reparacion' : 'repair'}?d=${device_id}&m=${id}`;
    },
    deviceNameTranslated(slug) {
        switch (slug) {
            case 'tablet':
                return 'Tablet';
            case 'phone':
                return window.appLocale === 'es' ? 'Teléfono' : 'Phone';
            case 'watch':
                return window.appLocale === 'es' ? 'Reloj' : 'Watch';
        }
    }
  },
};
</script>

<style>
</style>
