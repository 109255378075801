<template>
  <div v-if="loading">
    <button class="btn has-spinner"> <span class="spinner black"></span></button>
  </div>
  <div v-else-if="!loading && items.length > 0" class="cart">
    <transition-group
      name="fade"
      enter-active-class="animated fadeInUp"
      leave-active-class="animated fadeOutDown"
    >
      <cart-item v-for="item in items" :key="item.id" :item="item"></cart-item>
    </transition-group>
  </div>
  <span v-else-if="!loading && items.length == 0">
    {{ noData[lang] }}
  </span>
</template>

<script>
import CartItem from "./CartItem";
import { mapGetters } from "vuex";
import { GET_ITEM_SELECTOR, GET_CART_LOADING } from "../../store/cart/types";
import mixin from "../../mixin";

export default {
  mixins: [mixin],
  computed: {
    ...mapGetters({
      items: GET_ITEM_SELECTOR,
    }),
  },
  watch: {
    items: function (val) {
      this.loading = false;
    },
  },
  data: () => {
    return {
      noData: {
        en: "No items on cart",
        es: "No hay productos en el carrito",
      },
      loading: true,
    };
  },
  components: {
    CartItem,
  },
  created () {
    this.$store.commit('setState', { prop: 'locale', data: window.appLocale });
  },
};
</script>

<style lang="scss" scoped>
.cart {
  @media (min-width: 992px) {
    padding: 0.45rem;
  }
}
</style>
