const shopGetters = {
    getShopProducts(state) {
        return state.products;
    },
    getShopProductsFiltered(state) {
        return state.products_filtered;
    },
    getCategory(state) {
        return state.category;
    },
    getShopLoading(state) {
        return state.shopLoading;
    },
    getPhoneBrands(state){
        return state.filter.brands;
    },
    getAccessoriesTypes(state){
        return state.filter.type_accessories;
    },
    getTypeFilter(state){
        return state.filter.typefilter;
    },
    getFilterAll(state){
        return state.filter.all;
    },
    getTrans(state){
        return state.trans;
    },
    getLocale(state) {
        return state.locale;
    },
};

export default shopGetters;
