<template>
    <div class="calendar-component flex flex-col justify-between">
        <h1>{{ trans.steps[5].title }}</h1>
        <div class="date-time-container flex flex-col lg:flex-row mx-auto">
            <div class="date lg:pr-16 mb-12 lg:mb-16">
                <v-calendar
                    is-expanded
                    :locale="locale"
                    :masks="{ weekdays: 'WW' }"
                    :first-day-of-week="2"
                    nav-visibility="hidden"
                    :attributes="attrs"
                    :disabled-dates="disabledDates"
                    :min-date="new Date()"
                    @dayclick="dayclick"
                    :from-page="date && { month: date.getMonth() + 1, year: date.getFullYear() }"
                    ref="calendar"
                    :key="key"
                />
            </div>
            <div class="time">
                <h2 class="text-center text-primary">{{ trans.steps[5]['choose-time'] }}</h2>
                <div class="justify-center mt-5" :class="[loadingSchedule ? 'flex' : 'hidden']">
                    <span class="spinner"></span>
                </div>
                <p class="no-times-warning mx-auto mt-4" v-if="noTimesAvailableForDateSelected">
                    {{ trans.steps[5]['no-times-available'] }}
                </p>
                <div v-if="showTimes">
                    <h3 v-show="timesShown.morning.length" class="text-center text-primary underline mt-5 mb-4">{{ trans.steps[5].morning }}</h3>
                    <div v-show="timesShown.morning.length" class="cards grid" :class="cardsGridClasses('morning')">
                        <button class="card m-0" v-for="time in timesShown.morning" :key="time" :class="{ selected: isSelectedTime(time) }" @click="setTime(time)">
                            {{ time }}
                        </button>
                    </div>
                    <h3 v-show="timesShown.afternoon.length" class="text-center text-primary underline mt-5 mb-4">{{ trans.steps[5].afternoon }}</h3>
                    <div v-show="timesShown.afternoon.length" class="cards grid" :class="cardsGridClasses('afternoon')">
                        <button class="card m-0" v-for="time in timesShown.afternoon" :key="time" :class="{ selected: isSelectedTime(time) }" @click="setTime(time)">
                            {{ time }}
                        </button>
                    </div>
                </div>

            </div>
        </div>

        <div class="flex justify-center mt-12 lg:mt-0">
            <button @click="nextStep" :disabled="nextButtonDisabled" :class="{ disabled: nextButtonDisabled }" class="next-button mdc-button mdc-button--unelevated">
                <div class="mdc-button__ripple"></div>
                <span class="mdc-button__label">{{ trans.next }}</span>
            </button>
        </div>
    </div>
</template>

<script>
import { Skeleton } from "vue-loading-skeleton";
import { mapGetters, mapMutations } from 'vuex';

const times = {
    morning: ['10:00 - 15:00'],
    afternoon: ['17:00 - 19:00']
};

export default {
    components: {
        Skeleton
    },
    data() {
        return {
            key: 0,
            loadingSchedule: false,
        }
    },
    computed: {
        ...mapGetters({
            trans: 'getTrans',
            selected: 'getSelected',
            locale: 'getLocale',
            schedule: 'getSchedule',
        }),
        nextButtonDisabled() {
            return ! this.date || ! this.time;
        },
        attrs() {
            return [
                {
                    highlight: true,
                    dates: this.date
                },
            ]
        },
        date() {
            return this.selected.date;
        },
        showTimes() {
            if (! this.showFullCalendar) return true;
            if (! this.date) return false;
            const key = this.dateAsString(new Date(this.date));
            return this.scheduleLoadedForDate(key) && this.schedule[key].length;
        },
        time() {
            return this.selected.time;
        },
        sameDay() {
            return this.selected.service?.same_day;
        },
        showFullCalendar() {
            return this.selected.service?.show_full_calendar;
        },
        timesShown() {
            if (! this.showFullCalendar) {
                return times;
            } else {
                return this.timesScheduleForSelectedDate;
            }
        },
        timesScheduleForSelectedDate() {
            const actualDate = new Date();
            const date = this.selected.date;
            
            return this.schedule[this.dateAsString(this.date)].reduce((agg, curr) => {
                if((parseInt(curr.slice(0,2)) > actualDate.getHours()) || (date.getDate() !== actualDate.getDate() || date.getMonth() !== actualDate.getMonth())){
                    if (curr.slice(0, 2) < '15') {
                        agg.morning = [...agg.morning, curr];
                    } else {
                        agg.afternoon = [...agg.afternoon, curr];
                    }
                }
                return agg;
            }, { morning: [], afternoon: []});
        },
        noTimesAvailableForDateSelected() {
            return this.showFullCalendar &&
                this.date &&
                this.scheduleLoadedForDate(this.dateAsString(new Date(this.date))) &&
                ! this.schedule[this.dateAsString(this.date)].length;
        },
        disabledDates() {
            if (! this.showFullCalendar) {
                const disabledDates = [{ start: null, end: new Date() }, { weekdays: [1, 7] }];
                // Disable tomorrow date if the time is 7pm or later
                const options = {
                    timeZone: 'Europe/Madrid',
                    hour: 'numeric',
                },
                formatter = new Intl.DateTimeFormat([], options);
                const today = new Date();
                const hour = formatter.format(today);
                if (Number(hour) >= 19) {
                    const tomorrow = today.setDate(today.getDate() + 1);
                    disabledDates.push(tomorrow);
                }
                return disabledDates;
            } else {
                let end = new Date();
                if (this.sameDay) {
                    end = end.setDate(end.getDate() - 1);
                }
                const disabledDates = [{ start: null, end }, { weekdays: [] }];
                const { scheduleClosedDates } = this.$store.state;
                if (! scheduleClosedDates) {
                    return disabledDates;
                }
                const { closedDays, closedDates } = scheduleClosedDates;
                for (let day of closedDays) {
                    disabledDates[1].weekdays.push(day);
                }
                for (let date of closedDates) {
                    disabledDates.push(new Date(date));
                }
                return disabledDates;
            }
        },
    },
    watch: {
        date: {
            async handler(date) {
                this.setSelected({ prop: 'time', data: null });
                date = this.dateAsString(date);
                if (! this.showFullCalendar || this.scheduleLoadedForDate(date)) {
                    return;
                }
                this.loadingSchedule = true;
                await this.$store.dispatch('retrieveScheduleForDate', date);
                this.loadingSchedule = false;
            },
            immediate: true,
        }
    },
    methods: {
        ...mapMutations(['setSelected']),
        setTime(time) {
            return this.setSelected({ prop: 'time', data: time });
        },
        isSelectedTime(time) {
            return this.selected.time === time;
        },
        nextStep() {
            this.$store.dispatch('retrieveExtras');
            this.$router.push(this.$store.getters.getNextRoute(6)).catch(e => e);
        },
        dayclick({ year, month, day }) {
            const data = new Date(year, month - 1, day);
            data.setHours(12);
            this.setSelected({ prop: 'date', data });
            this.key = this.key + 1;
        },
        dateAsString(date) {
            if (! date) return '';
            return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate()}`;
        },
        scheduleLoadedForDate(dateKey) {
            return this.schedule.hasOwnProperty(dateKey);
        },
        cardsGridClasses(shift) {
            if (this.timesShown[shift].length > 2) {
                return ['three-cols'];
            }
            if (this.timesShown[shift].length === 2) {
                return ['two-cols'];
            }
            return [];
        },
        async setInitialDate() {
            if (! this.date ) {
                if (! this.selected.service.show_full_calendar) {
                    let date = new Date();
                    date.setDate(date.getDate() + 1);
                    while ([0, 6].includes(date.getDay())) {
                        date.setDate(date.getDate() + 1);
                    }
                    date.setHours(12);
                    this.setSelected({ prop: 'date', data: date });
                    this.key = this.key + 1;
                } else {
                    const { data } = await store.dispatch("retrieveFirstAvailableDateForService");
                    const [year, month, day] = data.split('-').map(Number);
                    this.dayclick({ year, month, day });
                }
            }
        }
    },
    async beforeRouteEnter(to, from, next) {
        const { store } = await import('../../store');
        if (store.state.selected.service.show_full_calendar) {
            store.commit('setState', { prop: 'schedule', data: {} });
            await store.dispatch("retrieveScheduleClosedDates");
        }
        next();
    },
    async created() {
        this.setInitialDate();
    },
};
</script>

<style scoped lang="scss">
@import '../../../sass/_variables';

.date-time-container {
    max-width: 1000px;

    .date {
        @media (min-width: 1024px) {
            width: 35vw;
            max-width: 672px;
        }

        div:first-child {
            border: none;
            border-radius: 20px;
            background: transparent;
        }

        .note {
            font-size: 14px;
        }
    }

    .time {
        @media (min-width: $lg) {
            width: 358px;
        }

        h2 {
            font-size: 20px;
        }

        h3 {
            font-size: 14px;
        }

        .grid {
            max-width: 90vw;
            grid-gap: 8px;
            grid-template-columns: minmax(100px, 114px);

            &.two-cols {
                grid-template-columns: repeat(2, minmax(100px, 114px));
            }

            &.three-cols {
                grid-template-columns: repeat(3, minmax(100px, 114px));
            }
        }

        .card {
            height: 49px;
            border-radius: 10px;

            &.selected {
                background: $white;
                color: black;
            }

            &.disabled {
                opacity: 0.53;
                pointer-events: none;
            }
        }

        .spinner {
            width: 24px;
            height: 24px;
            border: 5px solid $primary;
            border-radius: 100%;
            border-top: 5px solid transparent;
            animation: spinner infinite linear 1s;
        }
        @keyframes spinner {
            0% {
                transform: rotate(0deg);
            }

            50% {
                transform: rotate(180deg);
                opacity: 0.35;
            }

            100% {
                transform: rotate(360deg);
            }
        }

        .no-times-warning {
            max-width: 334px;
        }

    }
}
</style>
