<template>
  <div class="container wizard-content mt-20 xl:mt-24">
    <div class="space flex flex-col">
      <transition name="slide-fade" mode="out-in" appear>
        <router-view></router-view>
      </transition>
      <p class="text-center font-bold mt-10">{{trans['free-shipping']}}</p>
      <p class="contact-link font-bold flex-grow flex flex-col items-center md:flex-row md:items-end justify-center text-center">
        {{ trans['cant-find'] }}
        <a :href="getContactLink" class="mt-3 md:mt-0 ml-2 flex justify-center text-primary">
          {{ trans['contact-us'] }}
          <img class="ml-2" src="/img/icons/arrow-right.svg" alt="arrow right">
        </a>
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ['lang'],
  computed: {
    ...mapGetters({ trans: 'getTrans' }),
    getContactLink() {
      const locale = window.appLocale;
      return `/${locale}/${locale === 'es' ? 'contacto' : 'contact'}`;
    }
  }
};
</script>
