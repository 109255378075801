import {
    GET_ITEM_SELECTOR,
    GET_CART_COUNT,
    GET_CART_LOADING,
    GET_SUBTOTAL,
    GET_TOTAL,
    GET_SHIPMENT_PRICE,
    GET_REMAINING_FREE,
    GET_PRODUCT_HAS_ENOUGH_STOCK,
} from "./types";


const cartGetters = {
    [GET_ITEM_SELECTOR](state) {
        return state.cartItems;
    },
    [GET_CART_COUNT](state, getters) {
        if (state.cartItems && state.cartItems.length > 0) {
            return state.cartItems
                .map(item => item.quantity)
                .reduce((total, amount) => total + amount);
        } else {
            return 0;
        }
    },
    [GET_CART_LOADING](state) {
        return state.cartLoading;
    },
    [GET_SUBTOTAL](state) {
        let subtotal = 0;

        for (var item of state.cartItems) {
            subtotal += item.price * item.quantity;
        }

        return subtotal;
    },
    [GET_TOTAL](state, getters) {
        return parseFloat(getters[GET_SUBTOTAL]) + parseFloat(getters[GET_SHIPMENT_PRICE]);
    },
    [GET_SHIPMENT_PRICE](state, getters) {
        return 0;
    },
    [GET_REMAINING_FREE](state, getters){
        return 100;
    },
    [GET_PRODUCT_HAS_ENOUGH_STOCK]: state => (productId, productStock, unitsToBeAddedToCart) => {
        const productInCart = state.cartItems.find(({id}) => id == productId);
        const unitsInCart = productInCart ? productInCart.quantity : 0;
        return productStock - unitsInCart - unitsToBeAddedToCart >= 0;
    }
    // getCouponMessage(state) {
    //     return state.couponmessage;
    // },
    // getCoupon(state) {
    //     return state.coupon;
    // },
    // getCouponApplicable(state, getters, mutations) {
    //     if (!state.coupon) return false;

    //     switch (state.coupon.requirements) {
    //         case "monto":
    //             if (getters.getSubtotal >= state.coupon.req_qty) {
    //                 state.couponmessage = "";
    //                 return true;
    //             } else {
    //                 state.couponmessage =
    //                     "Código no valido, tu compra debe ser de " +
    //                     state.coupon.req_qty +
    //                     "€ o más.";
    //                 return false;
    //             }
    //         case "quantity":
    //             if (getters.getCartCount >= state.coupon.req_qty) {
    //                 state.couponmessage = "";
    //                 return true;
    //             } else {
    //                 state.couponmessage =
    //                     "Código no valido, tu compra debe ser de " +
    //                     state.coupon.req_qty +
    //                     " artículos o más.";
    //                 return false;
    //             }
    //         case "none":
    //             state.couponmessage = "";
    //             return true;
    //     }
    // }
};

export default cartGetters;
