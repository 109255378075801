<template>
    <div>
        <h1>{{ trans.steps[0].title }}</h1>
        <div class="cards">
            <button :class="{ selected : isSelected(brand) }" @click="select(brand)" v-for="brand in brands" :key="brand.id" class="card flex flex-col items-center justify-center font-bold">
                <img class="mb-1" :src="`/img/brands/${brand.slug}.png`" :alt="brand.name">
                {{ brand.name }}
            </button>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
    computed: {
        ...mapGetters({
            brands: "getBrands",
            trans: 'getTrans',
            selected: 'getSelected',
        }),
        selectedBrand() {
            return this.selected.brand;
        }
    },
    watch: {
        selectedBrand(newVal, oldVal) {
            newVal = newVal?.id;
            oldVal = oldVal?.id;

            if (newVal && oldVal && newVal !== oldVal) {
                this.resetSelected(['device', 'model', 'damages', 'screen']);
                this.resetData(['devices', 'models', 'damages']);
                this.setScreensShown(false);
                this.setMaxIndex(1);
            }
        }
    },
    methods: {
        ...mapActions(['retrieveBrand']),
        ...mapMutations(['setSelected', 'resetSelected', 'resetData', 'setMaxIndex', 'setState', 'setScreensShown']),
        select(brand) {
            this.setSelected({ prop: 'brand', data: brand });
            this.retrieveBrand(brand.slug);
            this.$nextTick(() => {
                this.$router.push(this.$store.getters.getNextRoute(1)).catch(e => e);
            });
        },
        isSelected(brand) {
            return this.selected.brand?.id === brand.id;
        }
    }
};
</script>

<style scoped lang="scss">
.card {
    width: 120px;
    height: 140px;
    @media (min-width: 640px) {
       width: 102px;
        height: 123px;
    }
}
img {
    height: 80px;
}
</style>
