<template>
<svg class="sim-card-icon" viewBox="0 0 33 60" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="wizard-4.2-1920" transform="translate(-525.000000, -921.000000)">
            <g id="sim-card" transform="translate(525.380151, 921.682870)">
                <g id="icon-software2-copy-3">
                    <path d="M7.41671931,0 L24.4167193,0 L23.0106376,2.87795689 C22.6751848,3.56455893 21.9778092,4 21.2136424,4 L10.4083786,4 C9.59910518,4 8.86959008,3.51230068 8.56025293,2.76448118 L7.41671931,0 L7.41671931,0 Z" id="Rectangle" fill="#979797" fill-rule="nonzero"></path>
                    <path d="M0.5,10 L1,10 L1,10 L1,15 L0.5,15 C0.223857625,15 0,14.7761424 0,14.5 L0,10.5 C0,10.2238576 0.223857625,10 0.5,10 Z" id="Rectangle" fill="#979797" fill-rule="nonzero"></path>
                    <path d="M31.3334394,12 L31.8334394,12 L31.8334394,12 L31.8334394,17 L31.3334394,17 C31.057297,17 30.8334394,16.7761424 30.8334394,16.5 L30.8334394,12.5 C30.8334394,12.2238576 31.057297,12 31.3334394,12 Z" id="Rectangle-Copy-3" fill="#979797" fill-rule="nonzero" transform="translate(31.333439, 14.500000) scale(-1, 1) translate(-31.333439, -14.500000) "></path>
                    <path d="M0.5,15.8560838 L1,15.8560838 L1,15.8560838 L1,20.8560838 L0.5,20.8560838 C0.223857625,20.8560838 0,20.6322262 0,20.3560838 L0,16.3560838 C0,16.0799414 0.223857625,15.8560838 0.5,15.8560838 Z" id="Rectangle-Copy" fill="#979797" fill-rule="nonzero"></path>
                    <rect id="Rectangle" stroke="#979797" stroke-width="1.6" x="1.8" y="0.8" width="28.2334386" height="57.4" rx="4"></rect>
                </g>
                <g id="Group-3" transform="translate(7.104220, 17.425320)" fill="#979797" fill-rule="nonzero" stroke="#979797" stroke-width="0.5">
                    <path d="M17.4815674,5.03928125 L12.5838369,0.143432617 C12.49204,0.0516357422 12.3677471,0 12.2376709,0 L1.95834863,0 C0.878266602,0 0,0.878266602 0,1.95834863 L0,21.5416973 C0,22.6217334 0.878266602,23.5 1.95834863,23.5 L15.6666973,23.5 C16.7467334,23.5 17.625,22.6217334 17.625,21.5416514 L17.625,5.38540137 C17.625,5.25537109 17.5733643,5.13107812 17.4815674,5.03928125 Z M16.6458486,21.5416514 C16.6458486,22.081417 16.2064629,22.5208027 15.6666973,22.5208027 L1.95834863,22.5208027 C1.41858301,22.5208027 0.979197266,22.081417 0.979197266,21.5416514 L0.979197266,1.95834863 C0.979197266,1.41858301 1.41858301,0.979197266 1.95834863,0.979197266 L12.0349834,0.979197266 L16.6458486,5.58813477 L16.6458486,21.5416514 Z" id="Shape"></path>
                    <path d="M2.9375,10.28125 L2.9375,19.09375 C2.9375,19.9036738 3.59632617,20.5625 4.40625,20.5625 L13.21875,20.5625 C14.0286738,20.5625 14.6875,19.9036738 14.6875,19.09375 L14.6875,10.28125 C14.6875,9.47132617 14.0286738,8.8125 13.21875,8.8125 L4.40625,8.8125 C3.59632617,8.8125 2.9375,9.47132617 2.9375,10.28125 Z M6.36459863,19.5833486 L4.40625,19.5833486 C4.1361377,19.5833486 3.91665137,19.3639082 3.91665137,19.09375 L3.91665137,17.1354014 L6.36455273,17.1354014 L6.36455273,19.5833486 L6.36459863,19.5833486 Z M10.28125,19.5833486 L7.34375,19.5833486 L7.34375,17.1354473 L10.28125,17.1354473 L10.28125,19.5833486 Z M13.7083486,19.09375 C13.7083486,19.3638623 13.4889082,19.5833486 13.21875,19.5833486 L11.2604014,19.5833486 L11.2604014,16.6458486 C11.2604014,16.3752314 11.0414199,16.15625 10.7708027,16.15625 L3.91665137,16.15625 L3.91665137,13.21875 L13.7083486,13.21875 L13.7083486,19.09375 L13.7083486,19.09375 Z M11.2604014,9.79165137 L13.21875,9.79165137 C13.4888623,9.79165137 13.7083486,10.0110918 13.7083486,10.28125 L13.7083486,12.2395986 L11.2604473,12.2395986 L11.2604473,9.79165137 L11.2604014,9.79165137 Z M7.34375,9.79165137 L10.28125,9.79165137 L10.28125,12.2395527 L7.34375,12.2395527 L7.34375,9.79165137 Z M6.36459863,12.2395986 L3.91669727,12.2395986 L3.91669727,10.28125 C3.91669727,10.0111377 4.1361377,9.79165137 4.4062959,9.79165137 L6.36464453,9.79165137 L6.36464453,12.2395986 L6.36459863,12.2395986 Z" id="Shape"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
</template>

<script>
export default {};
</script>
