<template>
    <div class="wizard-steps mt-20 mx-auto relative">
        <span class="line absolute"></span>
        <span
            class="line-active absolute"
            :style="{ width: widthLine() }"
        ></span>

        <div
            v-for="(step, index) in steps"
            :key="index"
            class="step flex flex-col items-center absolute"
            :id="`step-${index + 1}`"
            :class="{ disabled: maxIndex < index }"
        >
            <div
                class="flex flex-col items-center relative container justify-between w-full"
                :class="{ active: stepIsActive(step) }"
            >
                <span
                    :id="`step-name-${index + 1}`"
                    class="step-name hidden lg:inline text-center mt-3"
                    :class="{ active : stepIsActive(step) || stepIsActivated(index),
                              displaynone: stepIsActivated(index)}"
                    >{{ trans.steps[index].step }}
                </span>
                <span class="step-value hidden lg:flex lg:items-center lg:justify-center text-center mb-3 font-bold cursor-default" :title="getValue(step)">
                    {{ trimValue(getValue(step)) }}
                </span>
                <span
                    @click="goToRoute(index)"
                    class="circle relative bg-white flex justify-center items-center"
                    :class="{
                        active: stepIsActive(step),
                        'cursor-pointer': stepIsAllowed(index),
                        'cursor-not-allowed': !stepIsAllowed(index),
                        'active-later': stepIsActivated(index)
                    }"
                >
                    <span class="number"></span>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { eventBus } from "../../app";

export default {
    computed: {
        ...mapGetters({
            steps: "getRoutes",
            locale: "getLocale",
            trans: "getTrans",
            selected: "getSelected",
            maxIndex: "getMaxIndex",
            stepSelected: "getStepSelected"
        }),
    },
    watch: {},
    methods: {
        stepIsActivated(step) {
            if (this.stepSelected > step) {
                return true;
            } else {
                return false;
            }
        },
        stepIsActive(step) {
            return this.$route.name === step.name;
        },

        stepIsAllowed(index) {
            return index <= this.maxIndex;
        },

        goToRoute(index) {
            if (this.stepIsAllowed(index)) {
                this.$store.commit('setState', { prop: 'stepSelected', data: index });
                let path = this.locale === 'es' ? this.steps[index].alias : this.steps[index].path;
                path = path.replace(':brand', this.selected.brand?.slug);
                path = path.replace(':model', this.selected.model?.slug);
                this.$router
                    .push(`/${this.locale}/wizard/${path}`)
                    .catch(() => {});
            }
        },

        getValue({ name }) {
            switch (name) {
                case 'brand':
                case 'model':
                    return this.selected[name]?.name;
                case 'device':
                    return this.trans.steps[1].devices[this.selected.device?.slug];
                case 'damages':
                case 'extras':
                    return this.selected[name]?.map(d => d.name[this.locale]).join(' - ');
                case 'services':
                    if (this.selected.postalCode?.length === 5) {
                        return this.selected.postalCode;
                    }
                    return '';
                case 'calendar':
                    return this.selected.date ?
                        this.formatDate(this.selected.date) :
                        '';
                default:
                    return '';
            }
        },
        trimValue(value) {
            let maxChars;
            switch (true) {
                case window.matchMedia('(min-width: 1680px)').matches:
                    maxChars = 40;
                    break;
                default:
                    maxChars = 30;
            }
            return value?.length > maxChars ? value.slice(0, maxChars) + '...' : value;
        },
        widthLine() {
            let value = this.stepSelected * (100 / 9);
            return value + "%";
        },
        formatDate(date) {
            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const year = date.getFullYear();
            return `${day}.${month}.${year}`;
        }
    },
    created() {
        eventBus.$on("routechanged", route => {
            const index = this.steps.findIndex(s => s.name === route.name);
            this.$store.commit('setState', { prop: 'stepSelected', data: index });
            if (index > this.maxIndex) {
                this.$store.commit('setMaxIndex', index);
            }
        });
    }
};
</script>

<style scoped lang="scss">
.line {
    height: 6px;
    top: 5px;
    left: 6.5%;
    right: 5.5%;
    transform: rotate(-360deg);
    border-radius: 5px;
    box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    z-index: 10;
    @media (min-width: 1024px) {
        background-size: 8px 1px;
        box-shadow: 0 0 13px 0 rgba(17, 17, 95, 0.13);
        top: 26px;
    }
}
.line-active {
    height: 6px;
    top: 5px;
    left: 6.5%;
    right: 6.5%;
    transform: rotate(-360deg);
    border-radius: 22.9px;
    background-color: #3c3cfa;
    z-index: 19;
    transition: width 1s;
    position: relative;
    @media (min-width: 1024px) {
        top: 26px;
    }
}
.container {
    position: absolute;
    &.active {
        @media (max-width: 1023px) {
            top: -45px;
        }
    }
}
.wizard-steps {
    width: 90%;
    max-width: 1400px;

    .step {
        z-index: 20;
        width: 12.5%;
        color: #535b6c;
        .circle {
            width: 16px;
            height: 16px;
            border-radius: 30px;
            box-shadow: 0 7px 14px -2px rgba(17, 17, 95, 0.5);
            background-color: #ffffff;
            @media (min-width: 1024px) {
                width: 18px;
                height: 18px;
                top: 20px;
            }

            &.active-later {
                background-color: #3c3cfa !important;
            }
            &.active {
                width: 32px;
                top: 37px;
                height: 32px;
                border-radius: 15.8px;
                box-shadow: 0 7px 14px -5px rgba(17, 17, 95, 0.22);
                border: solid 2.1px #3c3cfa;
                background-color: #ffffff;
                @media (min-width: 1024px) {
                    top: 14px;
                }
                .number {
                    width: 16px;
                    height: 16px;
                    border-radius: 8.5px;
                    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.13);
                    background-color: #3c3cfa;
                }
            }
        }

        .step-name {
            font-size: 16px;
            font-weight: bold;
            color: #808087;
            position: absolute;
            top:-65px;

            @media (max-width: 1023px) {
                margin-bottom: 5px;
                top:-5px;
            }

            &.active {
                color: #3c3cfa;
                display: inline !important;
            }

            &#step-name-8 {
                left: -300%;
                right: -200%;

                @media (min-width: 640px) {
                    right: -300%
                }
            }
            &#step-name-9 {
                @media (max-width: 599px) {
                    left: -150%;
                    right: -25%;
                }
            }
        }

        #step-name-7 {
            @media (max-width: 630px) {
                top: -33px !important;
            }
        }
        .step-value {
            font-weight: 500;
            font-size: 12px;
            color: #000000;
            position: absolute;
            top: -28px;
            height: 38px;
            overflow: hidden;
            width: 100%;
        }
        &.disabled {
            color: #818fa6;
            .circle {
                border-color: #818fa6;
            }
        }
    }
    @for $i from 0 through 8 {
        #step-#{$i + 1} {
            left: calc(100% / 9 * #{$i});
        }
    }
}

.displaynone {
    @media(max-width: 1023px) {
        visibility: hidden !important;
    }
}
</style>
