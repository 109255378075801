<template>
  <div class="items-center">
    <button
      v-if="!loading"
      @click="comfirmAction()"
      class="mdc-button mdc-button--unelevated mt-12 next-button w-full"
      :class="{ disabled: buttonDisabled }"
      :disabled="buttonDisabled"
    >
      <div class="mdc-button__ripple"></div>
      <span class="font-bold">{{ trans.steps[7].confirm }}</span>
    </button>

    <button v-else class="mdc-button mdc-button--unelevated mt-12 next-button w-full">
      <div class="mdc-button__ripple"></div>
      <clip-loader class="flex items-center" color="#ffffff"></clip-loader>
    </button>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { saveAppointment } from "../../../utils";

export default {
  props: ["buttonDisabled", "loading"],
  components: {
    ClipLoader,
  },
  computed: {
    ...mapGetters({
      trans: "getTrans",
      wizard: "getSelected",
    }),
  },
  methods: {
    ...mapMutations({
      setAppointmentId: "setAppointmentId",
      setLoading: "setLoading",
    }),
    async comfirmAction() {
      const appointment_id = await saveAppointment(this.wizard);
      this.setAppointmentId(appointment_id);
      this.$nextTick(() => {
        this.setLoading(false);
        this.$router.push(this.$store.getters.getNextRoute(7)).catch((e) => e);
      });
    },
  },
};
</script>

<style>
@media(max-width: 1024px) {
  .mdc-button {
    margin-bottom: 3rem;
  }
}
</style>