<template>
    <div class="models-component">
        <h1>{{ trans.steps[2].title }}</h1>
        <div class="cards">
            <button :class="{ selected : isSelected(model) }" @click="select(model)" v-for="model in models" :key="model.id" class="card flex flex-col items-center justify-center font-bold">
                {{ model.name }}
            </button>
            <button class="card p-0" v-if="! models.length" v-for="index in 10">
                <Skeleton :duration="0.75" :loading="true" class="skeleton" width="170" height="70px"></Skeleton>
            </button>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { Skeleton } from "vue-loading-skeleton";

export default {
    components: {
        Skeleton
    },
    computed: {
        ...mapGetters({
            models: 'getModels',
            trans: 'getTrans',
            selected: 'getSelected'
        }),
        selectedModel() {
            return this.selected.model;
        }
    },
    watch: {
        selectedModel(newVal, oldVal) {
            newVal = newVal?.id;
            oldVal = oldVal?.id;

            if (newVal && oldVal && newVal !== oldVal) {
                this.resetSelected(['damages', 'screen']);
                this.resetData(['damages']);
                this.setScreensShown(false);
                this.setMaxIndex(3);
            }
        }
    },
    methods: {
        ...mapMutations(['setSelected', 'resetSelected', 'resetData', 'setMaxIndex', 'setScreensShown']),
        select(model) {
            this.setSelected({ prop: 'model', data: model });
            this.$store.dispatch('retrieveDamages', model.id);
            this.$store.dispatch('retrieveScreens');
            this.$nextTick(() => {
                this.$router.push(this.$store.getters.getNextRoute(3)).catch(e => e);
            });
        },
        isSelected(model) {
            return this.selected.model?.id === model.id;
        }
    }
};
</script>

<style scoped lang="scss">
.card {
    width: 150px;
    height: 60px;
    border-radius: 35px !important;
    padding-left: 20px;
    padding-right: 20px;
    @media (min-width: 640px) {
        min-width: 170px;
        height: 70px;
         padding-left: 31px;
    padding-right: 31px;
    }
}
</style>
