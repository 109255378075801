<template>
    <div class="flex justify-center">
        <ValidationObserver v-slot="{ invalid }">
            <form @submit.prevent="onSubmit" class="w-full">
                <div class="wizard-form">
                    <div class="wizard-article">
                        <div class="article-form">
                            <h1 class="title-form">
                                {{ trans.steps[7].coupon }}
                            </h1>
                            <coupon-component></coupon-component>
                        </div>
                        <div class="article-form">
                            <h1 class="title-form mt-10">{{ trans.steps[7]['payment-info'] }}</h1>
                            <ValidationProvider tag="div" name="paymentMethod" rules="required">
                                <div class="flex items-center space-x-4">
                                    <input v-model="paymentMethod" type="radio" id="online" value="online" checked>
                                    <label class="font-semibold cursor-pointer" for="online">{{ trans.steps[7]['payment-via-platform'] }}</label>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider name="cardName" rules="required" v-slot="{ errors }" v-if="selected.paymentMethod === 'online'">
                                <div class="flex flex-col mt-4" >
                                    <input v-model="selected.cardName" class="outline-none rounded-lg appearance-none block w-full py-3 px-4 my-3" type="text" :placeholder="trans.steps[7]['card-name'] + '*'"/>
                                    <span class="text-red-500">{{ errors[0] }}</span>
                                </div>
                            </ValidationProvider>
                            <stripe v-if="selected.paymentMethod === 'online'" @stripe-invalid="stripeInvalid = $event"></stripe>
                            <div  class="required-fields-note text-left mb-10 ml-5" v-if="selected.paymentMethod === 'online'">
                                *{{ trans.steps[7]['required-fields'] }}
                            </div>
                        </div>
                        <div v-show="selected.paymentMethod === 'online'" class="accepted-cards flex-col items-center mb-10">
                            <span class="font-bold">{{ trans.steps[7]['accepted-cards'] }}</span>
                            <div class="mt-3 flex space-x-3">
                                <img src="/img/icons/visa.svg" alt="Visa">
                                <img src="/img/icons/mastercard.svg" alt="Mastercard">
                                <img src="/img/icons/maestro.png" alt="Maestro">
                                <img src="/img/icons/american-express.png" alt="American Express">
                            </div>
                        </div>
                        <div v-show="selected.paymentMethod === 'online'" class="remember-payment-note mb-8">
                            {{ trans.steps[7]['remember-payment'] }}
                        </div>
                        <div v-if="!showPaymentMethodSelector" class="flex items-center space-x-4 my-5 check-refund">
                            <input v-model="paymentMethod" type="radio" id="cash" value="cash">
                            <label class="font-semibold cursor-pointer" for="cash">{{ trans.steps[7]['payment-on-cash'] }}</label>
                        </div>
                        <div v-if="showPaymentMethodSelector" class="flex items-center space-x-4 my-5 check-refund">
                            <input v-model="paymentMethod" type="radio" id="delivery" value="delivery">
                            <label class="font-semibold cursor-pointer" for="delivery">{{ trans.steps[7]['payment-on-delivery'] }}</label>
                        </div>
                        
                        <payment-button v-if="isMobile" :button-disabled="buttonIsDisabled(invalid)" :loading="loading"></payment-button>
                        <payment-button v-if="!isMobile" :button-disabled="buttonIsDisabled(invalid)" :loading="loading" class="mt-5"></payment-button>
                    </div>
                    <div class="wizard-article">
                        <resume-component :selected="selected" :damages="damages" :trans="trans" :locale="locale" :select-date="selectDate">
                            <tr>
                                <td class="title px-4 py-2">{{trans.steps[7]['step']}}:</td>
                                <td class="price px-4 py-2">{{selected.paymentMethod && payment_types[selected.paymentMethod][locale]}}</td>
                            </tr>
                        </resume-component>                    
                    </div>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import {
    ValidationProvider,
    ValidationObserver,
    extend,
    localize
} from "vee-validate";
import es from "vee-validate/dist/locale/es.json";
import * as rules from "vee-validate/dist/rules";
import { mapGetters,mapMutations  } from "vuex";
import axios from "axios";
import Stripe from "./Stripe.vue";
import Coupon from "./Coupon.vue";
import { prepareWizard } from "../../utils";
import paymentButton from './paymentBtn/paymentButton.vue';
import Resume from "./Resume.vue";

// install rules and localization
Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule]);
});

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        Stripe,
        CouponComponent: Coupon,
        paymentButton,
        ResumeComponent: Resume
    },
    data: () => ({
        invalid: true,
        stripeInvalid: true,
        payment_types: {
            online: {
                en: 'Online',
                es: 'Online'
            },
            delivery: {
                en: 'On delivery',
                es: 'Con entrega'
            },
            cash: {
                en: 'Cash on delivery (+5€)',
                es: 'Contrareembolso (+5€)'
            }
        }
    }),
    computed: {
        ...mapGetters({
            selected: "getSelected",
            trans: "getTrans",
            locale: "getLocale",
            carderror: "getCardError",
            priceToDiscount: "getPriceToDiscount",
            validDiscountCode: "getValidDiscountCode",
            isApplicable: "getDiscountCodeIsApplicable",
            appointment_id: "getAppointmentId"
        }),
        damages(){
            return this.selected.damages.map(damage => ({
                ...damage,
            }));
        },
        price(){
            return this.$store.getters.getTotalPrice
        },
        selectDate()
        {
            const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            const lang = this.locale;
            const fecha = this.selected.date.toLocaleDateString(lang, options);
            return fecha;
        },
        loading() {
            return this.$store.state.loading;
        },
        paymentMethod: {
            get() {
                return this.selected.paymentMethod;
            },
            set(data) {
                this.setSelected({ prop: 'paymentMethod', data });
            }
        },
        showPaymentMethodSelector() {
            return this.selected.service.show_full_calendar;
        },
        isMobile(){
            return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        },
    },
    methods: {
        ...mapMutations(['setSelected', 'setLoading']),
        async onSubmit(event) {
            event.preventDefault();
            event.stopPropagation();
            this.setLoading(true);
            if (this.selected.paymentMethod === 'delivery' || this.selected.paymentMethod === 'cash') {
                try {
                    await axios.post('/api/appointments', {
                        wizard: {
                            ...prepareWizard(this.selected),
                            locale: this.locale,
                        },
                        appointment_id: this.appointment_id
                    });
                    this.$router.replace(store.getters.getNextRoute(8));
                } catch (e) {
                    Vue.$toast.error(this.trans.steps[7].error, { position: "bottom" });
                } finally {
                    this.setLoading(false);
                }
            }
        },
        buttonIsDisabled(invalid) {
            if (! this.selected.paymentMethod) return true;
            if (this.selected.paymentMethod === 'online') {
                return invalid || this.stripeInvalid;
            }
            return invalid;
        }
    },

    created() {
        const names = {
            name: this.trans.steps[7].name,
            email: this.trans.steps[7].email,
            phone: this.trans.steps[7].phone,
            addressName: this.trans.steps[7].name,
            address: this.trans.steps[7].address,
            addressPostal: this.trans.steps[7]['postal-code'],
            addressCity: this.trans.steps[7].city,
            cardName: this.trans.steps[7]['card-name']
        };
        localize({
            en: { names },
            es: { names }
        });
        if (this.locale === 'es') {
            localize("es", es);
        }
    }
};
</script>

<style scoped lang="scss">
.disabled {
    background-color: #d5d5da!important;
}
.accepted-cards {
    display: flex;
}
.check-refund {
    padding: 1rem 0rem;
    border-top: 1px solid #d5d5da;
    border-bottom: 1px solid #d5d5da;
}
</style>
