<template>
<svg class="software-icon" viewBox="0 0 33 60" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="wizard-4.2-1920" transform="translate(-455.000000, -921.000000)">
            <g id="software" transform="translate(455.880151, 921.682870)">
                <g id="icon-software2-copy-2">
                    <path d="M7.41671931,0 L24.4167193,0 L23.0106376,2.87795689 C22.6751848,3.56455893 21.9778092,4 21.2136424,4 L10.4083786,4 C9.59910518,4 8.86959008,3.51230068 8.56025293,2.76448118 L7.41671931,0 L7.41671931,0 Z" id="Rectangle" fill="#979797" fill-rule="nonzero"></path>
                    <path d="M0.5,10 L1,10 L1,10 L1,15 L0.5,15 C0.223857625,15 0,14.7761424 0,14.5 L0,10.5 C0,10.2238576 0.223857625,10 0.5,10 Z" id="Rectangle" fill="#979797" fill-rule="nonzero"></path>
                    <path d="M31.3334394,12 L31.8334394,12 L31.8334394,12 L31.8334394,17 L31.3334394,17 C31.057297,17 30.8334394,16.7761424 30.8334394,16.5 L30.8334394,12.5 C30.8334394,12.2238576 31.057297,12 31.3334394,12 Z" id="Rectangle-Copy-3" fill="#979797" fill-rule="nonzero" transform="translate(31.333439, 14.500000) scale(-1, 1) translate(-31.333439, -14.500000) "></path>
                    <path d="M0.5,15.8560838 L1,15.8560838 L1,15.8560838 L1,20.8560838 L0.5,20.8560838 C0.223857625,20.8560838 0,20.6322262 0,20.3560838 L0,16.3560838 C0,16.0799414 0.223857625,15.8560838 0.5,15.8560838 Z" id="Rectangle-Copy" fill="#979797" fill-rule="nonzero"></path>
                    <rect id="Rectangle" stroke="#979797" stroke-width="1.6" x="1.8" y="0.8" width="28.2334386" height="57.4" rx="4"></rect>
                </g>
                <g id="candado-(1)-copy" transform="translate(7.182385, 17.425320)" fill="#979797" fill-rule="nonzero" stroke="#979797" stroke-width="0.3">
                    <path d="M10.3778269,13.9846562 C10.3778269,14.619718 10.0155313,15.1714046 9.48693454,15.4449794 L9.48693454,18.3180983 C9.48693454,18.7150287 9.16515853,19.0368497 8.76818314,19.0368497 C8.37120776,19.0368497 8.04943174,18.7150287 8.04943174,18.3180983 L8.04943174,15.4780869 C7.48431345,15.2178989 7.09079705,14.6465364 7.09079705,13.9846562 C7.09079705,13.0784455 7.8280563,12.3411413 8.73431198,12.3411413 C9.64056766,12.3411413 10.3778269,13.0784455 10.3778269,13.9846562 L10.3778269,13.9846562 Z M17.4686689,10.3107135 L17.4686689,21.0673224 C17.4686689,22.1330061 16.601675,23 15.5359913,23 L1.9326776,23 C0.866948959,23 0,22.1330061 0,21.0673224 L0,10.3107135 C0,9.24502978 0.866993881,8.37803589 1.9326776,8.37803589 L12.7077944,8.37803589 L12.7077944,5.24710986 C12.7077045,3.14646904 10.925201,1.43750281 8.73431198,1.43750281 C6.54337801,1.43750281 4.76082961,3.14646904 4.76073977,5.24706494 L4.76073977,6.12448071 C4.76073977,6.52141117 4.43896375,6.84323212 4.04198836,6.84323212 C3.64501298,6.84323212 3.32323696,6.52141117 3.32323696,6.12448071 L3.32323696,5.24706494 C3.32337173,2.353821 5.75072998,0 8.73431198,0 C11.7178491,0 14.1451624,2.353821 14.1452972,5.24702001 L14.1452972,8.37794605 L15.5359464,8.37794605 C16.601675,8.37799097 17.4686689,9.24502978 17.4686689,10.3107135 Z M16.0311661,10.3107135 C16.0311661,10.0422598 15.8044,9.8155387 15.5359913,9.8155387 L1.9326776,9.8155387 C1.66422395,9.8155387 1.43750281,10.0423048 1.43750281,10.3107135 L1.43750281,21.0673224 C1.43750281,21.335776 1.66426888,21.5624972 1.9326776,21.5624972 L15.5359464,21.5624972 C15.8044,21.5624972 16.0311661,21.3357311 16.0311661,21.0673224 L16.0311661,10.3107135 L16.0311661,10.3107135 Z" id="Shape"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
</template>

<script>
export default {};
</script>
