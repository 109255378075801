import screenProtector from './screen-protector';
import transparentCase from './transparent-case';
import wiredCharger from './wired-charger';
import appleAirpods from './apple-airpods';
import appleHeadphonesNormal from './apple-headphones-normal';
import appleHeadphonesLighting from './apple-headphones-lighting';
import appleAirpodsPro from './apple-airpods-pro';

export default {
    screenProtector,
    transparentCase,
    wiredCharger,
    appleAirpods,
    appleHeadphonesNormal,
    appleHeadphonesLighting,
    appleAirpodsPro
};
