import actions  from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export const shopState = {
    shopLoading: true,
    products: [],
    accessoryTypes: [],
    products_filtered: [],
    category: '',
    filter: {
        all: true,
        brands: [],
        brandfilter: [],
        typefilter: 'all',
        type_accessories: '',
    },
};

export const shopGetters = {
    ...getters
};

export const shopMutations = {
    ...mutations
};

export const shopActions = {
    ...actions
};
