<template>
<svg xmlns="http://www.w3.org/2000/svg" width="79" height="76" viewBox="0 0 79 76">
    <g fill="#AEAEB3">
        <path d="M8.54 18.72c.928.004 1.767-.552 2.124-1.409.357-.857.161-1.844-.495-2.5-.656-.656-1.643-.852-2.5-.495S6.256 15.512 6.26 16.44c.005 1.257 1.023 2.275 2.28 2.28zM29.48 26c.933 0 1.69-.757 1.69-1.69 0-.933-.757-1.69-1.69-1.69-.933 0-1.69.757-1.69 1.69 0 .933.757 1.69 1.69 1.69zM25.1 11.73c.225.226.531.353.85.353.319 0 .625-.127.85-.353l.09-.09c.464-.468.464-1.222 0-1.69l-4.07-4.09c-.473-.46-1.227-.46-1.7 0L21 6c-.459.47-.459 1.22 0 1.69l4.1 4.04z"/>
        <path d="M15.69.68h-.19c-1.18 0-7.46.39-14.82 8.1L.45 9v1l-.08.08h.08v13l.15.2c.08.13 8.48 12.11 21.87 5.92v43.41c-.01.917.31 1.808.9 2.51.46.476 1.1.734 1.76.71h6.62c.658.017 1.293-.236 1.76-.7.595-.7.915-1.592.9-2.51V22.79c-.132-3.811-.949-7.568-2.41-11.09C29.85 6.68 25.27.69 15.69.68zM32.32 74c-.152.147-.359.224-.57.21h-6.62c-.213.015-.422-.066-.57-.22-.1-.103-.179-.226-.23-.36h8.22c-.057.134-.135.26-.23.37zM2.67 9.15c7.15-7.06 12.9-6.8 13-6.8 7.05 0 12.06 3.4 14.89 10.11 1.33 3.288 2.072 6.785 2.19 10.33v48.09h-8.6V26.44l-1.22.66C12 32.91 4.71 25.66 2.62 23.16c.3-.21.479-.554.48-.92V10c0-.335-.16-.65-.43-.85zM70 18.72c.928.004 1.767-.552 2.124-1.409.357-.857.161-1.844-.495-2.5-.656-.656-1.643-.852-2.5-.495s-1.413 1.196-1.409 2.124c.005 1.257 1.023 2.275 2.28 2.28zM49 22.58c-.685-.004-1.305.406-1.57 1.039-.264.632-.12 1.361.365 1.846.485.485 1.214.629 1.846.364.633-.264 1.043-.884 1.039-1.569-.005-.926-.754-1.675-1.68-1.68zM57.84 6.81c-.004-.306-.13-.597-.35-.81l-.1-.1c-.474-.448-1.216-.448-1.69 0l-4.08 4.08c-.459.47-.459 1.22 0 1.69l.1.1c.226.225.531.35.85.35.318-.001.623-.127.85-.35l4.07-4.08c.238-.23.365-.55.35-.88z"/>
        <path d="M78.06 10V9l-.23-.24c-8.18-8.58-15-8.1-15-8.1-18.54 0-18.75 21.89-18.75 22.11v49.84c-.007.92.32 1.812.92 2.51.463.47 1.1.728 1.76.71h6.63c.66.019 1.298-.239 1.76-.71.584-.701.9-1.587.89-2.5V29.18c2.366 1.154 4.957 1.776 7.59 1.82 9 0 14.24-7.61 14.28-7.68l.15-.21v-13h.1l-.1-.11zM54 74c-.154.144-.36.216-.57.2h-6.67c-.207.017-.41-.056-.56-.2-.1-.109-.181-.234-.24-.37h8.23c-.045.132-.109.257-.19.37zm.41-47.52v44.4h-8.63V22.79c.128-3.54.88-7.03 2.22-10.31 2.78-6.72 7.81-10.13 15-10.13.63 0 6.19.2 12.88 6.79-.265.21-.42.531-.42.87v12.23c-.004.363.172.704.47.91-1.42 1.662-3.11 3.072-5 4.17C66.14 30 61 30 55.6 27.09l-1.19-.61z"/>
    </g>
</svg>


</template>

<script>
export default {

}
</script>

<style>

</style>