<template>
    <div v-show="!hidden" class="circle">
        <span class="item-count">{{itemCount}}</span>
    </div>
</template>

<script>
import { retrieveCartItems } from "../../app/cart.js";
import { mapGetters } from "vuex";
import { GET_CART_COUNT } from "../../store/cart/types";

export default {
    props: {
        hidden:{
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters({
            itemCount: GET_CART_COUNT
        })
    },
    mounted() {
        retrieveCartItems();
    }
};
</script>
<style lang="scss" scoped>
@import "../../../sass/_variables.scss";
.circle {
    width: 20px;
    height: 20px;
    position: absolute;
    top: -10px;
    right: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: $primary;

    .item-count {
        color: $white;
        text-align: center;
        font-size: 0.7rem;
    }
}
</style>
