<template>
    <svg width="74px" height="74px" viewBox="0 0 74 74" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>Artboard Copy 2</title>
        <g id="Artboard-Copy-2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Group-12" transform="translate(3.000000, 3.000000)" fill="#AEAEB3">
                <g id="Group-3" transform="translate(0.500000, 0.000000)">
                    <path d="M15.9996836,2.65063582 C8.60741493,2.65063582 2.59353433,8.66451642 2.59353433,16.0567851 L2.59353433,212.681412 C2.59353433,220.072576 8.60741493,226.085352 15.9996836,226.085352 L102.883415,226.085352 C110.274579,226.085352 116.28846,220.072576 116.28846,212.681412 L116.28846,16.0567851 C116.28846,8.66451642 110.274579,2.65063582 102.883415,2.65063582 L15.9996836,2.65063582 Z M102.883415,228.626755 L15.9996836,228.626755 C7.20693731,228.626755 0.0532358209,221.474158 0.0532358209,212.681412 L0.0532358209,16.0567851 C0.0532358209,7.26403881 7.20693731,0.110337313 15.9996836,0.110337313 L102.883415,0.110337313 C111.676161,0.110337313 118.829863,7.26403881 118.829863,16.0567851 L118.829863,212.681412 C118.829863,221.474158 111.676161,228.626755 102.883415,228.626755 L102.883415,228.626755 Z" id="Fill-1"></path>
                </g>
                <path d="M19.8228924,14.5489177 C16.6413281,14.5489177 14.0523537,17.2507706 14.0523537,20.5721212 L14.0523537,35.4267706 C14.0523537,38.7481212 16.6413281,41.449974 19.8228924,41.449974 L34.1759979,41.449974 C37.3586719,41.449974 39.9465366,38.7481212 39.9465366,35.4267706 L39.9465366,20.5721212 C39.9465366,17.2507706 37.3586719,14.5489177 34.1759979,14.5489177 L19.8228924,14.5489177 Z M34.1759979,44 L19.8228924,44 C15.2342044,44 11.5,40.1533506 11.5,35.4267706 L11.5,20.5721212 C11.5,15.8455411 15.2342044,12 19.8228924,12 L34.1759979,12 C38.7646859,12 42.5,15.8455411 42.5,20.5721212 L42.5,35.4267706 C42.5,40.1533506 38.7646859,44 34.1759979,44 L34.1759979,44 Z" id="Fill-4"></path>
                <path d="M21,18.5442478 C19.369469,18.5442478 18.0442478,19.8705752 18.0442478,21.5 C18.0442478,23.130531 19.369469,24.4557522 21,24.4557522 C22.630531,24.4557522 23.9557522,23.130531 23.9557522,21.5 C23.9557522,19.8705752 22.630531,18.5442478 21,18.5442478 M21,27 C17.9668142,27 15.5,24.5331858 15.5,21.5 C15.5,18.4679204 17.9668142,16 21,16 C24.0331858,16 26.5,18.4679204 26.5,21.5 C26.5,24.5331858 24.0331858,27 21,27" id="Fill-6"></path>
                <path d="M21,31.543992 C19.369469,31.543992 18.0442478,32.870186 18.0442478,34.500553 C18.0442478,36.129814 19.369469,37.454902 21,37.454902 C22.630531,37.454902 23.9557522,36.129814 23.9557522,34.500553 C23.9557522,32.870186 22.630531,31.543992 21,31.543992 M21,40 C17.9668142,40 15.5,37.5323278 15.5,34.500553 C15.5,31.4676722 17.9668142,29 21,29 C24.0331858,29 26.5,31.4676722 26.5,34.500553 C26.5,37.5323278 24.0331858,40 21,40" id="Fill-8"></path>
                <path d="M32.999447,25.543992 C31.3690799,25.543992 30.043992,26.870186 30.043992,28.499447 C30.043992,30.129814 31.3690799,31.456008 32.999447,31.456008 C34.629814,31.456008 35.954902,30.129814 35.954902,28.499447 C35.954902,26.870186 34.629814,25.543992 32.999447,25.543992 M32.999447,34 C29.9665661,34 27.5,31.5323278 27.5,28.499447 C27.5,25.4676722 29.9665661,23 32.999447,23 C36.0323278,23 38.5,25.4676722 38.5,28.499447 C38.5,31.5323278 36.0323278,34 32.999447,34" id="Fill-10"></path>
            </g>
        </g>
    </svg>
</template>

<script>
export default {};
</script>
