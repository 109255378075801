<template>
  <div class="cart-item">
    <div class="cart-item__image-container">
      <img :src="item.attributes['image']" :alt="item.name[locale]" />
    </div>
    <div class="cart-item__info">
      <div class="name-content">
        <span class="font-bold">{{ item.name[locale] }}</span>
        <div class="units">
          <span class="units__text">{{ units[lang] }}</span>
          <div id="input_div">
            <div class="flex items-center ml-3">
              <!-- actualizar paquete composer rica cart-->
              <input
                class="button mr-1 plus-minus"
                type="button"
                value="-"
                v-on:click="minus(item.id)"
              />
              <input
                class="text-center quantity"
                type="text"
                size="2"
                @input="editQuantity(item.id, $event)"
                :value="item.quantity"
                id="count"
              />
              <input
                class="button ml-1 plus-minus"
                type="button"
                value="+"
                v-on:click="plus(item.id)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="price-content">
        <button v-on:click="deleteItem(item.id)" class="mdc-button">
          <span class="mdc-button__ripple"></span>
          <span class="mdc-button__label">
            <svg
              width="21px"
              height="24px"
              viewBox="0 0 21 24"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <!-- Generator: Sketch 52.5 (67469) - http://www.bohemiancoding.com/sketch -->
              <title>garbage</title>
              <desc>Created with Sketch.</desc>
              <g
                id="Page-1"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g
                  id="admiin-5"
                  transform="translate(-1246.000000, -365.000000)"
                  fill="#000000"
                >
                  <g
                    id="garbage"
                    transform="translate(1246.079125, 365.292758)"
                  >
                    <path
                      d="M19.9143897,3.31906496 L15.1159701,3.31906496 L15.1159701,2.53671393 C15.1159701,1.13796513 13.978005,0 12.5792562,0 L8.01791263,0 C6.61916383,0 5.4811987,1.13796513 5.4811987,2.53671393 L5.4811987,3.31906496 L0.682779077,3.31906496 C0.327164974,3.31906496 0.0426736923,3.60355624 0.0426736923,3.95917034 C0.0426736923,4.31478444 0.327164974,4.59927572 0.682779077,4.59927572 L1.83971029,4.59927572 L1.83971029,19.6393815 C1.83971029,21.526507 3.37596321,23.0627599 5.26308872,23.0627599 L15.3340801,23.0627599 C17.2212056,23.0627599 18.7574585,21.526507 18.7574585,19.6393815 L18.7574585,4.59927572 L19.9143897,4.59927572 C20.2700038,4.59927572 20.5544951,4.31478444 20.5544951,3.95917034 C20.5544951,3.60355624 20.2700038,3.31906496 19.9143897,3.31906496 Z M6.76140947,2.53671393 C6.76140947,1.84445181 7.32565051,1.28021077 8.01791263,1.28021077 L12.5792562,1.28021077 C13.2715183,1.28021077 13.8357593,1.84445181 13.8357593,2.53671393 L13.8357593,3.31906496 L6.76140947,3.31906496 L6.76140947,2.53671393 Z M17.4772478,19.6393815 C17.4772478,20.8200203 16.5147189,21.7825492 15.3340801,21.7825492 L5.26308872,21.7825492 C4.0824499,21.7825492 3.11992106,20.8200203 3.11992106,19.6393815 L3.11992106,4.59927572 L17.4819893,4.59927572 L17.4819893,19.6393815 L17.4772478,19.6393815 Z"
                      id="Shape"
                      fill-rule="nonzero"
                    ></path>
                    <path
                      d="M10.2985844,19.4876528 C10.6541985,19.4876528 10.9386898,19.2031615 10.9386898,18.8475474 L10.9386898,7.53427745 C10.9386898,7.17866335 10.6541985,6.89417207 10.2985844,6.89417207 C9.9429703,6.89417207 9.65847902,7.17866335 9.65847902,7.53427745 L9.65847902,18.8428059 C9.65847902,19.19842 9.9429703,19.4876528 10.2985844,19.4876528 Z"
                      id="Path"
                    ></path>
                    <path
                      d="M6.12130408,18.7811661 C6.47691819,18.7811661 6.76140947,18.4966748 6.76140947,18.1410607 L6.76140947,8.23602261 C6.76140947,7.88040851 6.47691819,7.59591723 6.12130408,7.59591723 C5.76568998,7.59591723 5.4811987,7.88040851 5.4811987,8.23602261 L5.4811987,18.1410607 C5.4811987,18.4966748 5.7704315,18.7811661 6.12130408,18.7811661 Z"
                      id="Path"
                    ></path>
                    <path
                      d="M14.4758647,18.7811661 C14.8314788,18.7811661 15.1159701,18.4966748 15.1159701,18.1410607 L15.1159701,8.23602261 C15.1159701,7.88040851 14.8314788,7.59591723 14.4758647,7.59591723 C14.1202506,7.59591723 13.8357593,7.88040851 13.8357593,8.23602261 L13.8357593,18.1410607 C13.8357593,18.4966748 14.1202506,18.7811661 14.4758647,18.7811661 Z"
                      id="Path"
                    ></path>
                  </g>
                </g>
              </g>
            </svg>
          </span>
        </button>
        <span class="">{{ item.price | currency}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { MDCRipple } from "@material/ripple";
import { mapGetters } from 'vuex';

import * as actions from "../../app/cart";
import mixin from "../../mixin";

export default {
  mixins: [mixin],
  props: {
    item: {
      type: Object,
      default: {},
    },
  },
  data: () => {
    return {
      units: {
        es: "Unidades",
        en: "Units",
      },
      toastMessage: {
        es: "Producto eliminado correctamente",
        en: "Product removed successfully",
      },
    };
  },
  computed: {
    ...mapGetters({
        locale: "getLocale",
    }),
  },
  methods: {
    plus(id) {
      actions.increaseCartItem(id);
    },
    minus(id) {
      actions.decreaseCartItem(id);
    },
    editQuantity(id, e) {
      actions.updateCartItem(id, parseInt(e.target.value));
    },
    deleteItem(id) {
      actions.deleteCartItem(id, this.toastMessage[this.lang]);
    },
  },
  mounted() {
    document
      .querySelectorAll(".mdc-button")
      .forEach((element) => new MDCRipple(element));
  },
};
</script>

<style lang="scss" scoped>
@import url("https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.min.css");

.cart-item {
  animation-duration: 0.3s;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  min-height: 132px;
  margin-bottom: 1rem;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 0 11px -1px rgb(0 0 0 / 19%);
  background-color: white;

  &__image-container {
    width: 120px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0.6rem;
    .name-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .units {
        display: flex;
        align-items: center;
        &__text {
          @media (max-width: 578px) {
            display: none;
          }
        }
        .plus-minus {
          background-color: transparent;
          font-size: 1.5rem;
          &:hover {
            cursor: pointer;
          }
        }
        .quantity {
          border: 1px solid black;
          width: 38px;
          height: 27px;
          font-weight: bold;
        }
      }
    }
    .price-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;

      .mdc-button{
        max-width: 64px;
      }
    }
  }
}
</style>
