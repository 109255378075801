<template>
    <svg width="99px" height="130px" viewBox="0 0 99 130" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <!-- Generator: sketchtool 63.1 (101010) - https://sketch.com -->
        <title>8FD3CCB1-36E0-43F4-B254-CA850A400C64</title>
        <desc>Created with sketchtool.</desc>
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(-1107.000000, -371.000000)" fill="#AEAEB3" fill-rule="nonzero" stroke="#FFFFFF" stroke-width="2.41403026">
                <path d="M1190.88316,372.468363 C1198.15217,372.468363 1204.07084,378.374489 1204.07084,385.628099 L1204.07084,385.628099 L1204.07084,485.642878 C1204.07084,492.896487 1198.15217,498.802613 1190.88292,498.802613 L1190.88292,498.802613 L1122.30666,498.802613 C1115.03765,498.802613 1109.11898,492.896487 1109.11898,485.642631 L1109.11898,485.642631 L1109.11898,385.628099 C1109.11898,378.374489 1115.03765,372.468363 1122.30666,372.468363 L1122.30666,372.468363 Z M1190.88292,377.732208 L1122.30666,377.732208 C1117.94406,377.732208 1114.394,381.274749 1114.394,385.628099 L1114.394,385.628099 L1114.394,485.642878 C1114.394,489.996228 1117.94406,493.538769 1122.30666,493.538769 L1122.30666,493.538769 L1190.88316,493.538769 C1195.24576,493.538769 1198.79582,489.996228 1198.79582,485.642878 L1198.79582,485.642878 L1198.79582,385.628099 C1198.79582,381.274749 1195.24551,377.732208 1190.88292,377.732208 L1190.88292,377.732208 Z M1156.59491,480.531131 C1159.47481,480.531131 1161.81205,482.870026 1161.81205,485.751677 C1161.81205,488.633328 1159.47481,490.971978 1156.59491,490.971978 C1153.71501,490.971978 1151.37778,488.633084 1151.37778,485.751432 C1151.37778,482.869781 1153.71501,480.531131 1156.59491,480.531131 Z M1189.96611,382.90921 C1191.41632,382.90921 1192.59314,384.091413 1192.59314,385.548259 L1192.59314,385.548259 L1192.59314,475.281623 C1192.59314,476.738469 1191.41608,477.920919 1189.96586,477.920919 L1189.96586,477.920919 L1121.65882,477.920919 C1120.20861,477.920919 1119.03154,476.738469 1119.03154,475.281623 L1119.03154,475.281623 L1119.03154,385.548507 C1119.03154,384.091661 1120.20861,382.90921 1121.65882,382.90921 L1121.65882,382.90921 Z M1187.33883,388.187556 L1124.2861,388.187556 L1124.2861,472.642326 L1187.33883,472.642326 L1187.33883,388.187556 Z" id="tablets-device-wizard"></path>
            </g>
        </g>
    </svg>
</template>

<script>
export default {};
</script>
