<template>
  <component-data :title="trans.addressTitle[lang]" :expandable="expandable">
    <form>
      <floating-input
        v-for="(item, index) in addressForm"
        :key="index"
        :name="index"
        :placeholder="trans[index][lang]"
        :initialValue="item.value"
        :initialErrors="item.errors"
        @handle-change="handleAddressChange"
        type="text"
        class="mt-2"
      ></floating-input>
      <floating-textarea
        name="shopExtraInfo"
        :placeholder="trans.info[lang]"
        :initial-value="$store.state.shopExtraInfo"
        @input="setShopExtraInfo($event)"
      ></floating-textarea>
    </form>
    <template v-slot:minimized-slot>
      <slot></slot>
    </template>
  </component-data>
</template>

<script>
import {
  GET_ADDRESS_DATA,
  SET_ADDRESS_DATA_MUTATION,
  SET_SHOP_EXTRA_INFO_MUTATION,
} from "../../../store/wizardShop/types";
import mixin from "../../../mixin";
import { mapGetters, mapMutations } from "vuex";
import { trans } from "../trans";

export default {
  mixins: [mixin],
  props: {
    expandable: {
      Type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      trans,
    };
  },
  computed: {
    ...mapGetters({
      addressForm: GET_ADDRESS_DATA,
    }),
  },
  methods: {
    ...mapMutations({
      setAddressData: SET_ADDRESS_DATA_MUTATION,
      setShopExtraInfo: SET_SHOP_EXTRA_INFO_MUTATION,
    }),
    handleAddressChange(payload) {
      this.setAddressData({
        ...this.addressForm,
        [payload.name]: {
          ...this.addressForm[payload.name],
          value: payload.value,
        },
      });
    },
  },
};
</script>

<style>
</style>
