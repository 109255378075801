import {
    GET_ADDRESS_DATA,
    GET_PERSONAL_DATA,
    GET_SHIPMENT_DATA,
    GET_ADDRESS_VALID,
    GET_PERSONAL_VALID,
    GET_ORDER,
    GET_ORDER_LOADING,
    GET_CARDS,
    GET_SELECTED_CARD,
    GET_ORDER_SAVED
} from "./types";

const wizardShopGetters = {
    [GET_ADDRESS_DATA](state) {
        return state.addressData;
    },
    [GET_PERSONAL_DATA](state) {
        return state.personalData;
    },
    [GET_SHIPMENT_DATA](state) {
        return state.shippingData;
    },
    [GET_ADDRESS_VALID](state, getters) {
        let valid = true;
        valid = Object.entries(getters[GET_ADDRESS_DATA]).every(item => {
            if (item[1].value != "" || item[1].name == 'floor') {
                return true;
            } else {
                return false;
            }
        });
        return valid;
    },
    [GET_PERSONAL_VALID](state, getters) {
        let valid = true;
        valid = Object.entries(getters[GET_PERSONAL_DATA]).every(item => {
            if (item[1].value != "") {
                return true;
            } else {
                return false;
            }
        });
        return valid;
    },
    GET_ORDER(state) {
        return state.order;
    },
    [GET_ORDER_LOADING](state){
        return state.orderLoading;
    },
    [GET_CARDS](state){
        return state.cards;
    },
    [GET_SELECTED_CARD](state){
        return state.selectedCardUUID
    },
    [GET_ORDER_SAVED](state){
        return state.orderSaved;
    }
};

export default wizardShopGetters;
