<template>
  <div class="component-data">
    <div class="title-section">
      <h5>{{ title }}</h5>
      <button v-if="expandable" @click="expanded = !expanded" class="mdc-button" :disabled="disabled">
        <span class="mdc-button__ripple"></span>
        <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <title>pencil (1)</title>
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="pencil-(1)" fill="#000000" fill-rule="nonzero">
                    <path d="M21.3838128,3.56531064 L18.3880681,0.567038298 C17.6332809,-0.188404255 16.3202085,-0.189855319 15.5624723,0.568489362 L2.14209787,14.1212851 C2.08260426,14.1812936 2.03968085,14.255017 2.0162766,14.3359957 L0.0191446809,21.3320426 C-0.030612766,21.5062638 0.0181617021,21.6941532 0.146417021,21.8225021 C0.241485106,21.9176638 0.368757447,21.9689191 0.499446809,21.9689191 C0.54527234,21.9689191 0.591612766,21.9625532 0.636455319,21.9498681 L7.62651064,19.9510043 C7.70744255,19.9276 7.78107234,19.8846298 7.84103404,19.8250894 L21.3838128,6.39184255 C21.7612298,6.01414468 21.9689191,5.51245106 21.9689191,4.9786 C21.9689191,4.44474894 21.7612298,3.94300851 21.3838128,3.56531064 Z M13.3867191,4.20045532 L15.2246085,6.03834468 L5.62905106,15.6339489 L4.94040426,14.2561872 C4.85554043,14.0869745 4.68295745,13.9802043 4.49375745,13.9802043 L3.69417447,13.9802043 L13.3867191,4.20045532 Z M1.22642979,20.742583 L1.87678723,18.4659574 L3.50300851,20.0921787 L1.22642979,20.742583 Z M6.99019574,19.096 L4.6013234,19.7784213 L2.19054468,17.3676426 L2.87296596,14.9787702 L4.18505532,14.9787702 L5.04562979,16.699966 C5.09388936,16.7965319 5.17238723,16.8750298 5.26895319,16.9232894 L6.99019574,17.7838638 L6.99019574,19.096 L6.99019574,19.096 Z M7.98880851,18.2748383 L7.98880851,17.4752553 C7.98880851,17.2860553 7.8820383,17.1134723 7.71282553,17.0286085 L6.33506383,16.3399617 L15.9306213,6.74440426 L17.7685106,8.58229362 L7.98880851,18.2748383 Z M20.6792511,5.69753191 L18.4777064,7.87941702 L14.0895489,3.49125957 L16.269983,1.29121277 C16.6474,0.913795745 17.3046383,0.913795745 17.6820553,1.29121277 L20.6778,4.28695745 C20.8664851,4.47564255 20.9703532,4.72625532 20.9703532,4.99297021 C20.9703532,5.25968511 20.8664851,5.51029787 20.6792511,5.69753191 Z" id="Shape"></path>
                </g>
            </g>
        </svg>
      </button>
    </div>
    <div v-if="(expandable && expanded) || !expandable" class="slot-content">
      <slot></slot>
    </div>
    <div v-else class="minimized-content">
      <slot name="minimized-slot"></slot>
    </div>
  </div>
</template>

<script>
import { MDCRipple } from "@material/ripple";
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    expandable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      expanded: false,
    };
  },
  mounted() {
    document
      .querySelectorAll(".mdc-button")
      .forEach((element) => new MDCRipple(element));
  },
};
</script>

<style lang="scss">
.component-data {
  .title-section {
    padding-bottom: 0.5rem;
    font-weight: bold;
    border-bottom: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .mdc-button{
      width: 80px;
    }
  }
  .slot-content {
    padding-top: 0.5rem;
  }

  .minimized-content{
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
  }
}
</style>