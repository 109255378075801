export const GET_ADDRESS_DATA = 'GET_ADDRESS_DATA';
export const SET_ADDRESS_DATA_MUTATION = 'SET_ADDRESS_DATA_MUTATION';
export const SET_SHOP_EXTRA_INFO_MUTATION = 'SET_SHOP_EXTRA_INFO_MUTATION';
export const GET_PERSONAL_DATA = 'GET_PERSONAL_DATA';
export const SET_PERSONAL_DATA_MUTATION = 'SET_PERSONAL_DATA_MUTATION';
export const GET_SHIPMENT_DATA = 'GET_SHIPMENT_DATA';
export const SET_SHIPMENT_DATA_MUTATION = 'SET_SHIPMENT_DATA_MUTATION';
export const GET_PERSONAL_VALID = 'GET_PERSONAL_VALID';
export const GET_ADDRESS_VALID = 'GET_ADDRESS_VALID';
export const GET_ORDER = 'GET_ORDER';
export const SET_ORDER_DATA_MUTATION = 'SET_ORDER_DATA_MUTATION';
export const STORE_ORDER_ACTION = 'STORE_ORDER_ACTION';
export const GET_ORDER_LOADING = 'GET_ORDER_LOADING';
export const SET_ORDER_LOADING_TO_TRUE_MUTATION = 'SET_ORDER_LOADING_TO_TRUE_MUTATION';
export const SET_ORDER_LOADING_TO_FALSE_MUTATION = 'SET_ORDER_LOADING_TO_FALSE_MUTATION';
export const RETREIVE_ORDER_ACTION = 'RETREIVE_ORDER_ACTION';
export const GET_CARDS = 'GET_CARDS';
export const SET_CARDS = 'SET_CARDS';
export const SET_SELECTED_CARD = 'SET_SELECTED_CARD';
export const GET_SELECTED_CARD = 'GET_SELECTED_CARD';
export const GET_ORDER_SAVED = 'GET_ORDER_SAVED';
export const SET_ORDER_SAVED_TO_TRUE = 'SET_ORDER_SAVED_TO_TRUE';

