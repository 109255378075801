import {
    SET_ADDRESS_DATA_MUTATION,
    SET_SHOP_EXTRA_INFO_MUTATION,
    SET_PERSONAL_DATA_MUTATION,
    SET_SHIPMENT_DATA_MUTATION,
    SET_ORDER_DATA_MUTATION,
    SET_ORDER_LOADING_TO_FALSE_MUTATION,
    SET_ORDER_LOADING_TO_TRUE_MUTATION,
    SET_CARDS,
    SET_SELECTED_CARD,
    SET_ORDER_SAVED_TO_TRUE
} from "./types";

const wizardShopMutations = {
    [SET_ADDRESS_DATA_MUTATION](state, payload) {
        state.addressData = { ...payload };
    },
    [SET_SHOP_EXTRA_INFO_MUTATION](state, payload) {
        state.shopExtraInfo = payload;
    },
    [SET_PERSONAL_DATA_MUTATION](state, payload) {
        state.personalData = { ...payload };
    },
    [SET_SHIPMENT_DATA_MUTATION](state, payload) {
        state.shippingData = payload;
    },
    [SET_ORDER_DATA_MUTATION](state, payload) {
        state.order = {...payload};
    },
    [SET_ORDER_LOADING_TO_FALSE_MUTATION](state) {
        state.orderLoading = false;
    },
    [SET_ORDER_LOADING_TO_TRUE_MUTATION](state) {
        state.orderLoading = true;
    },
    [SET_CARDS](state, payload){
        state.cards = payload;
    },
    [SET_SELECTED_CARD](state, payload){
        state.selectedCardUUID = payload;
    },
    [SET_ORDER_SAVED_TO_TRUE](state){
        state.orderSaved = true;
    }

};

export default wizardShopMutations;
